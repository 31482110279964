import { Directive, HostListener, ElementRef, Renderer2, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[appNumeric]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: NumericDirective,
      multi: true
    }
  ]
})
export class NumericDirective implements ControlValueAccessor {
  private onChange!: (val: number | null) => void;
  private onTouched!: () => void;
  private value!: number | null;

  @Input() minValue!: number;
  @Input() maxValue!: number;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) { }

  @HostListener('input', ['$event.target.value'])
  onInputChange(value: string) {
    // Check if the value is empty (user deleted the entire value)
    if (value === '') {
      this.updateTextInput(null, this.value !== null);
      return;
    }

    // Remove non-numeric characters and convert to number
    const numericValue = parseInt(value.replace(/\D*/g, ''), 10);

    // Check if the numeric value is within the specified range
    if (!isNaN(numericValue) && numericValue >= this.minValue && numericValue <= this.maxValue) {
      this.updateTextInput(numericValue, this.value !== numericValue);
    } else {
      // If the value is out of range, revert to the previous value
      this.updateTextInput(this.value, false);
    }
  }


  @HostListener('blur')
  onBlur() {
    this.onTouched();
  }

  private updateTextInput(value: number | null, propagateChange: boolean) {
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', value);
    if (propagateChange) {
      this.onChange(value);
    }
    this.value = value;
  }

  // ControlValueAccessor Interface
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }

  writeValue(value: any): void {
    value = value ? parseInt(value, 10) : null;
    this.updateTextInput(value, false);
  }
}


