<div class="text-primary md:mt-12 px-5">
  <div class="bg-greyCard rounded-lg mb-4 p-7">
      <div class="w-full">
          <div class="text-base mb-3 lg:text-lg font-medium">Proof of business revenue/solvency <sup>*</sup></div>
          <div>*Previous year's audited or unaudited financial statements (P&L, statement of cash flows)
              confirming the turnover of the business.</div>
      </div>

      <div *ngIf="!getRemoveFilesValue()">
        <mat-accordion class="flex flex-row justify-between mt-3" *ngFor="let selected_file of turnoverFiles; index as i">
            <mat-expansion-panel
            [ngClass]="selected_file.isUploaded ? 'bg-lightGreen' : ''"
            class="w-[85%]" *ngIf="selected_file" disabled [expanded]="selected_file.upload_result">
                <mat-expansion-panel-header [ngClass]="selected_file.upload_result ? 'error-state' : ''">
                    <mat-panel-title>
                        <div class="flex items-center">
                            <mat-icon color="primary">description</mat-icon>
                            <span class="ml-3 text-primary font-medium file-name" matTooltip="{{selected_file.file.name}}">{{selected_file.file.name}}</span>
                            <span class="ml-3 text-black">{{ getFileSize(selected_file.file.size) }}</span>
                        </div>
                    </mat-panel-title>
                    <button *ngIf="selected_file && selected_file?.isUploaded" class="download-icon mt-1 mr-3"
                    (click)="downLoadFile(selected_file.file.id)" matTooltip="Download file">
                        <mat-icon>vertical_align_bottom</mat-icon>
                    </button>
                </mat-expansion-panel-header>
                <div class="py-2 font-bold" [ngClass]="selected_file.isUploaded ? 'text-green' : 'text-red'">
                    {{selected_file.upload_result}}
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <div *ngIf="viewing === 'admin'" class="mt-1 italic text-red blinking-text">
            GDPR compliance: Please ensure to remove this file(s) from download folder
            from your device after downloading/viewing.
        </div>
      </div>
      
      <div *ngIf="getRemoveFilesValue()" class="mt-2">
        <div class="font-bold text-red">File(s) removed for GDPR compliance</div>
      </div>
  </div>
  <div class="bg-greyCard rounded-lg mb-4 p-7">
      <div class="w-full">
          <div class="text-base mb-3 lg:text-lg font-medium">Proof of company registration and ownership/partnership
              status <sup>*</sup>
          </div>
      </div>

      @if (country) {
          <div *ngIf="country === 'DE'">
              <div class="font-bold mb-2">Germany</div>
              <div class="mb-2">Registration certificate from the Common Register Portal of the German Federal States
                  (Handels Register) that shows the current share ownership status of a company.
              </div>
              <div class="mb-2">Articles of Association that indicate the current share ownership status from the Common
                  Register Portal of the German Federal States (Handels Register).
              </div>
          </div>
          
          <div *ngIf="country === 'FR'">
              <div class="font-bold mb-2">France</div>
              <div class="mb-2">K-Bis certificate.</div>
              <div class="mb-2">Articles of Association that indicate the current share ownership/partnership status.</div>
          </div>
          
          <div *ngIf="country === 'NL'">
              <div class="font-bold mb-2">Netherlands</div>
              <div class="mb-2">Registration certificate from Dutch Business Register (KVK) shows the current share ownership
                  status of a company.
              </div>
              <div>The Articles of Association that may indicate the ownership status.</div>
          </div>
      } @else {
          <div>
              <div class="font-bold mb-2">Germany</div>
              <div class="mb-2">Registration certificate from the Common Register Portal of the German Federal States
                  (Handels Register) that shows the current share ownership status of a company.
              </div>
              <div class="mb-2">Articles of Association that indicate the current share ownership status from the Common
                  Register Portal of the German Federal States (Handels Register).
              </div>
          </div>
          
          <div>
              <div class="font-bold mb-2">France</div>
              <div class="mb-2">K-Bis certificate.</div>
              <div class="mb-2">Articles of Association that indicate the current share ownership/partnership status.</div>
          </div>
          
          <div>
              <div class="font-bold mb-2">Netherlands</div>
              <div class="mb-2">Registration certificate from Dutch Business Register (KVK) shows the current share ownership
                  status of a company.
              </div>
              <div>The Articles of Association that may indicate the ownership status.</div>
          </div>
      }

      <div *ngIf="!getRemoveFilesValue()">
        <mat-accordion class="flex flex-row justify-between mt-3" *ngFor="let selected_file of registrationFiles; index as i">
            <mat-expansion-panel
            [ngClass]="selected_file.isUploaded ? 'bg-lightGreen' : ''"
             class="w-[85%]" *ngIf="selected_file" disabled [expanded]="selected_file.upload_result">
                <mat-expansion-panel-header [ngClass]="selected_file.upload_result ? 'error-state' : ''">
                    <mat-panel-title>
                        <div class="flex items-center">
                            <mat-icon color="primary">description</mat-icon>
                            <span class="ml-3 text-primary font-medium file-name" matTooltip="{{selected_file.file.name}}">{{selected_file.file.name}}</span>
                            <span class="ml-3 text-black">{{ getFileSize(selected_file.file.size) }}</span>
                        </div>
                    </mat-panel-title>
                    <button *ngIf="selected_file && selected_file?.isUploaded" class="download-icon mt-1 mr-3"
                        (click)="downLoadFile(selected_file.file.id)" matTooltip="Download file">
                        <mat-icon>vertical_align_bottom</mat-icon>
                    </button>
                </mat-expansion-panel-header>
                <div class="py-2 font-bold" [ngClass]="selected_file.isUploaded ? 'text-green' : 'text-red'">
                    {{selected_file.upload_result}}
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <div *ngIf="viewing === 'admin'" class="mt-1 italic text-red blinking-text">
            GDPR compliance: Please ensure to remove this file(s) from download folder
            from your device after downloading/viewing.
        </div>
      </div>
      <div *ngIf="getRemoveFilesValue()" class="mt-2">
        <div class="font-bold text-red">File(s) removed for GDPR compliance</div>
      </div>
  </div>
  <div class="italic font-medium">*Supply a copy of the applicant’s financial statement for one year preceding the
      year of application or for the
      time that the applicant has been in business if less than one year, plus financial statement of any subsidiaries
      of affiliates of the applicant for the same period. If the applicant is a new business concern, enclose a copy
      of projection of income, or a statement by a certified public accountant, which states that the applicant is a
      viable business concern. All financial statements submitted to ESDP must show applicable date of the information
      given and must be signed and dated by the proprietor, partner or authorized officer unless prepared by an
      independent certified public accountant. All materials will be kept confidential.
  </div>
  <div class="flex justify-between mt-6">
      <div class="flex flex-start gap-5">
          <button mat-button matStepperPrevious class="!rounded-[50px] next-button">
              <div class="flex items-center pr-2"><mat-icon>chevron_left</mat-icon>Previous step</div>
          </button>
      </div>
  </div>
</div>
