import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private tokenSubject: BehaviorSubject<string | null>;
  private checkTokenInterval: any;

  constructor(private router: Router, private afAuth: AngularFireAuth) {
    this.tokenSubject = new BehaviorSubject<string | null>(this.getToken());
    this.startTokenValidityCheck();
  }

  getToken(): string | null {
    return sessionStorage.getItem('token');
  }

  getTokenObservable(): Observable<string | null> {
    return this.tokenSubject.asObservable();
  }

  updateToken(token: string | null) {
    this.tokenSubject.next(token);
    if (token && !this.checkTokenInterval) {
      this.startTokenValidityCheck();
    }
  }

  async checkTokenValidity(): Promise<boolean> {
    const token: any = this.getToken();
    if (token) {
      const decodedToken = this.decodeJWT(token);
      const currentTime = Date.now() / 1000; // Convert to seconds
      if (decodedToken && decodedToken.exp && decodedToken.exp < currentTime) {
        await this.refreshToken();
      }
    } else {
      this.updateToken(null);
    }
    return true;
  }

  private async refreshToken() {
    try {
      // Call Firebase to refresh the token
      const user = await this.afAuth.currentUser;
      if (user) {
        const refreshedToken = await user.getIdToken(true);
        sessionStorage.setItem('token', refreshedToken);
        this.updateToken(refreshedToken);
      } else {
        // this.redirectToLogin();
      }
    } catch (error) {
      console.error('Error refreshing token:', error);
      // this.redirectToLogin();
    }
  }

  private startTokenValidityCheck() {
    // Check token validity every 5 minutes (300000 milliseconds)
    this.checkTokenInterval = setInterval(() => {
      this.checkTokenValidity();
    }, 2000);
  }

  private decodeJWT(token: string): any {
    try {
      const base64Url = token?.split('.')[1];
      const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
      return JSON.parse(atob(base64));
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  }

  private redirectToLogin() {
    clearInterval(this.checkTokenInterval);
    this.checkTokenInterval = null;
    this.router.navigate(['/login']);
  }
  
}
