import { Injectable } from '@angular/core';
import {
  AngularFirestore,
} from '@angular/fire/compat/firestore';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DbService {

  constructor(private firestore: AngularFirestore) { }

  // Add data to a collection
  // createCertificationForm(data: any): Promise<any> {
  //   return this.firestore.collection('certifications').doc(data.id).set(data);
  // }

  async createPaymentTier(data: any) {
    const existingDoc = await this.checkExistingTier(data.email);
    if (!existingDoc) {
      return await this.firestore.collection('exempted_embs').add(data);
    } else {
      return null; // Indicate no document created
    }
  }

  async checkExistingTier(value: string): Promise<any> {
    const query = this.firestore.collection('exempted_embs').ref.where('email', '==', value);
    const querySnapshot = await query.get();
    return querySnapshot.empty ? null : querySnapshot.docs[0];
  }

  createCertificationForm(newData: any): Observable<any> {
    // Create a new document in Firestore
    return new Observable<any>(observer => {
      this.firestore.collection('certifications').doc(newData.id).set(newData)
      .then(docRef => {
        observer.next({ success: true, data: newData }); // Return success message and document data
        observer.complete();
      })
      .catch(error => {
        observer.error(error);
      });
    });
  }

  updateCertificationForm(docId: any, data: any): Promise<any> {
    this.removeLastDuplicateFileName(data.turnoverDocs);
    this.removeLastDuplicateFileName(data.ownershipDocs);
    return this.firestore.doc(`certifications/${docId}`).update(data);
  }

  removeLastDuplicateFileName(array: any) {
    const fileNameMap = new Map<string, number>();

    // Iterate through the array in reverse order to find the last occurrence of each file name
    for (let i = array.length - 1; i >= 0; i--) {
        const fileName = array[i].file.name;
        
        // If the file name is already in the map, remove this entry from the array
        if (fileNameMap.has(fileName)) {
            array.splice(i, 1);
        } else {
            // Otherwise, add the file name to the map
            fileNameMap.set(fileName, i);
        }
    }
  }

  checkPaymentStatus(appId: string): Observable<{ isPaid: boolean, txn_id?: string }> {
    return this.firestore.doc<any>(`certifications/${appId}`).valueChanges().pipe(
      map((payment: any) => {
        return { isPaid: payment.status.current_status === 'PAID', txn_id: payment.status.txn_id };
      })
    );
  }

  getCertifications(): Observable<any[]> {
    return this.firestore.collection<any>('certifications').snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  async getCertificationForm(appId: string): Promise<any> {
    try {
      const docRef = this.firestore.collection('certifications').doc(appId);
      const docSnapshot = await docRef.get().toPromise();
    //   const query = this.firestore.collection('payment_tiers', ref => ref.where('value', '==', value)).get();
    // const snapshot = await firstValueFrom(query);
      if (docSnapshot?.exists) {
        return docSnapshot.data();
      } else {
        throw new Error('Certification form not found');
      }
    } catch (error) {
      console.error('Error getting certification form:', error);
      throw error;
    }
  }

  getCertificationsByUid(uid: string): Observable<any[]> {
    return this.firestore.collection('certifications', ref => ref.where('uid', '==', uid))
      .valueChanges({ idField: 'id' })
      .pipe(
        map((data: any[]) => {
          return data.map(item => {
            return { id: item.id, ...item };
          });
        })
      );
  }

  deleteCertificationForm(formId: string): Promise<void> {
    const formRef = this.firestore.collection('certifications').doc(formId);
    return formRef.delete();
  }

  createStatus(data: any): Promise<any> {
    return this.firestore.collection('status').doc(data.application_id).set(data);
  }
}
