import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appRegNumber]'
})
export class RegNumberDirective {
    @HostListener('input', ['$event']) onInput(event: Event): void {
        const inputElement = (event.target as HTMLInputElement);
        let inputValue = (event.target as HTMLInputElement).value;
        const validCharacters = /^[a-zA-Z0-9]*$/; // Updated regular expression to allow alphabets

        // Allow deletion if the Backspace or Delete key is pressed
        if (event instanceof InputEvent && event.inputType === 'deleteContentBackward') {
            return;
        }

        if (!validCharacters.test(inputValue)) {
            // Replace any characters that are not alphanumeric with an empty string
            inputElement.value = inputValue.replace(/[^a-zA-Z0-9]/g, '');
            // event.preventDefault();
        }

        // Convert alphabets to uppercase
        inputValue = inputValue.toUpperCase();

        // Update the input element value
        inputElement.value = inputValue;
    }
}
