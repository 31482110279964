<div class="dialog-container">
    <h1 class="text-xl font-bold mb-4">Submit Application</h1>
    <p class="mb-1">Your application is ready to be submitted for verification. You will not be allowed to update this application further.</p>
    <p class="mb-6"> Next you will be
      redirected to the payments page. <span class="font-bold">The fee is non-refundable. </span>Do you want to proceed?
    </p>
    <div class="flex justify-end mt-10">
      <button mat-button class="submit-button mr-2 border border-gray-300 px-4 py-2 rounded-md hover:bg-blue-600 text-white hover:text-white focus:outline-none" [mat-dialog-close]="true">Yes, Submit and Proceed</button>
      <button mat-button class="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-gray-100 focus:outline-none" [mat-dialog-close]="false">No, save as draft to edit further</button>
    </div>
  </div>
  