import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-view-business-identification',
  templateUrl: './view-business-identification.component.html',
  styleUrl: './view-business-identification.component.css'
})
export class ViewBusinessIdentificationComponent {
  @Input() businessIdentificationForm!: FormGroup;
  checkedArray: {key: string, value: string}[] = [{key:'Yes',value:'true'},{key:'No',value: 'false'} ];
  countryOfOrigin: {key: string, value: string}[] = [
    {key:'France',value:'FR'}, {key:'Germany',value: 'DE'}, {key:'Netherlands',value:'NL'}
  ];

  filteredBusinessType: any[] = [
    {key: 'option_fr_1', value: 'Sole Proprietorship (Entreprise Individuelle)'},
    {key: 'option_fr_2', value: 'Partnership (Société en Nom Collectif - SNC)'},
    {key: 'option_fr_3', value: 'Limited Liability Company (Société à Responsabilité Limitée - SARL)'},
    {key: 'option_fr_4', value: 'Public Limited Company (Société Anonyme - SA)'},
    {key: 'option_fr_5', value: 'Simplified Joint-Stock Company (Société par Actions Simplifiée - SAS)'},
    {key: 'option_fr_6', value: 'Micro-Entreprise (Micro-Entreprise)'},
    {key: 'option_fr_7', value: 'Société en commandite simple'},
    {key: 'option_fr_8', value: 'Société en commandite par actions'},
    {key: 'option_de_1', value: 'Sole Proprietorship (Einzelunternehmen)'},
    {key: 'option_de_2', value: 'Partnership (Personengesellschaft)'},
    {key: 'option_de_2_1', value: 'General Partnership (Offene Handelsgesellschaft - OHG)'},
    {key: 'option_de_2_2', value: 'Limited Partnership (Kommanditgesellschaft - KG)'},
    {key: 'option_de_3', value: 'Limited Liability Company (Gesellschaft mit beschränkter Haftung - GmbH)'},
    {key: 'option_de_4', value: 'Public Limited Company (Aktiengesellschaft - AG)'},
    {key: 'option_nl_1', value: 'Sole Proprietorship (Eenmanszaak)'},
    {key: 'option_nl_2', value: 'Partnership (Vennootschap onder Firma - VOF)'},
    {key: 'option_nl_3', value: 'Private Limited Company (Besloten Vennootschap - BV)'},
    {key: 'option_nl_4', value: 'Public Limited Company (Naamloze Vennootschap - NV)'},
    {key: 'option_nl_5', value: 'Cooperative (Coöperatie)'}
  ];

  howDidYouHearAboutUs: {key: string, value: string}[] = [

    {
      "key": "corporate_partner_orgnaisation",
      "value": "Corporate / Partner Organisation"
    },
    {
      "key": "event",
      "value": "Event"
    },
    {
      "key": "friend_colleague",
      "value": "Friend / Colleague"
    },
    {
      "key": "esdp",
      "value": "ESDP"
    },
    {
      "key": "msduk",
      "value": "MSDUK"
    },
    {
      "key": "press",
      "value": "Press"
    },
    {
      "key": "university",
      "value": "University"
    },
    {
      "key": "other",
      "value": "Other"
    }
  ]

}
