import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Router } from '@angular/router';
import { loadStripe } from '@stripe/stripe-js';
import { Observable, firstValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment.production';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  constructor(private afCloudFunc: AngularFireFunctions, private router: Router) {
    //afCloudFunc.useEmulator("localhost", 5001);
  }

  public async onStripeCheckout(appId: any): Promise<any> {
    try {
      const stripe = await loadStripe(environment.stripeKey);
      // const stripe = await loadStripe("pk_test_51OsLj6RxHcwIZvoLyAoGBQ1mRrr92QVjuK7qNlaC5IIpCkXJkmeQOq49dDRPcQWpPE4RXMmzlIhXBSdXtLMwtN7d00H5xAkv6X");
      // const stripe = await loadStripe("pk_test_51Of19LAY7Bb9zUXsnUE1AurAgAmePDQnX029irU8vtCJo42tUih7F7hBr3b9yJY1lfJKaFSQcHcGr9DmeQfGorh90009yIPeOT");
      const response$: Observable<any> = this.afCloudFunc.httpsCallable('doStripeCheckout')({ id: appId });
      const result = await firstValueFrom(response$);
      const sessionId = result.session.id;
      if (sessionId) {
        if (sessionId !== 'exempted') {
          // Redirect to Stripe checkout
          const error = await stripe?.redirectToCheckout({
            sessionId: result.session.id,
          });
          if (error) {
            console.error('Error redirecting to Stripe checkout:', error);
          // TODO: Return to failed page
          }
        } else if (sessionId === 'exempted') {
          if (!result.session.encId) {
            this.router.navigate(['/success', appId]);
          } else {
            this.router.navigate(['/success', result.session.encId]);
          }
        }
      }
      return sessionId;

    } catch (error) {
      console.error('Error calling Cloud Function:', error);
      // Handle errors appropriately, e.g., display error message to user
    }
  }

  public async checkPaymentStatus(appId: any): Promise<any> {
    try {
      const response$: Observable<any> = this.afCloudFunc.httpsCallable('checkPaymentStatus')({ id: appId });
      const result = await firstValueFrom(response$);
      const session = result.session;
      return session;

    } catch (error) {
      console.error('Error calling Cloud Function:', error);
      // Handle errors appropriately, e.g., display error message to user
    }
  }

}
