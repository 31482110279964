<div class="spinner-container" *ngIf="isLoading">
  <mat-spinner diameter="50"></mat-spinner>
</div>
<div *ngIf="!isLoading" class="certification-form-block">
  <div class="flex items-end justify-end mb-5">
    <button
      mat-raised-button
      class="text-primary back-btn"
      (click)="goToListing()"
    >
      <mat-icon class="mat-18"
        ><span class="material-symbols-outlined"> arrow_back </span></mat-icon
      >Go to Dashboard
    </button>
  </div>
  <mat-card class="pb-10">
    <mat-card-content>
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="6px" class="flex items-center mb-6">
        <div class="w-[15%] sm:w-[8%] md:w-[6%] lg:w-[3%] mr-8 logo-size"><img src="../../../../../assets/icons/esdp-logo.png" alt=""></div>
        <h1 class="text-primary !mb-0 text-2xl font-bold mt-2">Certification Application</h1>
      </div>
  <form [formGroup]="certificationForm">
    <mat-stepper
      class="custom-stepper px-5"
      [linear]="isLinear"
      #stepper
      [orientation]="(stepperOrientation | async)!"
      [disableRipple]="true"
    >
      <mat-step
        formGroupName="businessIdentificationForm"
        [stepControl]="
          certificationForm.get('businessIdentificationForm')!
        "
        [completed]="false"
      >
        <ng-template matStepLabel class="text-primary"
          >Business Identification</ng-template
        >
        <app-view-business-identification [businessIdentificationForm]="certificationForm"></app-view-business-identification>
      </mat-step>

      <mat-step
        formGroupName="businessProfileForm"
        [stepControl]="certificationForm.get('businessProfileForm')!"
        [completed]="false"
      >
        <ng-template matStepLabel class="text-primary"
          >Business Profile</ng-template
        >
        <app-view-business-profile [businessProfileForm]="certificationForm"></app-view-business-profile>
      </mat-step>

      <mat-step
        formGroupName="ownershipForm"
        [stepControl]="certificationForm.get('ownershipForm')!"
        [completed]="false"
      >
        <ng-template matStepLabel class="text-primary"
          >Ownership, Management</ng-template
        >
        <app-view-ownership [files]="ownerFiles" [ownershipForm]="certificationForm" [viewing]="'owner'"></app-view-ownership>
      </mat-step>

      <mat-step [completed]="false">
        <ng-template matStepLabel class="text-primary"
          >Documents</ng-template
        >
        <app-view-document [uploadForm]="certificationForm" [turnoverFiles]="turnoverFiles" [registrationFiles]="ownershipFiles" [viewing]="'owner'"></app-view-document>
      </mat-step>

      <mat-step [completed]="false">
        <ng-template matStepLabel class="text-primary">Final Submit</ng-template>
        <app-final-submit [application]="certificationForm" [isView]="true"></app-final-submit>
      </mat-step>

    </mat-stepper>
  </form>
</mat-card-content>
</mat-card>
</div>
