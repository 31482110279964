import { Directive, ElementRef, HostListener, Input, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appPostalCode]'
})
export class PostalCodeDirective {
  @Input() country!: string;

  constructor(@Self() private control: NgControl, private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: Event): void {
    const inputElement = (event.target as HTMLInputElement);
    const inputValue = (event.target as HTMLInputElement).value;

    let validCharacters: RegExp;
    switch (this.country) {
      case 'DE':
        validCharacters = /^[0-9]*$/;
        break;
      case 'NL':
        validCharacters = /^[1-9][0-9]{3} ?[A-Z]{2}$/;
        break;
      case 'FR':
        validCharacters = /^[0-9]*$/;
        break;
      default:
        validCharacters = /^[a-zA-Z0-9 ]*$/;
        break;
    }

    // Allow deletion if the Backspace or Delete key is pressed
    if (event instanceof InputEvent && event.inputType === 'deleteContentBackward') {
      return;
    }

    // Check if the entire input value matches the valid characters
    if (!validCharacters.test(inputValue)) {
      // Remove invalid characters from the input value
      inputElement.value = inputValue.replace(/[^0-9A-Za-z ]/g, '');
      this.control.control?.setErrors({ invalidFormat: true });
    } else {
      // If the input is valid, clear any existing errors
      this.control.control?.setErrors(null);
    }
  }

  @HostListener('blur') onBlur(): void {
    // Convert the entire input value to uppercase on blur
    const inputElement = this.el.nativeElement as HTMLInputElement;
    inputElement.value = inputElement.value.toUpperCase();
  }
}
