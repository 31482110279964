import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNonNumeric]'
})
export class NonNumericDirective {
    @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent): void {
        const validCharacters = /^[A-Za-z]+$/;
        if (!validCharacters.test(event.key)) {
            // Prevent the input if the pressed key is not a valid character
            event.preventDefault();
        }
    }
}

