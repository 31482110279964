import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Router } from '@angular/router';
import { Observable, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DownloadFileService {

  constructor(private afCloudFunc: AngularFireFunctions) { }

  private getMimeType(fileName: string): string {
    const fileExtension = fileName.split('.').pop();
    let mimeType = 'application/octet-stream'; // Default to binary data
    switch (fileExtension) {
      case 'pdf':
        mimeType = 'application/pdf';
        break;
      case 'png':
        mimeType = 'image/png';
        break;
      case 'jpeg':
      case 'jpg':
        mimeType = 'image/jpeg';
        break;
      case 'gif':
        mimeType = 'image/gif';
        break;
      case 'txt':
        mimeType = 'text/plain';
        break;
      case 'csv':
        mimeType = 'text/csv';
        break;
      case 'html':
        mimeType = 'text/html';
        break;
      case 'json':
        mimeType = 'application/json';
        break;
      case 'doc':
        mimeType = 'application/msword';
        break;
      case 'docx':
        mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        break;
      case 'xls':
        mimeType = 'application/vnd.ms-excel';
        break;
      case 'xlsx':
        mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        break;
      case 'ppt':
        mimeType = 'application/vnd.ms-powerpoint';
        break;
      case 'pptx':
        mimeType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
        break;
    }
    return mimeType;
  }


  async onFileDownload(fileId: any) {
    if (!(fileId)) {
      throw new Error('File id is required');
    }

    const response$: Observable<any> = this.afCloudFunc.httpsCallable('getBase64FileData')(
      {
        id: fileId
      }
    );
    const result: any = await firstValueFrom(response$);

    if(result){
      if(result?.status){
        if(result.status === "success"){
          const base64fileData = result.fileData;
          const fileName = result.name;

          const byteCharacters = atob(base64fileData);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          // Get the MIME type of the file
          const mimeType = this.getMimeType(fileName);

          const blob = new Blob([byteArray], { type: mimeType });

          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');

          link.href = url;

          link.download = fileName;
          link.click();
          return result;
        }
      }
    }
    return result;
  }
}
