<div class="md:mt-12 px-5">
    
    <div class="flex justify-center font-bold text-2xl text-primary">
      <div class="bg-white -mb-6 py-2 px-6 border-2 rounded-lg">Summary</div>
    </div>
    <div class="border-2 rounded-lg p-8">

      <!-- Name of the business -->
      <div class="text-primary text-base mb-3 lg:text-lg font-medium">
        Name of the business <sup>*</sup>
      </div>
      <div class="w-full mb-3">
        {{ certificationForm?.businessIdentificationForm?.business_name || 'Yet to be filled up' }}
      </div>

      <!-- Country of Business Registration -->
      <div class="flex items-center justify-between flex-col lg:flex-row mb-3">
        <div class="w-full lg:w-[48%]">
          <div class="text-primary text-base mb-3 lg:text-lg font-medium">
            Country of Business Registration <sup>*</sup>
          </div>
          <div>
            {{ getCountryOfOriginByValue(certificationForm?.businessIdentificationForm?.country_of_business_registration) || 'Yet to be filled up' }}
          </div>
        </div>

        <!-- Business Registration Number -->
        <div class="w-full lg:w-[48%]">
          <div class="text-primary text-base mb-3 lg:text-lg font-medium">
            Business registration number <sup>*</sup>
          </div>
          <div>
            {{ certificationForm?.businessIdentificationForm?.business_registration_number || 'Yet to be filled up' }}
          </div>
        </div>
      </div>

      <div class="flex items-center justify-between flex-col lg:flex-row mb-3">
        <!-- Business Type -->
        <div class="w-full lg:w-[48%]">
          <div class="text-primary text-base mb-3 lg:text-lg font-medium">
            Business type <sup>*</sup>
          </div>
          <div>
            {{ getBusinessTypeByKey(certificationForm?.businessIdentificationForm?.business_type) || 'Yet to be filled up' }}
          </div>
        </div>

        <!-- Number of employees -->
        <div class="w-full lg:w-[48%]">
            <div class="text-primary text-base mb-3 lg:text-lg font-medium">Number of employees <sup>*</sup></div>
            <div class="mb-3">
              {{ certificationForm?.businessProfileForm?.number_of_employees || 'Yet to be filled up' }}
            </div>
        </div>
      </div>

      <div class="flex justify-between flex-col lg:flex-row mb-3">
        <!-- Gross turnover -->
        <div class="w-full lg:w-[48%]">
            <div class="text-primary text-base mb-3 lg:text-lg font-medium">Gross turnover for the last year in Euros <sup>*</sup></div>
            <div class="mb-3">
              {{ getTurnoverByValue(certificationForm?.businessProfileForm?.last_year_gross_turnover_euro) || 'Yet to be filled up' }}
            </div>
        </div>

        <!-- Geographical coverage -->
        <div class="w-full lg:w-[48%]">
            <div class="text-primary text-base mb-3 lg:text-lg font-medium">What is your geographical coverage? <sup>*</sup></div>
          <div class="mb-3">
            <ng-container *ngIf="certificationForm?.businessProfileForm?.geographical_coverage?.length; else noGeographicalCoverage">
              <span *ngFor="let country of certificationForm?.businessProfileForm.geographical_coverage; let last = last">
                {{ getCountryByCode(country) }}<span *ngIf="!last">, </span>
              </span>
            </ng-container>
            <ng-template #noGeographicalCoverage>
              Yet to be filled up
            </ng-template>
          </div>
        </div>
      </div>

      <div class="flex justify-between flex-col lg:flex-row mb-3">
        <!-- Industry sectors -->
        <div class="w-full lg:w-[48%]">
            <div class="text-primary text-base mb-3 lg:text-lg font-medium">Up to 3 industry sectors of the business <sup>*</sup></div>
            <div class="mb-3">
                <ng-container *ngIf="certificationForm?.businessProfileForm?.industry_sectors?.length; else noIndustrySectors">
                <span *ngFor="let sector of certificationForm?.businessProfileForm.industry_sectors; let last = last">
                    {{ getIndustryByKey(sector) }}<span *ngIf="!last">, </span>
                </span>
                </ng-container>
                <ng-template #noIndustrySectors>
                Yet to be filled up
                </ng-template>
            </div>
        </div>

        <!-- Services -->
        <div class="w-full lg:w-[48%]">
            <div class="text-primary text-base mb-3 lg:text-lg font-medium">Up to 5 services the business offer <sup>*</sup></div>
            <div class="mb-3">
              <ng-container *ngIf="certificationForm?.businessProfileForm?.service_offerings?.length; else noServiceOfferings">
                <span *ngFor="let service of certificationForm?.businessProfileForm.service_offerings; let last = last">
                  {{ getSubIndustryByKey(service) }}<span *ngIf="!last">, </span>
                </span>
              </ng-container>
              <ng-template #noServiceOfferings>
                Yet to be filled up
              </ng-template>
            </div>
        </div>
      </div>

      <div>
        <div class="text-xl font-bold text-primary">Owner(s)</div>
        <div class="mt-5 dashboard-table">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z1">

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>Name</th>
                  <td mat-cell *matCellDef="let element">
                    <div>
                      @if (element.business_owner_firstname) {
                        {{ element.business_owner_firstname + ' ' + element.business_owner_lastname }}
                      } @else {
                        --
                      }
                    </div>
                  </td>
                </ng-container>
              
                <!-- Job title Column -->
                <ng-container matColumnDef="jobTitle">
                    <th mat-header-cell *matHeaderCellDef>Job Title</th>
                    <td mat-cell *matCellDef="let element">
                        <div>
                        @if (element.job_title) {
                            {{ element.job_title }}
                        } @else {
                            --
                        }
                        </div>
                    </td>
                </ng-container>
              
                <!-- Percentage Ownership Column -->
                <ng-container matColumnDef="percentageOwnership">
                    <th mat-header-cell *matHeaderCellDef>Percentage Ownership</th>
                    <td mat-cell *matCellDef="let element">
                        <div>
                        @if (element.percentage_ownership) {
                            {{ element.percentage_ownership + '%' }}
                        } @else {
                            --
                        }
                        </div>
                    </td>
                </ng-container>
              
                <!-- Ethnic group(s) Column -->
                <ng-container matColumnDef="ethnicGroup">
                    <th mat-header-cell *matHeaderCellDef>Ethnic group(s)</th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngFor="let group of element.ethnic_minority_group">
                        @if (group) {
                            {{ getRegionValueByKey(group) }}
                        } @else {
                            --
                        }
                        </div>
                    </td>
                </ng-container>
              
                <!-- Nationality Column -->
                <ng-container matColumnDef="nationality">
                  <th mat-header-cell *matHeaderCellDef>Nationality</th>
                  <td mat-cell *matCellDef="let element">
                    <div>
                    @if (element.resident_status) {
                        <div>{{getOptionValueByKey(element.resident_status).name}}</div>
                        <div>{{getOptionValueByKey(element.resident_status).value}}</div>
                    } @else {
                        European
                    }
                    </div>
                </td>
                </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="12">
                    <div class="flex justify-center no-data-row">
                      No Owners added yet
                    </div>
                  </td>
                </tr>
              </table>
              
        </div>
      </div>

    </div>
    
    <div *ngIf="!isView" class="flex items-center my-4 rounded-lg px-4 py-2 bg-[#d9d9d9]">
      <!-- formControlName="acceptedTerms" -->
      <mat-checkbox class="text-primary font-medium" color="primary"
      (change)="checkToDisableButton($event.checked)">
      <div class="text-primary font-medium text-base">I confirm and sign to attest that the above information is true to the best of my knowledge.
      </div>
      </mat-checkbox>
    </div>
 
    <div *ngIf="isView" class="flex items-center justify-center my-4 rounded-lg px-4 py-2 bg-[#d9d9d9]">
      <div class="text-[#c80815] font-bold text-base">{{userName}} signed and submitted the above information on {{getDateFormat(submittedDate)}}.
      </div>
    </div>

    <div class="flex justify-between">
        <div>
            <button mat-button matStepperPrevious class="!rounded-[50px] next-button">
                <div class="flex items-center pr-2"><mat-icon>chevron_left</mat-icon>Previous step</div>
            </button>
        </div>
        <div class="flex" *ngIf="!isView">
            <button mat-raised-button class="mat-blue !rounded-[50px]" (click)="submitFormFinally()" [disabled]="disableButton">Submit & Make Payment</button>
        </div>
        <!-- (click)="submitFormFinally()" -->
    </div>
  </div>

  <div class="error-container" *ngIf="errorMessage">
    <span>{{ errorMessage }}</span>
    <button mat-icon-button (click)="clearError()">
      <mat-icon>close</mat-icon>
    </button>
</div>