import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { DownloadFileService } from '../../../../services/download-file/download-file.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-view-ownership',
  templateUrl: './view-ownership.component.html',
  styleUrl: './view-ownership.component.css'
})
export class ViewOwnershipComponent {
  @Input() ownershipForm!: FormGroup;
  @Input() files: any = [];
  @Input() viewing: string = '';

  regionGroups = [
    {
      name: 'Middle East and Africa (including mixed heritage)',
      options: [
        {key: 'Ajam', value: 'Ajam'},
        {key: 'Arab', value: 'Arab'},
        {key: 'Aramean', value: 'Aramean'},
        {key: 'AA', value: 'Asian African'},
        {key: 'AC', value: 'Assyrian, Chaldean'},
        {key: 'BA', value: 'Berber/Amazigh'},
        {key: 'Kawliya', value: 'Kawliya'},
        {key: 'Kurd', value: 'Kurd'},
        {key: 'Malagasy', value: 'Malagasy'},
        {key: 'Mandean', value: 'Mandean'},
        {key: 'Persian', value: 'Persian'},
        {key: 'SSA', value: 'Saharan & sub-Saharan African'},
        {key: 'Shabak', value: 'Shabak'},
        {key: 'Turk', value: 'Turk'},
        {key: 'Yezids', value: 'Yezids'}
      ]
    },
    {
      name: 'Asia (including mixed heritage)',
      options: [
        {key: 'AF', value: 'Afghan'},
        {key: 'BD', value: 'Bangladeshi'},
        {key: 'BM', value: 'Burmese'},
        {key: 'KH', value: 'Cambodian'},
        {key: 'CN', value: 'Chinese'},
        {key: 'PH', value: 'Filipino'},
        {key: 'IN', value: 'Indian'},
        {key: 'ID', value: 'Indonesian'},
        {key: 'JP', value: 'Japanese'},
        {key: 'KZ', value: 'Kazakh'},
        {key: 'KR', value: 'Korean'},
        {key: 'LA', value: 'Laotian'},
        {key: 'MY', value: 'Malaysian'},
        {key: 'MN', value: 'Mongolian'},
        {key: 'NP', value: 'Nepalese'},
        {key: 'PK', value: 'Pakistani'},
        {key: 'SG', value: 'Singaporean'},
        {key: 'LK', value: 'Sri Lankan'},
        {key: 'TH', value: 'Thai'},
        {key: 'AZ', value: 'Turkic Azerbaijani'},
        {key: 'VN', value: 'Vietnamese'}
      ]
    },
    {
      name: 'North, Central, South America & Caribbean/Antilles (incl. mixed)',
      options: [
        {key: 'SA-African', value: 'African (South America)'},
        {key: 'US-AA', value: 'African / African-American (US)'},
        {key: 'CA-Black', value: 'African / Black Canadian (Canada)'},
        {key: 'MX-AfroMex', value: 'African/Afro-Mexican (Mexico)'},
        {key: 'Carib-Creole', value: 'African/Creole (Caribbean)'},
        {key: 'SA-Asian', value: 'Asian (South America)'},
        {key: 'US-Asian', value: 'Asian American (US)'},
        {key: 'CA-Asian', value: 'Asian Canadian (Canada)'},
        {key: 'MX-Asian', value: 'Asian Mexican (Mexico)'},
        {key: 'CA-NA', value: 'Native American (Canada)'},
        {key: 'US-NA', value: 'Native American (US)'},
        {key: 'CA-Mestizo', value: 'Native/Mestizo (Central America)'},
        {key: 'MX-Mestizo', value: 'Native/Mestizo (Mexico)'},
        {key: 'SA-Mestizo', value: 'Native/Mestizo (South America)'}
      ]
    },
    {
      name: 'South Pacific (incl. mixed)',
      options: [
        {key: 'Melanesian', value: 'Native Melanesian'},
        {key: 'Polynesian', value: 'Native Polynesian'}
      ]
    },
    {
      name: 'Other',
      options: [
        {key: 'Other', value: 'Other'}
      ]
    },
    {
      name: 'PNS',
      options: [
        {key: 'PNS', value: 'Prefer not to say'}
      ]
    }
  ];

  countryGroups = [
    {
      name: 'France',
      options: [
        {key: 'option_fr_1', value: 'Citizenship (Nationalité)'},
        {key: 'option_fr_2', value: 'Residence Permit (Titre de séjour)'},
        {key: 'option_fr_3', value: 'Long-stay Visa (Visa de long séjour)'},
        {key: 'option_fr_4', value: 'European Union Blue Card'}
      ]
    },
    {
      name: 'Germany',
      options: [
        {key: 'option_de_1', value: 'Citizenship (Staatsangehörigkeit)'},
        {key: 'option_de_2', value: 'Residence Permit (Aufenthaltstitel)'},
        {key: 'option_de_3', value: 'European Union Blue Card (Blaue Karte EU)'},
        {key: 'option_de_4', value: 'Settlement Permit (Niederlassungserlaubnis)'}
      ]
    },
    {
      name: 'Netherlands',
      options: [
        {key: 'option_nl_1', value: 'Citizenship (Nederlanderschap)'},
        {key: 'option_nl_2', value: 'Residence Permit (Verblijfsvergunning)'},
        {key: 'option_nl_3', value: 'European Union Blue Card'},
        {key: 'option_nl_4', value: 'Permanent Residence Permit (Permanente Verblijfsvergunning)'}
      ]
    }
  ];

  constructor(private fb: FormBuilder, private downloadService: DownloadFileService, private snackBar: MatSnackBar,
    private toastr: ToastrService) { }

  get owners(){
    return this.ownershipForm.controls['ownershipForm'] as FormArray;
  }

  addOwner() {
    const owner = this.fb.group({
      // is_owner_minority: [{value: '', disabled: true}],
      copy_details: [{value: '', disabled: true}],
      business_owner_firstname: [{value: '', disabled: true}],
      business_owner_lastname: [{value: '', disabled: true}],
      email_address: [{value: '', disabled: true}],
      job_title: [{value: '', disabled: true}],
      percentage_ownership: [{value: '', disabled: true}],
      ethnic_minority_group: [],
      // ethnic_minority_group: [{value: '', disabled: true}],
      is_european_union_national: [{value: '', disabled: true}],
      resident_status: [{value: '', disabled: true}],
      document: []
    });
    this.owners.push(owner);
  }

  getFileSize(index: number): string {
    const fileSizeInBytes = this.files[index].file.size;

    if (fileSizeInBytes < 1024) {
      return fileSizeInBytes + ' B';
    } else if (fileSizeInBytes < 1024 * 1024) {
      return (fileSizeInBytes / 1024).toFixed(2) + ' KB';
    } else {
      return (fileSizeInBytes / (1024 * 1024)).toFixed(2) + ' MB';
    }
  }

  async downLoadFile(id: any) {
    this.snackBar.open('Please wait file is downloading', '', {
      duration: 3000,
    });
    const response = await this.downloadService.onFileDownload(id);
    if (response.status === 'success') {
      this.snackBar.open('File downloaded successfully', 'Close', {
        duration: 6000,
      });
    } else {
      this.toastr.error('Some error occured, could not download the file');
    }
  }

  getRemoveFilesValue(): boolean {
    const currentStatus = this.ownershipForm?.value?.status?.current_status;
  
    // Check if current_status is 'CERTIFIED'
    if (currentStatus === 'CERTIFIED') {
      const statusHistory = this.ownershipForm?.value?.status?.status_history;
      const certifiedEntry = statusHistory.find((entry: any) => entry.status === 'CERTIFIED'
       && entry.datetime === this.ownershipForm?.value?.status?.status_datetime );
  
      if (certifiedEntry) {
        return !!certifiedEntry.metadata?.remove_files;
      }
    } else if (currentStatus === 'REJECTED') {
      const statusHistory = this.ownershipForm?.value?.status?.status_history;
      const rejectedEntry = statusHistory.find((entry: any) => entry.status === 'REJECTED');
  
      if (rejectedEntry) {
        return !!rejectedEntry.remove_files;
      }
    }
    // For statuses other than 'CERTIFIED', return false
    return false;
  }

}
