// export const environment = {
//   production: true,
//   firebase: {
//     apiKey: "AIzaSyC_if6G5Aker9BjotaaWwtfGWNot0LBah8",
//     authDomain: "emb-certification-staging.firebaseapp.com",
//     projectId: "emb-certification-staging",
//     storageBucket: "emb-certification-staging.appspot.com",
//     messagingSenderId: "380923447315",
//     appId: "1:380923447315:web:942d72f72609b9b98417e6"
//   },
//   recaptcha: {
//     siteKey: '6Ld_d7QpAAAAAGTdYrxlqyQyEsyPD1ImbQlkUNC_',
//   },
//   loginUrl: 'https://embportal.esdp-org.eu/login',
//  stripeKey: 'pk_live_51Of19LAY7Bb9zUXsEqmvFBXVZbntRuIf7nSSof2djxinqcAXvIEJEcVI7aMZFpfqUC91XFnhLyPUXd42ud2nrRuk00K6vTGN2C'
//   // pk_test_51Of19LAY7Bb9zUXsnUE1AurAgAmePDQnX029irU8vtCJo42tUih7F7hBr3b9yJY1lfJKaFSQcHcGr9DmeQfGorh90009yIPeOT
// };
export const environment = {
  production: true,

  firebase: {
    apiKey: "AIzaSyBeNXA06U3xfrk9E8gJEvu6kY7VNcD8pNk",
    authDomain: "emb-certification.firebaseapp.com",
    projectId: "emb-certification",
    storageBucket: "emb-certification.appspot.com",
    messagingSenderId: "264340741276",
    appId: "1:264340741276:web:69a5b83abc181ea3a2a159"
  },
  recaptcha: {
    siteKey: '6LdLipApAAAAAGSPFsZ_hTKCJhmXJlIdaCSxW9zn',
  },
  loginUrl: 'https://esdptest.bosenet.com/login',
  stripeKey: 'pk_test_51OsLj6RxHcwIZvoLyAoGBQ1mRrr92QVjuK7qNlaC5IIpCkXJkmeQOq49dDRPcQWpPE4RXMmzlIhXBSdXtLMwtN7d00H5xAkv6X'
};
