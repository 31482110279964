import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-final-submit',
  templateUrl: './dialog-final-submit.component.html',
  styleUrl: './dialog-final-submit.component.css'
})
export class DialogFinalSubmitComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
