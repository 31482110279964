import { Directive, ElementRef, HostListener, Input, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appVatNumber]'
})
export class VatNumberDirective {
  @Input() country!: string;

  constructor(@Self() private control: NgControl, private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: Event): void {
    const inputElement = (event.target as HTMLInputElement);
    const inputValue = (event.target as HTMLInputElement).value;

    let validCharacters: RegExp;
    switch (this.country) {
      case 'DE':
        validCharacters = /^DE[0-9]{9}$/;
        break;
      case 'NL':
        validCharacters = /^NL[0-9]{9}B[0-9]{2}$/;
        break;
      case 'FR':
        validCharacters = /^FR[A-HJ-NP-Z0-9]{2}[0-9]{9}$/;
        break;
      default:
        validCharacters = /^[a-zA-Z0-9 ]*$/;
        break;
    }

    // Check if the entire input value matches the valid characters
    if (!validCharacters.test(inputValue)) {
      // Set the form control to invalid
      inputElement.value = inputValue.replace(/[^0-9A-Za-z ]/g, '');
      this.control.control?.setErrors({ invalidFormat: true });
    } else {
      // If the input is valid, clear any existing errors
      this.control.control?.setErrors(null);
    }
  }

//   @HostListener('blur') onBlur(): void {
//     // Convert the entire input value to uppercase on blur
//     const inputElement = this.el.nativeElement as HTMLInputElement;
//     inputElement.value = inputElement.value.toUpperCase();
//   }
}
