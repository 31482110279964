import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";

export class CustomValidators {
  static passwordsMatching(control: AbstractControl): ValidationErrors | null {
    const password = control.get('password')?.value;
    const passwordConfirm = control.get('password_confirm')?.value;

    // Check if passwords are matching. If not then add the error 'passwordsNotMatching: true' to the form
    if ((password === passwordConfirm) && (password !== null && passwordConfirm !== null)) {
      return null;
    } else {
      return { passwordsNotMatching: true };
    }
  }

  static emailsMatching(control: AbstractControl): ValidationErrors | null {
    const email = control.get('email')?.value;
    const emailConfirm = control.get('email_confirm')?.value;

    // Check if passwords are matching. If not then add the error 'passwordsNotMatching: true' to the form
    if ((email === emailConfirm) && (email !== null && emailConfirm !== null)) {
      return null;
    } else {
      return { emailsNotMatching: true };
    }
  }

  static uniqueEmailsValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const formArray: any = control as FormGroup;
      const emails = formArray.controls.map((owner: FormGroup) => owner.get('email_address')?.value);
      const uniqueEmails = new Set(emails);
      if (emails.length !== uniqueEmails.size) {
        return { 'duplicateEmails': true };
      }
      return null;
    };
  }

  static maxSelectionValidator(max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const selectedOptions = control.value || [];
      return selectedOptions.length > max ? { maxSelection: { value: control.value } } : null;
    };
  }

}