import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-view-business-profile',
  templateUrl: './view-business-profile.component.html',
  styleUrl: './view-business-profile.component.css'
})
export class ViewBusinessProfileComponent implements OnChanges {
  @Input() businessProfileForm!: FormGroup;
  @Input() geographicalCoverageArray: any;

  grossTurnovers = [
    {
      key: 'Less than €1 million',
      value: 'less_than_1_million',
    },
    {
      key: '€1 million - €5 million',
      value: '1m_to_5m',
    },
    {
      key: '€5 million - €10 million',
      value: '5m_to_10m',
    },
    {
      key: '€10 million - €50 million',
      value: '10m_to_50m',
    },
    {
      key: '€50 million - €100 million',
      value: '50m_to_100m',
    },
    {
      key: '€100 million and above',
      value: '100m_and_above',
    },
  ];

  geographicalCoverage = [
    {
      country: 'Global',
      code: 'GLOBAL',
    },
    {
      country: 'Europe',
      code: 'EU',
    },
    {
      country: 'Albania',
      code: 'AL',
    },
    {
      country: 'Andorra',
      code: 'AD',
    },
    {
      country: 'Armenia',
      code: 'AM',
    },
    {
      country: 'Austria',
      code: 'AT',
    },
    {
      country: 'Azerbaijan',
      code: 'AZ',
    },
    {
      country: 'Belarus',
      code: 'BY',
    },
    {
      country: 'Belgium',
      code: 'BE',
    },
    {
      country: 'Bosnia and Herzegovina',
      code: 'BA',
    },
    {
      country: 'Bulgaria',
      code: 'BG',
    },
    {
      country: 'Croatia',
      code: 'HR',
    },
    {
      country: 'Cyprus',
      code: 'CY',
    },
    {
      country: 'Czech Republic',
      code: 'CZ',
    },
    {
      country: 'Denmark',
      code: 'DK',
    },
    {
      country: 'Estonia',
      code: 'EE',
    },
    {
      country: 'Finland',
      code: 'FI',
    },
    {
      country: 'France',
      code: 'FR',
    },
    {
      country: 'Germany',
      code: 'DE',
    },
    {
      country: 'Georgia',
      code: 'GE',
    },
    {
      country: 'Greece',
      code: 'GR',
    },
    {
      country: 'Hungary',
      code: 'HU',
    },
    {
      country: 'Iceland',
      code: 'IS',
    },
    {
      country: 'Ireland',
      code: 'IE',
    },
    {
      country: 'Italy',
      code: 'IT',
    },
    {
      country: 'Latvia',
      code: 'LV',
    },
    {
      country: 'Liechtenstein',
      code: 'LI',
    },
    {
      country: 'Lithuania',
      code: 'LT',
    },
    {
      country: 'Luxembourg',
      code: 'LU',
    },
    {
      country: 'Malta',
      code: 'MT',
    },
    {
      country: 'Moldova',
      code: 'MD',
    },
    {
      country: 'Monaco',
      code: 'MC',
    },
    {
      country: 'Montenegro',
      code: 'ME',
    },
    {
      country: 'Netherlands',
      code: 'NL',
    },
    {
      country: 'North Macedonia',
      code: 'MK',
    },
    {
      country: 'Norway',
      code: 'NO',
    },
    {
      country: 'Poland',
      code: 'PL',
    },
    {
      country: 'Portugal',
      code: 'PT',
    },
    {
      country: 'Romania',
      code: 'RO',
    },
    {
      country: 'Russia',
      code: 'RU',
    },
    {
      country: 'San Marino',
      code: 'SM',
    },
    {
      country: 'Serbia',
      code: 'RS',
    },
    {
      country: 'Slovakia',
      code: 'SK',
    },
    {
      country: 'Slovenia',
      code: 'SI',
    },
    {
      country: 'Spain',
      code: 'ES',
    },
    {
      country: 'Sweden',
      code: 'SE',
    },
    {
      country: 'Switzerland',
      code: 'CH',
    },
    {
      country: 'Turkey',
      code: 'TR',
    },
    {
      country: 'Ukraine',
      code: 'UA',
    },
    {
      country: 'United Kingdom',
      code: 'UK',
    },
    {
      country: 'Vatican City',
      code: 'VA',
    },
  ];

  industrySectors = [
    {
      title: 'Advertising, PR, Marketing & Print',
      key: 'advertising_pr_marketing_and_print',
    },
    {
      title: 'Agriculture & Farming',
      key: 'agriculture_and_farming',
    },
    {
      title: 'Apparel & Clothing',
      key: 'apparel_and_clothing',
    },
    {
      title: 'Architecture, Design & Construction',
      key: 'architecture_design_and_construction',
    },
    {
      title: 'Business Services / Consultancy',
      key: 'business_services_or_consultancy',
    },
    {
      title: 'Consumer Goods',
      key: 'consumer_goods',
    },
    {
      title: 'Education & Training',
      key: 'education_and_training',
    },
    {
      title: 'Event Management',
      key: 'event_management',
    },
    {
      title: 'Facilities Management',
      key: 'facilities_management',
    },
    {
      title: 'Financial Services',
      key: 'financial_services',
    },
    {
      title: 'Healthcare, Pharmaceutical & Medical',
      key: 'healthcare_pharmaceutical_and_medical',
    },
    {
      title: 'Hospitality & Catering',
      key: 'hospitality_and_catering',
    },
    {
      title: 'Information Technology & Telecommunications',
      key: 'information_technology_and_telecommunications',
    },
    {
      title: 'Insurance',
      key: 'insurance',
    },
    {
      title: 'Leisure, Travel & Tourism',
      key: 'leisure_travel_and_tourism',
    },
    {
      title: 'Manufacturing, Industrial & Engineering',
      key: 'manufacturing_industrial_and_engineering',
    },
    {
      title: 'Office Suppliers & Promotional Goods',
      key: 'office_suppliers_and_promotional_goods',
    },
    {
      title: 'Packaging',
      key: 'packaging',
    },
    {
      title: 'Raw Materials & Chemicals',
      key: 'raw_materials_and_chemicals',
    },
    {
      title: 'Real-estate',
      key: 'real-estate',
    },
    {
      title: 'Recruitment & Staffing',
      key: 'recruitment_and_staffing',
    },
    {
      title: 'Sustainability & Environment',
      key: 'sustainability_and_environment',
    },
    {
      title: 'Transports, Logistics & Warehousing',
      key: 'transports_logistics_and_warehousing',
    },
    {
      title: 'Utilities',
      key: 'utilities',
    },
  ];

  services = [
    {
        "parent": "facilities_management",
        "title": "Cleanroom cleaning",
        "key": "facilities_management_cleanroom_cleaning"
    },
    {
        "parent": "advertising_pr_marketing_and_print",
        "title": "Advertising",
        "key": "advertising_pr_marketing_and_print_advertising"
    },
    {
        "parent": "advertising_pr_marketing_and_print",
        "title": "Animation",
        "key": "advertising_pr_marketing_and_print_animation"
    },
    {
        "parent": "advertising_pr_marketing_and_print",
        "title": "Brand Management",
        "key": "advertising_pr_marketing_and_print_brand_management"
    },
    {
        "parent": "advertising_pr_marketing_and_print",
        "title": "Creative Design Agency",
        "key": "advertising_pr_marketing_and_print_creative_design_agency"
    },
    {
        "parent": "advertising_pr_marketing_and_print",
        "title": "Digital Marketing & Print",
        "key": "advertising_pr_marketing_and_print_digital_marketing_and_print"
    },
    {
        "parent": "advertising_pr_marketing_and_print",
        "title": "End-to-end marketing agency",
        "key": "advertising_pr_marketing_and_print_end_to_end_marketing_agency"
    },
    {
        "parent": "advertising_pr_marketing_and_print",
        "title": "Enterprise 2.0",
        "key": "advertising_pr_marketing_and_print_enterprise_2_dot_0"
    },
    {
        "parent": "advertising_pr_marketing_and_print",
        "title": "Ethnic Media Marketing",
        "key": "advertising_pr_marketing_and_print_ethnic_media_marketing"
    },
    {
        "parent": "advertising_pr_marketing_and_print",
        "title": "Film Making",
        "key": "advertising_pr_marketing_and_print_film_making"
    },
    {
        "parent": "advertising_pr_marketing_and_print",
        "title": "Graphic Design",
        "key": "advertising_pr_marketing_and_print_graphic_design"
    },
    {
        "parent": "advertising_pr_marketing_and_print",
        "title": "Market Research",
        "key": "advertising_pr_marketing_and_print_market_research"
    },
    {
        "parent": "advertising_pr_marketing_and_print",
        "title": "Marketing Technology & Automation",
        "key": "advertising_pr_marketing_and_print_marketing_technology_and_automation"
    },
    {
        "parent": "advertising_pr_marketing_and_print",
        "title": "Media Agency",
        "key": "advertising_pr_marketing_and_print_media_agency"
    },
    {
        "parent": "advertising_pr_marketing_and_print",
        "title": "Media Production",
        "key": "advertising_pr_marketing_and_print_media_production"
    },
    {
        "parent": "advertising_pr_marketing_and_print",
        "title": "Online Media",
        "key": "advertising_pr_marketing_and_print_online_media"
    },
    {
        "parent": "advertising_pr_marketing_and_print",
        "title": "Photography",
        "key": "advertising_pr_marketing_and_print_photography"
    },
    {
        "parent": "advertising_pr_marketing_and_print",
        "title": "PR & Communications",
        "key": "advertising_pr_marketing_and_print_pr_and_communications"
    },
    {
        "parent": "advertising_pr_marketing_and_print",
        "title": "Print Fulfilment",
        "key": "advertising_pr_marketing_and_print_print_fulfilment"
    },
    {
        "parent": "advertising_pr_marketing_and_print",
        "title": "Print Management",
        "key": "advertising_pr_marketing_and_print_print_management"
    },
    {
        "parent": "advertising_pr_marketing_and_print",
        "title": "Print Visual Media",
        "key": "advertising_pr_marketing_and_print_print_visual_media"
    },
    {
        "parent": "advertising_pr_marketing_and_print",
        "title": "Product Launch",
        "key": "advertising_pr_marketing_and_print_product_launch"
    },
    {
        "parent": "advertising_pr_marketing_and_print",
        "title": "Publishing",
        "key": "advertising_pr_marketing_and_print_publishing"
    },
    {
        "parent": "advertising_pr_marketing_and_print",
        "title": "Social Media Agency",
        "key": "advertising_pr_marketing_and_print_social_media_agency"
    },
    {
        "parent": "advertising_pr_marketing_and_print",
        "title": "Telemarketing & Sales",
        "key": "advertising_pr_marketing_and_print_telemarketing_and_sales"
    },
    {
        "parent": "advertising_pr_marketing_and_print",
        "title": "Video Production",
        "key": "advertising_pr_marketing_and_print_video_production"
    },
    {
        "parent": "advertising_pr_marketing_and_print",
        "title": "Web 2.0",
        "key": "advertising_pr_marketing_and_print_web_2_dot_0"
    },
    {
        "parent": "advertising_pr_marketing_and_print",
        "title": "Web Marketing & Communication",
        "key": "advertising_pr_marketing_and_print_web_marketing_and_communication"
    },
    {
        "parent": "advertising_pr_marketing_and_print",
        "title": "Website Design",
        "key": "advertising_pr_marketing_and_print_website_design"
    },
    {
        "parent": "advertising_pr_marketing_and_print",
        "title": "Writing & Editing",
        "key": "advertising_pr_marketing_and_print_writing_and_editing"
    },
    {
        "parent": "agriculture_and_farming",
        "title": "Agritech",
        "key": "agriculture_and_farming_agritech"
    },
    {
        "parent": "agriculture_and_farming",
        "title": "Coffee Production",
        "key": "agriculture_and_farming_coffee_production"
    },
    {
        "parent": "agriculture_and_farming",
        "title": "Dairy",
        "key": "agriculture_and_farming_dairy"
    },
    {
        "parent": "agriculture_and_farming",
        "title": "Fishery",
        "key": "agriculture_and_farming_fishery"
    },
    {
        "parent": "agriculture_and_farming",
        "title": "Food Production",
        "key": "agriculture_and_farming_food_production"
    },
    {
        "parent": "agriculture_and_farming",
        "title": "Ranching",
        "key": "agriculture_and_farming_ranching"
    },
    {
        "parent": "apparel_and_clothing",
        "title": "Apparel/Clothing Manufacturer",
        "key": "apparel_and_clothing_apparel_or_clothing_manufacturer"
    },
    {
        "parent": "apparel_and_clothing",
        "title": "Fashion Accessories",
        "key": "apparel_and_clothing_fashion_accessories"
    },
    {
        "parent": "apparel_and_clothing",
        "title": "Merchandising",
        "key": "apparel_and_clothing_merchandising"
    },
    {
        "parent": "apparel_and_clothing",
        "title": "Personal Protective Equipment",
        "key": "apparel_and_clothing_personal_protective_equipment"
    },
    {
        "parent": "apparel_and_clothing",
        "title": "Sportswear",
        "key": "apparel_and_clothing_sportswear"
    },
    {
        "parent": "apparel_and_clothing",
        "title": "Textiles",
        "key": "apparel_and_clothing_textiles"
    },
    {
        "parent": "apparel_and_clothing",
        "title": "Workwear / Corporate ware",
        "key": "apparel_and_clothing_workwear_or_corporate_ware"
    },
    {
        "parent": "architecture_design_and_construction",
        "title": "Architectural Design",
        "key": "architecture_design_and_construction_architectural_design"
    },
    {
        "parent": "architecture_design_and_construction",
        "title": "Building Material",
        "key": "architecture_design_and_construction_building_material"
    },
    {
        "parent": "architecture_design_and_construction",
        "title": "Civil & Structural Design Consultants",
        "key": "architecture_design_and_construction_civil_and_structural_design_consultants"
    },
    {
        "parent": "architecture_design_and_construction",
        "title": "Construction",
        "key": "architecture_design_and_construction_construction"
    },
    {
        "parent": "architecture_design_and_construction",
        "title": "Design Services",
        "key": "architecture_design_and_construction_design_services"
    },
    {
        "parent": "architecture_design_and_construction",
        "title": "Dismantling and remediation services",
        "key": "architecture_design_and_construction_dismantling_and_remediation_services"
    },
    {
        "parent": "architecture_design_and_construction",
        "title": "Electrical Engineering",
        "key": "architecture_design_and_construction_electrical_engineering"
    },
    {
        "parent": "architecture_design_and_construction",
        "title": "End-to-end general construction contractor",
        "key": "architecture_design_and_construction_end_to_end_general_construction_contractor"
    },
    {
        "parent": "architecture_design_and_construction",
        "title": "Engineering Services",
        "key": "architecture_design_and_construction_engineering_services"
    },
    {
        "parent": "architecture_design_and_construction",
        "title": "Facade Specialists",
        "key": "architecture_design_and_construction_facade_specialists"
    },
    {
        "parent": "architecture_design_and_construction",
        "title": "Flooring Specialists",
        "key": "architecture_design_and_construction_flooring_specialists"
    },
    {
        "parent": "architecture_design_and_construction",
        "title": "Furniture",
        "key": "architecture_design_and_construction_furniture"
    },
    {
        "parent": "architecture_design_and_construction",
        "title": "Glass, Ceramics & Concreating",
        "key": "architecture_design_and_construction_glass_ceramics_and_concreating"
    },
    {
        "parent": "architecture_design_and_construction",
        "title": "Groundworks",
        "key": "architecture_design_and_construction_groundworks"
    },
    {
        "parent": "architecture_design_and_construction",
        "title": "Insulation & Renovation Specialists",
        "key": "architecture_design_and_construction_insulation_and_renovation_specialists"
    },
    {
        "parent": "architecture_design_and_construction",
        "title": "Joinery",
        "key": "architecture_design_and_construction_joinery"
    },
    {
        "parent": "architecture_design_and_construction",
        "title": "Mechanical Engineering",
        "key": "architecture_design_and_construction_mechanical_engineering"
    },
    {
        "parent": "architecture_design_and_construction",
        "title": "Office Design",
        "key": "architecture_design_and_construction_office_design"
    },
    {
        "parent": "architecture_design_and_construction",
        "title": "Pipes & Drains",
        "key": "architecture_design_and_construction_pipes_and_drains"
    },
    {
        "parent": "architecture_design_and_construction",
        "title": "Plant & Machine Hire",
        "key": "architecture_design_and_construction_plant_and_machine_hire"
    },
    {
        "parent": "architecture_design_and_construction",
        "title": "Plastering",
        "key": "architecture_design_and_construction_plastering"
    },
    {
        "parent": "architecture_design_and_construction",
        "title": "Product Suppliers",
        "key": "architecture_design_and_construction_product_suppliers"
    },
    {
        "parent": "architecture_design_and_construction",
        "title": "Project Management",
        "key": "architecture_design_and_construction_project_management"
    },
    {
        "parent": "architecture_design_and_construction",
        "title": "Protect Valuation",
        "key": "architecture_design_and_construction_protect_valuation"
    },
    {
        "parent": "architecture_design_and_construction",
        "title": "Refrigeration Services",
        "key": "architecture_design_and_construction_refrigeration_services"
    },
    {
        "parent": "architecture_design_and_construction",
        "title": "Roofing, cladding and waterproofing",
        "key": "architecture_design_and_construction_roofing_cladding_and_waterproofing"
    },
    {
        "parent": "architecture_design_and_construction",
        "title": "Scaffolding",
        "key": "architecture_design_and_construction_scaffolding"
    },
    {
        "parent": "architecture_design_and_construction",
        "title": "Sustainable Construction",
        "key": "architecture_design_and_construction_sustainable_construction"
    },
    {
        "parent": "architecture_design_and_construction",
        "title": "Steel Fixing",
        "key": "architecture_design_and_construction_steel_fixing"
    },
    {
        "parent": "architecture_design_and_construction",
        "title": "Stone Supply",
        "key": "architecture_design_and_construction_stone_supply"
    },
    {
        "parent": "architecture_design_and_construction",
        "title": "Structural Engineering",
        "key": "architecture_design_and_construction_structural_engineering"
    },
    {
        "parent": "architecture_design_and_construction",
        "title": "Surveying",
        "key": "architecture_design_and_construction_surveying"
    },
    {
        "parent": "architecture_design_and_construction",
        "title": "Tile & Mosaic services",
        "key": "architecture_design_and_construction_tile_and_mosaic_services"
    },
    {
        "parent": "architecture_design_and_construction",
        "title": "Water protection systems",
        "key": "architecture_design_and_construction_water_protection_systems"
    },
    {
        "parent": "business_services_or_consultancy",
        "title": "Bid Writing",
        "key": "business_services_or_consultancy_bid_writing"
    },
    {
        "parent": "business_services_or_consultancy",
        "title": "Bid management & Tender Consultancy",
        "key": "business_services_or_consultancy_bid_management_and_tender_consultancy"
    },
    {
        "parent": "business_services_or_consultancy",
        "title": "Consulting",
        "key": "business_services_or_consultancy_consulting"
    },
    {
        "parent": "business_services_or_consultancy",
        "title": "Data Services",
        "key": "business_services_or_consultancy_data_services"
    },
    {
        "parent": "business_services_or_consultancy",
        "title": "Diversity, Equity and Inclusion",
        "key": "business_services_or_consultancy_diversity_equity_and_inclusion"
    },
    {
        "parent": "business_services_or_consultancy",
        "title": "Document Storage & Archiving",
        "key": "business_services_or_consultancy_document_storage_and_archiving"
    },
    {
        "parent": "business_services_or_consultancy",
        "title": "Employee Benefits",
        "key": "business_services_or_consultancy_employee_benefits"
    },
    {
        "parent": "business_services_or_consultancy",
        "title": "HR & Payroll",
        "key": "business_services_or_consultancy_hr_and_payroll"
    },
    {
        "parent": "business_services_or_consultancy",
        "title": "Insurance",
        "key": "business_services_or_consultancy_insurance"
    },
    {
        "parent": "business_services_or_consultancy",
        "title": "Intellectual Property Services",
        "key": "business_services_or_consultancy_intellectual_property_services"
    },
    {
        "parent": "business_services_or_consultancy",
        "title": "Legal Services",
        "key": "business_services_or_consultancy_legal_services"
    },
    {
        "parent": "business_services_or_consultancy",
        "title": "Mentoring",
        "key": "business_services_or_consultancy_mentoring"
    },
    {
        "parent": "business_services_or_consultancy",
        "title": "Media",
        "key": "business_services_or_consultancy_media"
    },
    {
        "parent": "business_services_or_consultancy",
        "title": "Office / Room Hire",
        "key": "business_services_or_consultancy_office_or_room_hire"
    },
    {
        "parent": "business_services_or_consultancy",
        "title": "Outsourcing",
        "key": "business_services_or_consultancy_outsourcing"
    },
    {
        "parent": "business_services_or_consultancy",
        "title": "Relocation Services",
        "key": "business_services_or_consultancy_relocation_services"
    },
    {
        "parent": "business_services_or_consultancy",
        "title": "Risk Management Consultancy",
        "key": "business_services_or_consultancy_risk_management_consultancy"
    },
    {
        "parent": "business_services_or_consultancy",
        "title": "Scanning",
        "key": "business_services_or_consultancy_scanning"
    },
    {
        "parent": "business_services_or_consultancy",
        "title": "Supply Chain Management",
        "key": "business_services_or_consultancy_supply_chain_management"
    },
    {
        "parent": "business_services_or_consultancy",
        "title": "Trade Mark Lawyers",
        "key": "business_services_or_consultancy_trade_mark_lawyers"
    },
    {
        "parent": "business_services_or_consultancy",
        "title": "Translation Services",
        "key": "business_services_or_consultancy_translation_services"
    },
    {
        "parent": "business_services_or_consultancy",
        "title": "Workspaces Services",
        "key": "business_services_or_consultancy_workspaces_services"
    },
    {
        "parent": "consumer_goods",
        "title": "Electronics",
        "key": "consumer_goods_electronics"
    },
    {
        "parent": "consumer_goods",
        "title": "Fashion / Apparel",
        "key": "consumer_goods_fashion_or_apparel"
    },
    {
        "parent": "consumer_goods",
        "title": "General Goods",
        "key": "consumer_goods_general_goods"
    },
    {
        "parent": "consumer_goods",
        "title": "Health & Beauty",
        "key": "consumer_goods_health_and_beauty"
    },
    {
        "parent": "consumer_goods",
        "title": "Jewellery",
        "key": "consumer_goods_jewellery"
    },
    {
        "parent": "consumer_goods",
        "title": "Retail",
        "key": "consumer_goods_retail"
    },
    {
        "parent": "consumer_goods",
        "title": "Sustainable Products",
        "key": "consumer_goods_sustainable_products"
    },
    {
        "parent": "consumer_goods",
        "title": "Textiles",
        "key": "consumer_goods_textiles"
    },
    {
        "parent": "consumer_goods",
        "title": "Inclusive Toys / Games",
        "key": "consumer_goods_inclusive_toys_or_games"
    },
    {
        "parent": "consumer_goods",
        "title": "Wholesale of ethnic and exotic products",
        "key": "consumer_goods_wholesale_of_ethnic_and_exotic_products"
    },
    {
        "parent": "consumer_goods",
        "title": "Wholesale",
        "key": "consumer_goods_wholesale"
    },
    {
        "parent": "education_and_training",
        "title": "Change Management",
        "key": "education_and_training_change_management"
    },
    {
        "parent": "education_and_training",
        "title": "Clerical Training",
        "key": "education_and_training_clerical_training"
    },
    {
        "parent": "education_and_training",
        "title": "Coaching / Professional Development",
        "key": "education_and_training_coaching_or_professional_development"
    },
    {
        "parent": "education_and_training",
        "title": "Consultancy",
        "key": "education_and_training_consultancy"
    },
    {
        "parent": "education_and_training",
        "title": "Diversity Training",
        "key": "education_and_training_diversity_training"
    },
    {
        "parent": "education_and_training",
        "title": "Financial Education",
        "key": "education_and_training_financial_education"
    },
    {
        "parent": "education_and_training",
        "title": "Higher Education",
        "key": "education_and_training_higher_education"
    },
    {
        "parent": "education_and_training",
        "title": "IT Training",
        "key": "education_and_training_it_training"
    },
    {
        "parent": "education_and_training",
        "title": "Language Training Services",
        "key": "education_and_training_language_training_services"
    },
    {
        "parent": "education_and_training",
        "title": "Leadership Development Training",
        "key": "education_and_training_leadership_development_training"
    },
    {
        "parent": "education_and_training",
        "title": "Management Training",
        "key": "education_and_training_management_training"
    },
    {
        "parent": "education_and_training",
        "title": "Primary / Secondary Education",
        "key": "education_and_training_primary_or_secondary_education"
    },
    {
        "parent": "education_and_training",
        "title": "Sales Training",
        "key": "education_and_training_sales_training"
    },
    {
        "parent": "education_and_training",
        "title": "Supply Chain Training",
        "key": "education_and_training_supply_chain_training"
    },
    {
        "parent": "education_and_training",
        "title": "Team Building Training",
        "key": "education_and_training_team_building_training"
    },
    {
        "parent": "education_and_training",
        "title": "Workforce Mediation",
        "key": "education_and_training_workforce_mediation"
    },
    {
        "parent": "event_management",
        "title": "Audio – Visual",
        "key": "event_management_audio_visual"
    },
    {
        "parent": "event_management",
        "title": "Conference Management",
        "key": "event_management_conference_management"
    },
    {
        "parent": "event_management",
        "title": "Corporate Event Hospitality",
        "key": "event_management_corporate_event_hospitality"
    },
    {
        "parent": "event_management",
        "title": "Entertainment",
        "key": "event_management_entertainment"
    },
    {
        "parent": "event_management",
        "title": "Event Catering",
        "key": "event_management_event_catering"
    },
    {
        "parent": "event_management",
        "title": "Event Supplies",
        "key": "event_management_event_supplies"
    },
    {
        "parent": "event_management",
        "title": "Exhibition & Trade Shows",
        "key": "event_management_exhibition_and_trade_shows"
    },
    {
        "parent": "event_management",
        "title": "Full Event Management Services",
        "key": "event_management_full_event_management_services"
    },
    {
        "parent": "event_management",
        "title": "Product Launches",
        "key": "event_management_product_launches"
    },
    {
        "parent": "event_management",
        "title": "Road Shows",
        "key": "event_management_road_shows"
    },
    {
        "parent": "event_management",
        "title": "Speciality Corporate events",
        "key": "event_management_speciality_corporate_events"
    },
    {
        "parent": "event_management",
        "title": "Team Building Activities",
        "key": "event_management_team_building_activities"
    },
    {
        "parent": "event_management",
        "title": "Venue Hire",
        "key": "event_management_venue_hire"
    },
    {
        "parent": "facilities_management",
        "title": "Building Maintenance",
        "key": "facilities_management_building_maintenance"
    },
    {
        "parent": "facilities_management",
        "title": "Cash Security",
        "key": "facilities_management_cash_security"
    },
    {
        "parent": "facilities_management",
        "title": "Commercial catering equipment",
        "key": "facilities_management_commercial_catering_equipment"
    },
    {
        "parent": "facilities_management",
        "title": "Commercial Lighting",
        "key": "facilities_management_commercial_lighting"
    },
    {
        "parent": "facilities_management",
        "title": "Concierge Service",
        "key": "facilities_management_concierge_service"
    },
    {
        "parent": "facilities_management",
        "title": "Dry Cleaning Services",
        "key": "facilities_management_dry_cleaning_services"
    },
    {
        "parent": "facilities_management",
        "title": "Ventilation Management Services",
        "key": "facilities_management_ventilation_management_services"
    },
    {
        "parent": "facilities_management",
        "title": "Construction Cleaning",
        "key": "facilities_management_construction_cleaning"
    },
    {
        "parent": "facilities_management",
        "title": "Electrical Services",
        "key": "facilities_management_electrical_services"
    },
    {
        "parent": "facilities_management",
        "title": "Environmental Services",
        "key": "facilities_management_environmental_services"
    },
    {
        "parent": "facilities_management",
        "title": "Floor Cleaning",
        "key": "facilities_management_floor_cleaning"
    },
    {
        "parent": "facilities_management",
        "title": "Flooring",
        "key": "facilities_management_flooring"
    },
    {
        "parent": "facilities_management",
        "title": "Glazing",
        "key": "facilities_management_glazing"
    },
    {
        "parent": "facilities_management",
        "title": "Grounds & Parks Maintenance",
        "key": "facilities_management_grounds_and_parks_maintenance"
    },
    {
        "parent": "facilities_management",
        "title": "Health & Safety",
        "key": "facilities_management_health_and_safety"
    },
    {
        "parent": "facilities_management",
        "title": "HVAC (Heating, Ventilation, and Air-conditioning)",
        "key": "facilities_management_hvac_heating_ventilation_and_air_conditioning"
    },
    {
        "parent": "facilities_management",
        "title": "Industrial Cleaning",
        "key": "facilities_management_industrial_cleaning"
    },
    {
        "parent": "facilities_management",
        "title": "Industrial Lighting",
        "key": "facilities_management_industrial_lighting"
    },
    {
        "parent": "facilities_management",
        "title": "Janitorial Services",
        "key": "facilities_management_janitorial_services"
    },
    {
        "parent": "facilities_management",
        "title": "Landscaping",
        "key": "facilities_management_landscaping"
    },
    {
        "parent": "facilities_management",
        "title": "LED Lighting",
        "key": "facilities_management_led_lighting"
    },
    {
        "parent": "facilities_management",
        "title": "Manned Security",
        "key": "facilities_management_manned_security"
    },
    {
        "parent": "facilities_management",
        "title": "Office Cleaning",
        "key": "facilities_management_office_cleaning"
    },
    {
        "parent": "facilities_management",
        "title": "Painting & Decorating",
        "key": "facilities_management_painting_and_decorating"
    },
    {
        "parent": "facilities_management",
        "title": "Pest Control",
        "key": "facilities_management_pest_control"
    },
    {
        "parent": "facilities_management",
        "title": "Plumbing & Central heating",
        "key": "facilities_management_plumbing_and_central_heating"
    },
    {
        "parent": "facilities_management",
        "title": "Refrigeration",
        "key": "facilities_management_refrigeration"
    },
    {
        "parent": "facilities_management",
        "title": "Refurbishment Removals",
        "key": "facilities_management_refurbishment_removals"
    },
    {
        "parent": "facilities_management",
        "title": "Security Systems",
        "key": "facilities_management_security_systems"
    },
    {
        "parent": "facilities_management",
        "title": "Signage",
        "key": "facilities_management_signage"
    },
    {
        "parent": "facilities_management",
        "title": "Total FM Solutions",
        "key": "facilities_management_total_fm_solutions"
    },
    {
        "parent": "facilities_management",
        "title": "Traffic Management",
        "key": "facilities_management_traffic_management"
    },
    {
        "parent": "facilities_management",
        "title": "Waste Management",
        "key": "facilities_management_waste_management"
    },
    {
        "parent": "facilities_management",
        "title": "Window Cleaning",
        "key": "facilities_management_window_cleaning"
    },
    {
        "parent": "financial_services",
        "title": "Accounting",
        "key": "financial_services_accounting"
    },
    {
        "parent": "financial_services",
        "title": "Auditing",
        "key": "financial_services_auditing"
    },
    {
        "parent": "financial_services",
        "title": "Banking",
        "key": "financial_services_banking"
    },
    {
        "parent": "financial_services",
        "title": "Fundraising",
        "key": "financial_services_fundraising"
    },
    {
        "parent": "financial_services",
        "title": "Investment Banking",
        "key": "financial_services_investment_banking"
    },
    {
        "parent": "financial_services",
        "title": "Investment Management",
        "key": "financial_services_investment_management"
    },
    {
        "parent": "financial_services",
        "title": "Venture Capital & Private Equity",
        "key": "financial_services_venture_capital_and_private_equity"
    },
    {
        "parent": "healthcare_pharmaceutical_and_medical",
        "title": "Alternative Medicine",
        "key": "healthcare_pharmaceutical_and_medical_alternative_medicine"
    },
    {
        "parent": "healthcare_pharmaceutical_and_medical",
        "title": "Biotechnology",
        "key": "healthcare_pharmaceutical_and_medical_biotechnology"
    },
    {
        "parent": "healthcare_pharmaceutical_and_medical",
        "title": "Clinical Audit",
        "key": "healthcare_pharmaceutical_and_medical_clinical_audit"
    },
    {
        "parent": "healthcare_pharmaceutical_and_medical",
        "title": "Contract Clinical Research",
        "key": "healthcare_pharmaceutical_and_medical_contract_clinical_research"
    },
    {
        "parent": "healthcare_pharmaceutical_and_medical",
        "title": "Hospital & Healthcare",
        "key": "healthcare_pharmaceutical_and_medical_hospital_and_healthcare"
    },
    {
        "parent": "healthcare_pharmaceutical_and_medical",
        "title": "Lab Testing",
        "key": "healthcare_pharmaceutical_and_medical_lab_testing"
    },
    {
        "parent": "healthcare_pharmaceutical_and_medical",
        "title": "Medical Devices",
        "key": "healthcare_pharmaceutical_and_medical_medical_devices"
    },
    {
        "parent": "healthcare_pharmaceutical_and_medical",
        "title": "Medical Practice",
        "key": "healthcare_pharmaceutical_and_medical_medical_practice"
    },
    {
        "parent": "healthcare_pharmaceutical_and_medical",
        "title": "Mental Healthcare",
        "key": "healthcare_pharmaceutical_and_medical_mental_healthcare"
    },
    {
        "parent": "healthcare_pharmaceutical_and_medical",
        "title": "Pharmaceuticals",
        "key": "healthcare_pharmaceutical_and_medical_pharmaceuticals"
    },
    {
        "parent": "healthcare_pharmaceutical_and_medical",
        "title": "Product Trials",
        "key": "healthcare_pharmaceutical_and_medical_product_trials"
    },
    {
        "parent": "healthcare_pharmaceutical_and_medical",
        "title": "Public Health",
        "key": "healthcare_pharmaceutical_and_medical_public_health"
    },
    {
        "parent": "healthcare_pharmaceutical_and_medical",
        "title": "Social care",
        "key": "healthcare_pharmaceutical_and_medical_social_care"
    },
    {
        "parent": "healthcare_pharmaceutical_and_medical",
        "title": "Surgical & Clinical Goods Supplies",
        "key": "healthcare_pharmaceutical_and_medical_surgical_and_clinical_goods_supplies"
    },
    {
        "parent": "healthcare_pharmaceutical_and_medical",
        "title": "Wellness & Fitness",
        "key": "healthcare_pharmaceutical_and_medical_wellness_and_fitness"
    },
    {
        "parent": "hospitality_and_catering",
        "title": "Kitchen Equipment",
        "key": "hospitality_and_catering_kitchen_equipment"
    },
    {
        "parent": "hospitality_and_catering",
        "title": "Cafeteria Management",
        "key": "hospitality_and_catering_cafeteria_management"
    },
    {
        "parent": "hospitality_and_catering",
        "title": "Catering",
        "key": "hospitality_and_catering_catering"
    },
    {
        "parent": "hospitality_and_catering",
        "title": "Dairy Products",
        "key": "hospitality_and_catering_dairy_products"
    },
    {
        "parent": "hospitality_and_catering",
        "title": "Ethnic Food Catering",
        "key": "hospitality_and_catering_ethnic_food_catering"
    },
    {
        "parent": "hospitality_and_catering",
        "title": "Ethnic Food / Ingredients Wholesaler",
        "key": "hospitality_and_catering_ethnic_food_or_ingredients_wholesaler"
    },
    {
        "parent": "hospitality_and_catering",
        "title": "Food & Beverage Supplies",
        "key": "hospitality_and_catering_food_and_beverage_supplies"
    },
    {
        "parent": "hospitality_and_catering",
        "title": "Food & Ingredients Wholesaler",
        "key": "hospitality_and_catering_food_and_ingredients_wholesaler"
    },
    {
        "parent": "hospitality_and_catering",
        "title": "Food Wholeseller",
        "key": "hospitality_and_catering_food_wholeseller"
    },
    {
        "parent": "hospitality_and_catering",
        "title": "Fresh Produce",
        "key": "hospitality_and_catering_fresh_produce"
    },
    {
        "parent": "hospitality_and_catering",
        "title": "Frozen/Meat Products",
        "key": "hospitality_and_catering_frozenormeat_products"
    },
    {
        "parent": "hospitality_and_catering",
        "title": "Product Supplies",
        "key": "hospitality_and_catering_product_supplies"
    },
    {
        "parent": "hospitality_and_catering",
        "title": "Vending Machines",
        "key": "hospitality_and_catering_vending_machines"
    },
    {
        "parent": "information_technology_and_telecommunications",
        "title": "AI and Robotics",
        "key": "information_technology_and_telecommunications_ai_and_robotics"
    },
    {
        "parent": "information_technology_and_telecommunications",
        "title": "Application Development",
        "key": "information_technology_and_telecommunications_application_development"
    },
    {
        "parent": "information_technology_and_telecommunications",
        "title": "Automation",
        "key": "information_technology_and_telecommunications_automation"
    },
    {
        "parent": "information_technology_and_telecommunications",
        "title": "Blockchain",
        "key": "information_technology_and_telecommunications_blockchain"
    },
    {
        "parent": "information_technology_and_telecommunications",
        "title": "Cloud Computing",
        "key": "information_technology_and_telecommunications_cloud_computing"
    },
    {
        "parent": "information_technology_and_telecommunications",
        "title": "Computer Equipment Disposal",
        "key": "information_technology_and_telecommunications_computer_equipment_disposal"
    },
    {
        "parent": "information_technology_and_telecommunications",
        "title": "Cyber Security",
        "key": "information_technology_and_telecommunications_cyber_security"
    },
    {
        "parent": "information_technology_and_telecommunications",
        "title": "Fintech Solutions",
        "key": "information_technology_and_telecommunications_fintech_solutions"
    },
    {
        "parent": "information_technology_and_telecommunications",
        "title": "E-Commerce Solutions",
        "key": "information_technology_and_telecommunications_e_commerce_solutions"
    },
    {
        "parent": "information_technology_and_telecommunications",
        "title": "Gaming",
        "key": "information_technology_and_telecommunications_gaming"
    },
    {
        "parent": "information_technology_and_telecommunications",
        "title": "Hardware, Peripherals & Networks",
        "key": "information_technology_and_telecommunications_hardware_peripherals_and_networks"
    },
    {
        "parent": "information_technology_and_telecommunications",
        "title": "Hosted Solutions",
        "key": "information_technology_and_telecommunications_hosted_solutions"
    },
    {
        "parent": "information_technology_and_telecommunications",
        "title": "Immersive Technology",
        "key": "information_technology_and_telecommunications_immersive_technology"
    },
    {
        "parent": "information_technology_and_telecommunications",
        "title": "IOT",
        "key": "information_technology_and_telecommunications_iot"
    },
    {
        "parent": "information_technology_and_telecommunications",
        "title": "IT Consultancy",
        "key": "information_technology_and_telecommunications_it_consultancy"
    },
    {
        "parent": "information_technology_and_telecommunications",
        "title": "IT Security",
        "key": "information_technology_and_telecommunications_it_security"
    },
    {
        "parent": "information_technology_and_telecommunications",
        "title": "Mobile Solutions",
        "key": "information_technology_and_telecommunications_mobile_solutions"
    },
    {
        "parent": "information_technology_and_telecommunications",
        "title": "Software development",
        "key": "information_technology_and_telecommunications_software_development"
    },
    {
        "parent": "information_technology_and_telecommunications",
        "title": "Support & Maintenance Services",
        "key": "information_technology_and_telecommunications_support_and_maintenance_services"
    },
    {
        "parent": "information_technology_and_telecommunications",
        "title": "Sustainability Analytics Services",
        "key": "information_technology_and_telecommunications_sustainability_analytics_services"
    },
    {
        "parent": "information_technology_and_telecommunications",
        "title": "Telecommunications",
        "key": "information_technology_and_telecommunications_telecommunications"
    },
    {
        "parent": "information_technology_and_telecommunications",
        "title": "Web Design",
        "key": "information_technology_and_telecommunications_web_design"
    },
    {
        "parent": "information_technology_and_telecommunications",
        "title": "Web Services",
        "key": "information_technology_and_telecommunications_web_services"
    },
    {
        "parent": "insurance",
        "title": "Other",
        "key": "insurance_other"
    },
    {
        "parent": "leisure_travel_and_tourism",
        "title": "Accommodation Services",
        "key": "leisure_travel_and_tourism_accommodation_services"
    },
    {
        "parent": "leisure_travel_and_tourism",
        "title": "Entertainment",
        "key": "leisure_travel_and_tourism_entertainment"
    },
    {
        "parent": "leisure_travel_and_tourism",
        "title": "Hotels",
        "key": "leisure_travel_and_tourism_hotels"
    },
    {
        "parent": "leisure_travel_and_tourism",
        "title": "Recreational Facilities & Services",
        "key": "leisure_travel_and_tourism_recreational_facilities_and_services"
    },
    {
        "parent": "leisure_travel_and_tourism",
        "title": "Travel Services",
        "key": "leisure_travel_and_tourism_travel_services"
    },
    {
        "parent": "leisure_travel_and_tourism",
        "title": "Concierge Services",
        "key": "leisure_travel_and_tourism_concierge_services"
    },
    {
        "parent": "manufacturing_industrial_and_engineering",
        "title": "Alloy Engineering",
        "key": "manufacturing_industrial_and_engineering_alloy_engineering"
    },
    {
        "parent": "manufacturing_industrial_and_engineering",
        "title": "Assembly",
        "key": "manufacturing_industrial_and_engineering_assembly"
    },
    {
        "parent": "manufacturing_industrial_and_engineering",
        "title": "Automotive Parts Manufacturing",
        "key": "manufacturing_industrial_and_engineering_automotive_parts_manufacturing"
    },
    {
        "parent": "manufacturing_industrial_and_engineering",
        "title": "Boiler Maintainence",
        "key": "manufacturing_industrial_and_engineering_boiler_maintainence"
    },
    {
        "parent": "manufacturing_industrial_and_engineering",
        "title": "Calibration Services",
        "key": "manufacturing_industrial_and_engineering_calibration_services"
    },
    {
        "parent": "manufacturing_industrial_and_engineering",
        "title": "Civil Engineering",
        "key": "manufacturing_industrial_and_engineering_civil_engineering"
    },
    {
        "parent": "manufacturing_industrial_and_engineering",
        "title": "Electrical Installation",
        "key": "manufacturing_industrial_and_engineering_electrical_installation"
    },
    {
        "parent": "manufacturing_industrial_and_engineering",
        "title": "Energy Efficient Electrical Systems",
        "key": "manufacturing_industrial_and_engineering_energy_efficient_electrical_systems"
    },
    {
        "parent": "manufacturing_industrial_and_engineering",
        "title": "Engineering Services",
        "key": "manufacturing_industrial_and_engineering_engineering_services"
    },
    {
        "parent": "manufacturing_industrial_and_engineering",
        "title": "General Maintenance",
        "key": "manufacturing_industrial_and_engineering_general_maintenance"
    },
    {
        "parent": "manufacturing_industrial_and_engineering",
        "title": "Heavy Earth Moving",
        "key": "manufacturing_industrial_and_engineering_heavy_earth_moving"
    },
    {
        "parent": "manufacturing_industrial_and_engineering",
        "title": "Industrial Automation",
        "key": "manufacturing_industrial_and_engineering_industrial_automation"
    },
    {
        "parent": "manufacturing_industrial_and_engineering",
        "title": "Industrial Plant & Equipment Installation",
        "key": "manufacturing_industrial_and_engineering_industrial_plant_and_equipment_installation"
    },
    {
        "parent": "manufacturing_industrial_and_engineering",
        "title": "Industrial Services",
        "key": "manufacturing_industrial_and_engineering_industrial_services"
    },
    {
        "parent": "manufacturing_industrial_and_engineering",
        "title": "Mechanical Engineering",
        "key": "manufacturing_industrial_and_engineering_mechanical_engineering"
    },
    {
        "parent": "manufacturing_industrial_and_engineering",
        "title": "Metal Fabrication",
        "key": "manufacturing_industrial_and_engineering_metal_fabrication"
    },
    {
        "parent": "manufacturing_industrial_and_engineering",
        "title": "Plant & Equipment Maintenance",
        "key": "manufacturing_industrial_and_engineering_plant_and_equipment_maintenance"
    },
    {
        "parent": "manufacturing_industrial_and_engineering",
        "title": "Plastic Moulding",
        "key": "manufacturing_industrial_and_engineering_plastic_moulding"
    },
    {
        "parent": "manufacturing_industrial_and_engineering",
        "title": "Precision Engineering",
        "key": "manufacturing_industrial_and_engineering_precision_engineering"
    },
    {
        "parent": "manufacturing_industrial_and_engineering",
        "title": "Steel & Aluminium Casting",
        "key": "manufacturing_industrial_and_engineering_steel_and_aluminium_casting"
    },
    {
        "parent": "manufacturing_industrial_and_engineering",
        "title": "Structural Engineering",
        "key": "manufacturing_industrial_and_engineering_structural_engineering"
    },
    {
        "parent": "office_suppliers_and_promotional_goods",
        "title": "Clothing, Footwear & Corporate wear",
        "key": "office_suppliers_and_promotional_goods_clothing_footwear_and_corporate_wear"
    },
    {
        "parent": "office_suppliers_and_promotional_goods",
        "title": "Corporate Gifts",
        "key": "office_suppliers_and_promotional_goods_corporate_gifts"
    },
    {
        "parent": "office_suppliers_and_promotional_goods",
        "title": "Electrical goods",
        "key": "office_suppliers_and_promotional_goods_electrical_goods"
    },
    {
        "parent": "office_suppliers_and_promotional_goods",
        "title": "Janitorial Supplies",
        "key": "office_suppliers_and_promotional_goods_janitorial_supplies"
    },
    {
        "parent": "office_suppliers_and_promotional_goods",
        "title": "Office Stationary",
        "key": "office_suppliers_and_promotional_goods_office_stationary"
    },
    {
        "parent": "office_suppliers_and_promotional_goods",
        "title": "Offices Supplies",
        "key": "office_suppliers_and_promotional_goods_offices_supplies"
    },
    {
        "parent": "office_suppliers_and_promotional_goods",
        "title": "Promotional goods & Bespoke Gift Items",
        "key": "office_suppliers_and_promotional_goods_promotional_goods_and_bespoke_gift_items"
    },
    {
        "parent": "office_suppliers_and_promotional_goods",
        "title": "Sustainable Goods",
        "key": "office_suppliers_and_promotional_goods_sustainable_goods"
    },
    {
        "parent": "packaging",
        "title": "Contract packaging",
        "key": "packaging_contract_packaging"
    },
    {
        "parent": "packaging",
        "title": "Corrugated Packaging",
        "key": "packaging_corrugated_packaging"
    },
    {
        "parent": "packaging",
        "title": "Environment Friendly Promotional Goods",
        "key": "packaging_environment_friendly_promotional_goods"
    },
    {
        "parent": "packaging",
        "title": "Flexible Packaging",
        "key": "packaging_flexible_packaging"
    },
    {
        "parent": "packaging",
        "title": "Labels",
        "key": "packaging_labels"
    },
    {
        "parent": "packaging",
        "title": "Packaging Solutions",
        "key": "packaging_packaging_solutions"
    },
    {
        "parent": "raw_materials_and_chemicals",
        "title": "Chemicals",
        "key": "raw_materials_and_chemicals_chemicals"
    },
    {
        "parent": "raw_materials_and_chemicals",
        "title": "Mining & Metals",
        "key": "raw_materials_and_chemicals_mining_and_metals"
    },
    {
        "parent": "raw_materials_and_chemicals",
        "title": "Paper & Forest products",
        "key": "raw_materials_and_chemicals_paper_and_forest_products"
    },
    {
        "parent": "raw_materials_and_chemicals",
        "title": "Plastics",
        "key": "raw_materials_and_chemicals_plastics"
    },
    {
        "parent": "raw_materials_and_chemicals",
        "title": "Semi-Conductors",
        "key": "raw_materials_and_chemicals_semi_conductors"
    },
    {
        "parent": "real-estate",
        "title": "Commercial",
        "key": "real-estate_commercial"
    },
    {
        "parent": "real-estate",
        "title": "Investment",
        "key": "real-estate_investment"
    },
    {
        "parent": "real-estate",
        "title": "Property Management",
        "key": "real-estate_property_management"
    },
    {
        "parent": "real-estate",
        "title": "Resident",
        "key": "real-estate_resident"
    },
    {
        "parent": "recruitment_and_staffing",
        "title": "Admin / Secretarial Staff",
        "key": "recruitment_and_staffing_admin_or_secretarial_staff"
    },
    {
        "parent": "recruitment_and_staffing",
        "title": "Creative Freelancer Recruitment",
        "key": "recruitment_and_staffing_creative_freelancer_recruitment"
    },
    {
        "parent": "recruitment_and_staffing",
        "title": "End-to-end Workforce Solutions",
        "key": "recruitment_and_staffing_end_to_end_workforce_solutions"
    },
    {
        "parent": "recruitment_and_staffing",
        "title": "Engineers & Production Workers",
        "key": "recruitment_and_staffing_engineers_and_production_workers"
    },
    {
        "parent": "recruitment_and_staffing",
        "title": "HGV / LGV Drivers",
        "key": "recruitment_and_staffing_hgv_or_lgv_drivers"
    },
    {
        "parent": "recruitment_and_staffing",
        "title": "Highly Skilled Workforce",
        "key": "recruitment_and_staffing_highly_skilled_workforce"
    },
    {
        "parent": "recruitment_and_staffing",
        "title": "Hotel & Hospitality Staff",
        "key": "recruitment_and_staffing_hotel_and_hospitality_staff"
    },
    {
        "parent": "recruitment_and_staffing",
        "title": "Industrial Workers",
        "key": "recruitment_and_staffing_industrial_workers"
    },
    {
        "parent": "recruitment_and_staffing",
        "title": "International Recruitment",
        "key": "recruitment_and_staffing_international_recruitment"
    },
    {
        "parent": "recruitment_and_staffing",
        "title": "IT Staffing",
        "key": "recruitment_and_staffing_it_staffing"
    },
    {
        "parent": "recruitment_and_staffing",
        "title": "Legal Recruitment",
        "key": "recruitment_and_staffing_legal_recruitment"
    },
    {
        "parent": "recruitment_and_staffing",
        "title": "Medical Staff",
        "key": "recruitment_and_staffing_medical_staff"
    },
    {
        "parent": "recruitment_and_staffing",
        "title": "Pharmaceutical Recruitment",
        "key": "recruitment_and_staffing_pharmaceutical_recruitment"
    },
    {
        "parent": "recruitment_and_staffing",
        "title": "Recruitment Services",
        "key": "recruitment_and_staffing_recruitment_services"
    },
    {
        "parent": "recruitment_and_staffing",
        "title": "Senior Executive Search",
        "key": "recruitment_and_staffing_senior_executive_search"
    },
    {
        "parent": "recruitment_and_staffing",
        "title": "Social Care",
        "key": "recruitment_and_staffing_social_care"
    },
    {
        "parent": "recruitment_and_staffing",
        "title": "Temporary Staff",
        "key": "recruitment_and_staffing_temporary_staff"
    },
    {
        "parent": "sustainability_and_environment",
        "title": "CSR Services",
        "key": "sustainability_and_environment_csr_services"
    },
    {
        "parent": "sustainability_and_environment",
        "title": "Environment Services",
        "key": "sustainability_and_environment_environment_services"
    },
    {
        parent: 'advertising_pr_marketing_and_print',
        title: 'Advertising',
        key: 'advertising',
      },
      {
        parent: 'advertising_pr_marketing_and_print',
        title: 'Animation',
        key: 'animation',
      },
      {
        parent: 'advertising_pr_marketing_and_print',
        title: 'Brand Management',
        key: 'brand_management',
      },
      {
        parent: 'advertising_pr_marketing_and_print',
        title: 'Creative Design Agency',
        key: 'creative_design_agency',
      },
      {
        parent: 'advertising_pr_marketing_and_print',
        title: 'Digital Marketing & Print',
        key: 'digital_marketing_and_print',
      },
      {
        parent: 'advertising_pr_marketing_and_print',
        title: 'End-to-end marketing agency',
        key: 'end_to_end_marketing_agency',
      },
      {
        parent: 'advertising_pr_marketing_and_print',
        title: 'Enterprise 2.0',
        key: 'enterprise_2_dot_0',
      },
      {
        parent: 'advertising_pr_marketing_and_print',
        title: 'Ethnic Media Marketing',
        key: 'ethnic_media_marketing',
      },
      {
        parent: 'advertising_pr_marketing_and_print',
        title: 'Film Making',
        key: 'film_making',
      },
      {
        parent: 'advertising_pr_marketing_and_print',
        title: 'Graphic Design',
        key: 'graphic_design',
      },
      {
        parent: 'advertising_pr_marketing_and_print',
        title: 'Market Research',
        key: 'market_research',
      },
      {
        parent: 'advertising_pr_marketing_and_print',
        title: 'Marketing Technology & Automation',
        key: 'marketing_technology_and_automation',
      },
      {
        parent: 'advertising_pr_marketing_and_print',
        title: 'Media Agency',
        key: 'media_agency',
      },
      {
        parent: 'advertising_pr_marketing_and_print',
        title: 'Media Production',
        key: 'media_production',
      },
      {
        parent: 'advertising_pr_marketing_and_print',
        title: 'Online Media',
        key: 'online_media',
      },
      {
        parent: 'advertising_pr_marketing_and_print',
        title: 'Photography',
        key: 'photography',
      },
      {
        parent: 'advertising_pr_marketing_and_print',
        title: 'PR & Communications',
        key: 'pr_and_communications',
      },
      {
        parent: 'advertising_pr_marketing_and_print',
        title: 'Print Fulfilment',
        key: 'print_fulfilment',
      },
      {
        parent: 'advertising_pr_marketing_and_print',
        title: 'Print Management',
        key: 'print_management',
      },
      {
        parent: 'advertising_pr_marketing_and_print',
        title: 'Print Visual Media',
        key: 'print_visual_media',
      },
      {
        parent: 'advertising_pr_marketing_and_print',
        title: 'Product Launch',
        key: 'product_launch',
      },
      {
        parent: 'advertising_pr_marketing_and_print',
        title: 'Publishing',
        key: 'publishing',
      },
      {
        parent: 'advertising_pr_marketing_and_print',
        title: 'Social Media Agency',
        key: 'social_media_agency',
      },
      {
        parent: 'advertising_pr_marketing_and_print',
        title: 'Telemarketing & Sales',
        key: 'telemarketing_and_sales',
      },
      {
        parent: 'advertising_pr_marketing_and_print',
        title: 'Video Production',
        key: 'video_production',
      },
      {
        parent: 'advertising_pr_marketing_and_print',
        title: 'Web 2.0',
        key: 'web_2_dot_0',
      },
      {
        parent: 'advertising_pr_marketing_and_print',
        title: 'Web Marketing & Communication',
        key: 'web_marketing_and_communication',
      },
      {
        parent: 'advertising_pr_marketing_and_print',
        title: 'Website Design',
        key: 'website_design',
      },
      {
        parent: 'advertising_pr_marketing_and_print',
        title: 'Writing & Editing',
        key: 'writing_and_editing',
      },
      {
        parent: 'agriculture_and_farming',
        title: 'Agritech',
        key: 'agritech',
      },
      {
        parent: 'agriculture_and_farming',
        title: 'Coffee Production',
        key: 'coffee_production',
      },
      {
        parent: 'agriculture_and_farming',
        title: 'Dairy',
        key: 'dairy',
      },
      {
        parent: 'agriculture_and_farming',
        title: 'Fishery',
        key: 'fishery',
      },
      {
        parent: 'agriculture_and_farming',
        title: 'Food Production',
        key: 'food_production',
      },
      {
        parent: 'agriculture_and_farming',
        title: 'Ranching',
        key: 'ranching',
      },
      {
        parent: 'apparel_and_clothing',
        title: 'Apparel/Clothing Manufacturer',
        key: 'apparel_or_clothing_manufacturer',
      },
      {
        parent: 'apparel_and_clothing',
        title: 'Fashion Accessories',
        key: 'fashion_accessories',
      },
      {
        parent: 'apparel_and_clothing',
        title: 'Merchandising',
        key: 'merchandising',
      },
      {
        parent: 'apparel_and_clothing',
        title: 'Personal Protective Equipment',
        key: 'personal_protective_equipment',
      },
      {
        parent: 'apparel_and_clothing',
        title: 'Sportswear',
        key: 'sportswear',
      },
      {
        parent: 'apparel_and_clothing',
        title: 'Textiles',
        key: 'textiles',
      },
      {
        parent: 'apparel_and_clothing',
        title: 'Workwear / Corporate ware',
        key: 'workwear_or_corporate_ware',
      },
      {
        parent: 'architecture_design_and_construction',
        title: 'Architectural Design',
        key: 'architectural_design',
      },
      {
        parent: 'architecture_design_and_construction',
        title: 'Building Material',
        key: 'building_material',
      },
      {
        parent: 'architecture_design_and_construction',
        title: 'Civil & Structural Design Consultants',
        key: 'civil_and_structural_design_consultants',
      },
      {
        parent: 'architecture_design_and_construction',
        title: 'Construction',
        key: 'construction',
      },
      {
        parent: 'architecture_design_and_construction',
        title: 'Design Services',
        key: 'design_services',
      },
      {
        parent: 'architecture_design_and_construction',
        title: 'Dismantling and remediation services',
        key: 'dismantling_and_remediation_services',
      },
      {
        parent: 'architecture_design_and_construction',
        title: 'Electrical Engineering',
        key: 'electrical_engineering',
      },
      {
        parent: 'architecture_design_and_construction',
        title: 'End-to-end general construction contractor',
        key: 'end_to_end_general_construction_contractor',
      },
      {
        parent: 'architecture_design_and_construction',
        title: 'Engineering Services',
        key: 'engineering_services',
      },
      {
        parent: 'architecture_design_and_construction',
        title: 'Facade Specialists',
        key: 'facade_specialists',
      },
      {
        parent: 'architecture_design_and_construction',
        title: 'Flooring Specialists',
        key: 'flooring_specialists',
      },
      {
        parent: 'architecture_design_and_construction',
        title: 'Furniture',
        key: 'furniture',
      },
      {
        parent: 'architecture_design_and_construction',
        title: 'Glass, Ceramics & Concreating',
        key: 'glass_ceramics_and_concreating',
      },
      {
        parent: 'architecture_design_and_construction',
        title: 'Groundworks',
        key: 'groundworks',
      },
      {
        parent: 'architecture_design_and_construction',
        title: 'Insulation & Renovation Specialists',
        key: 'insulation_and_renovation_specialists',
      },
      {
        parent: 'architecture_design_and_construction',
        title: 'Joinery',
        key: 'joinery',
      },
      {
        parent: 'architecture_design_and_construction',
        title: 'Mechanical Engineering',
        key: 'mechanical_engineering',
      },
      {
        parent: 'architecture_design_and_construction',
        title: 'Office Design',
        key: 'office_design',
      },
      {
        parent: 'architecture_design_and_construction',
        title: 'Pipes & Drains',
        key: 'pipes_and_drains',
      },
      {
        parent: 'architecture_design_and_construction',
        title: 'Plant & Machine Hire',
        key: 'plant_and_machine_hire',
      },
      {
        parent: 'architecture_design_and_construction',
        title: 'Plastering',
        key: 'plastering',
      },
      {
        parent: 'architecture_design_and_construction',
        title: 'Product Suppliers',
        key: 'product_suppliers',
      },
      {
        parent: 'architecture_design_and_construction',
        title: 'Project Management',
        key: 'project_management',
      },
      {
        parent: 'architecture_design_and_construction',
        title: 'Protect Valuation',
        key: 'protect_valuation',
      },
      {
        parent: 'architecture_design_and_construction',
        title: 'Refrigeration Services',
        key: 'refrigeration_services',
      },
      {
        parent: 'architecture_design_and_construction',
        title: 'Roofing, cladding and waterproofing',
        key: 'roofing_cladding_and_waterproofing',
      },
      {
        parent: 'architecture_design_and_construction',
        title: 'Scaffolding',
        key: 'scaffolding',
      },
      {
        parent: 'architecture_design_and_construction',
        title: 'Sustainable Construction',
        key: 'sustainable_construction',
      },
      {
        parent: 'architecture_design_and_construction',
        title: 'Steel Fixing',
        key: 'steel_fixing',
      },
      {
        parent: 'architecture_design_and_construction',
        title: 'Stone Supply',
        key: 'stone_supply',
      },
      {
        parent: 'architecture_design_and_construction',
        title: 'Structural Engineering',
        key: 'structural_engineering',
      },
      {
        parent: 'architecture_design_and_construction',
        title: 'Surveying',
        key: 'surveying',
      },
      {
        parent: 'architecture_design_and_construction',
        title: 'Tile & Mosaic services',
        key: 'tile_and_mosaic_services',
      },
      {
        parent: 'architecture_design_and_construction',
        title: 'Water protection systems',
        key: 'water_protection_systems',
      },
      {
        parent: 'business_services_or_consultancy',
        title: 'Bid Writing',
        key: 'bid_writing',
      },
      {
        parent: 'business_services_or_consultancy',
        title: 'Bid management & Tender Consultancy',
        key: 'bid_management_and_tender_consultancy',
      },
      {
        parent: 'business_services_or_consultancy',
        title: 'Consulting',
        key: 'consulting',
      },
      {
        parent: 'business_services_or_consultancy',
        title: 'Data Services',
        key: 'data_services',
      },
      {
        parent: 'business_services_or_consultancy',
        title: 'Diversity, Equity and Inclusion',
        key: 'diversity_equity_and_inclusion',
      },
      {
        parent: 'business_services_or_consultancy',
        title: 'Document Storage & Archiving',
        key: 'document_storage_and_archiving',
      },
      {
        parent: 'business_services_or_consultancy',
        title: 'Employee Benefits',
        key: 'employee_benefits',
      },
      {
        parent: 'business_services_or_consultancy',
        title: 'HR & Payroll',
        key: 'hr_and_payroll',
      },
      {
        parent: 'business_services_or_consultancy',
        title: 'Insurance',
        key: 'insurance',
      },
      {
        parent: 'business_services_or_consultancy',
        title: 'Intellectual Property Services',
        key: 'intellectual_property_services',
      },
      {
        parent: 'business_services_or_consultancy',
        title: 'Legal Services',
        key: 'legal_services',
      },
      {
        parent: 'business_services_or_consultancy',
        title: 'Mentoring',
        key: 'mentoring',
      },
      {
        parent: 'business_services_or_consultancy',
        title: 'Media',
        key: 'media',
      },
      {
        parent: 'business_services_or_consultancy',
        title: 'Office / Room Hire',
        key: 'office_or_room_hire',
      },
      {
        parent: 'business_services_or_consultancy',
        title: 'Outsourcing',
        key: 'outsourcing',
      },
      {
        parent: 'business_services_or_consultancy',
        title: 'Relocation Services',
        key: 'relocation_services',
      },
      {
        parent: 'business_services_or_consultancy',
        title: 'Risk Management Consultancy',
        key: 'risk_management_consultancy',
      },
      {
        parent: 'business_services_or_consultancy',
        title: 'Scanning',
        key: 'scanning',
      },
      {
        parent: 'business_services_or_consultancy',
        title: 'Supply Chain Management',
        key: 'supply_chain_management',
      },
      {
        parent: 'business_services_or_consultancy',
        title: 'Trade Mark Lawyers',
        key: 'trade_mark_lawyers',
      },
      {
        parent: 'business_services_or_consultancy',
        title: 'Translation Services',
        key: 'translation_services',
      },
      {
        parent: 'business_services_or_consultancy',
        title: 'Workspaces Services',
        key: 'workspaces_services',
      },
      {
        parent: 'consumer_goods',
        title: 'Electronics',
        key: 'electronics',
      },
      {
        parent: 'consumer_goods',
        title: 'Fashion / Apparel',
        key: 'fashion_or_apparel',
      },
      {
        parent: 'consumer_goods',
        title: 'General Goods',
        key: 'general_goods',
      },
      {
        parent: 'consumer_goods',
        title: 'Health & Beauty',
        key: 'health_and_beauty',
      },
      {
        parent: 'consumer_goods',
        title: 'Jewellery',
        key: 'jewellery',
      },
      {
        parent: 'consumer_goods',
        title: 'Retail',
        key: 'retail',
      },
      {
        parent: 'consumer_goods',
        title: 'Sustainable Products',
        key: 'sustainable_products',
      },
      {
        parent: 'consumer_goods',
        title: 'Textiles',
        key: 'textiles',
      },
      {
        parent: 'consumer_goods',
        title: 'Inclusive Toys / Games',
        key: 'inclusive_toys_or_games',
      },
      {
        parent: 'consumer_goods',
        title: 'Wholesale of ethnic and exotic products',
        key: 'wholesale_of_ethnic_and_exotic_products',
      },
      {
        parent: 'consumer_goods',
        title: 'Wholesale',
        key: 'wholesale',
      },
      {
        parent: 'education_and_training',
        title: 'Change Management',
        key: 'change_management',
      },
      {
        parent: 'education_and_training',
        title: 'Clerical Training',
        key: 'clerical_training',
      },
      {
        parent: 'education_and_training',
        title: 'Coaching / Professional Development',
        key: 'coaching_or_professional_development',
      },
      {
        parent: 'education_and_training',
        title: 'Consultancy',
        key: 'consultancy',
      },
      {
        parent: 'education_and_training',
        title: 'Diversity Training',
        key: 'diversity_training',
      },
      {
        parent: 'education_and_training',
        title: 'Financial Education',
        key: 'financial_education',
      },
      {
        parent: 'education_and_training',
        title: 'Higher Education',
        key: 'higher_education',
      },
      {
        parent: 'education_and_training',
        title: 'IT Training',
        key: 'it_training',
      },
      {
        parent: 'education_and_training',
        title: 'Language Training Services',
        key: 'language_training_services',
      },
      {
        parent: 'education_and_training',
        title: 'Leadership Development Training',
        key: 'leadership_development_training',
      },
      {
        parent: 'education_and_training',
        title: 'Management Training',
        key: 'management_training',
      },
      {
        parent: 'education_and_training',
        title: 'Primary / Secondary Education',
        key: 'primary_or_secondary_education',
      },
      {
        parent: 'education_and_training',
        title: 'Sales Training',
        key: 'sales_training',
      },
      {
        parent: 'education_and_training',
        title: 'Supply Chain Training',
        key: 'supply_chain_training',
      },
      {
        parent: 'education_and_training',
        title: 'Team Building Training',
        key: 'team_building_training',
      },
      {
        parent: 'education_and_training',
        title: 'Workforce Mediation',
        key: 'workforce_mediation',
      },
      {
        parent: 'event_management',
        title: 'Audio – Visual',
        key: 'audio_visual',
      },
      {
        parent: 'event_management',
        title: 'Conference Management',
        key: 'conference_management',
      },
      {
        parent: 'event_management',
        title: 'Corporate Event Hospitality',
        key: 'corporate_event_hospitality',
      },
      {
        parent: 'event_management',
        title: 'Entertainment',
        key: 'entertainment',
      },
      {
        parent: 'event_management',
        title: 'Event Catering',
        key: 'event_catering',
      },
      {
        parent: 'event_management',
        title: 'Event Supplies',
        key: 'event_supplies',
      },
      {
        parent: 'event_management',
        title: 'Exhibition & Trade Shows',
        key: 'exhibition_and_trade_shows',
      },
      {
        parent: 'event_management',
        title: 'Full Event Management Services',
        key: 'full_event_management_services',
      },
      {
        parent: 'event_management',
        title: 'Product Launches',
        key: 'product_launches',
      },
      {
        parent: 'event_management',
        title: 'Road Shows',
        key: 'road_shows',
      },
      {
        parent: 'event_management',
        title: 'Speciality Corporate events',
        key: 'speciality_corporate_events',
      },
      {
        parent: 'event_management',
        title: 'Team Building Activities',
        key: 'team_building_activities',
      },
      {
        parent: 'event_management',
        title: 'Venue Hire',
        key: 'venue_hire',
      },
      {
        parent: 'facilities_management',
        title: 'Building Maintenance',
        key: 'building_maintenance',
      },
      {
        parent: 'facilities_management',
        title: 'Cash Security',
        key: 'cash_security',
      },
      {
        parent: 'facilities_management',
        title: 'Commercial catering equipment',
        key: 'commercial_catering_equipment',
      },
      {
        parent: 'facilities_management',
        title: 'Commercial Lighting',
        key: 'commercial_lighting',
      },
      {
        parent: 'facilities_management',
        title: 'Concierge Service',
        key: 'concierge_service',
      },
      {
        parent: 'facilities_management',
        title: 'Dry Cleaning Services',
        key: 'dry_cleaning_services',
      },
      {
        parent: 'facilities_management',
        title: 'Ventilation Management Services',
        key: 'ventilation_management_services',
      },
      {
        parent: 'facilities_management',
        title: 'Construction Cleaning',
        key: 'construction_cleaning',
      },
      {
        parent: 'facilities_management',
        title: 'Electrical Services',
        key: 'electrical_services',
      },
      {
        parent: 'facilities_management',
        title: 'Environmental Services',
        key: 'environmental_services',
      },
      {
        parent: 'facilities_management',
        title: 'Flooring',
        key: 'flooring',
      },
      {
        parent: 'facilities_management',
        title: 'Glazing',
        key: 'glazing',
      },
      {
        parent: 'facilities_management',
        title: 'Grounds & Parks Maintenance',
        key: 'grounds_and_parks_maintenance',
      },
      {
        parent: 'facilities_management',
        title: 'Health & Safety',
        key: 'health_and_safety',
      },
      {
        parent: 'facilities_management',
        title: 'HVAC (Heating, Ventilation, and Air-conditioning)',
        key: 'hvac_heating_ventilation_and_air_conditioning',
      },
      {
        parent: 'facilities_management',
        title: 'Industrial Cleaning',
        key: 'industrial_cleaning',
      },
      {
        parent: 'facilities_management',
        title: 'Industrial Lighting',
        key: 'industrial_lighting',
      },
      {
        parent: 'facilities_management',
        title: 'Janitorial Services',
        key: 'janitorial_services',
      },
      {
        parent: 'facilities_management',
        title: 'Landscaping',
        key: 'landscaping',
      },
      {
        parent: 'facilities_management',
        title: 'LED Lighting',
        key: 'led_lighting',
      },
      {
        parent: 'facilities_management',
        title: 'Manned Security',
        key: 'manned_security',
      },
      {
        parent: 'facilities_management',
        title: 'Office Cleaning',
        key: 'office_cleaning',
      },
      {
        parent: 'facilities_management',
        title: 'Painting & Decorating',
        key: 'painting_and_decorating',
      },
      {
        parent: 'facilities_management',
        title: 'Pest Control',
        key: 'pest_control',
      },
      {
        parent: 'facilities_management',
        title: 'Plumbing & Central heating',
        key: 'plumbing_and_central_heating',
      },
      {
        parent: 'facilities_management',
        title: 'Refrigeration',
        key: 'refrigeration',
      },
      {
        parent: 'facilities_management',
        title: 'Refurbishment Removals',
        key: 'refurbishment_removals',
      },
      {
        parent: 'facilities_management',
        title: 'Security Systems',
        key: 'security_systems',
      },
      {
        parent: 'facilities_management',
        title: 'Signage',
        key: 'signage',
      },
      {
        parent: 'facilities_management',
        title: 'Total FM Solutions',
        key: 'total_fm_solutions',
      },
      {
        parent: 'facilities_management',
        title: 'Traffic Management',
        key: 'traffic_management',
      },
      {
        parent: 'facilities_management',
        title: 'Waste Management',
        key: 'waste_management',
      },
      {
        parent: 'facilities_management',
        title: 'Window Cleaning',
        key: 'window_cleaning',
      },
      {
        parent: 'financial_services',
        title: 'Accounting',
        key: 'accounting',
      },
      {
        parent: 'financial_services',
        title: 'Auditing',
        key: 'auditing',
      },
      {
        parent: 'financial_services',
        title: 'Banking',
        key: 'banking',
      },
      {
        parent: 'financial_services',
        title: 'Fundraising',
        key: 'fundraising',
      },
      {
        parent: 'financial_services',
        title: 'Investment Banking',
        key: 'investment_banking',
      },
      {
        parent: 'financial_services',
        title: 'Investment Management',
        key: 'investment_management',
      },
      {
        parent: 'financial_services',
        title: 'Venture Capital & Private Equity',
        key: 'venture_capital_and_private_equity',
      },
      {
        parent: 'healthcare_pharmaceutical_and_medical',
        title: 'Alternative Medicine',
        key: 'alternative_medicine',
      },
      {
        parent: 'healthcare_pharmaceutical_and_medical',
        title: 'Biotechnology',
        key: 'biotechnology',
      },
      {
        parent: 'healthcare_pharmaceutical_and_medical',
        title: 'Clinical Audit',
        key: 'clinical_audit',
      },
      {
        parent: 'healthcare_pharmaceutical_and_medical',
        title: 'Contract Clinical Research',
        key: 'contract_clinical_research',
      },
      {
        parent: 'healthcare_pharmaceutical_and_medical',
        title: 'Hospital & Healthcare',
        key: 'hospital_and_healthcare',
      },
      {
        parent: 'healthcare_pharmaceutical_and_medical',
        title: 'Lab Testing',
        key: 'lab_testing',
      },
      {
        parent: 'healthcare_pharmaceutical_and_medical',
        title: 'Medical Devices',
        key: 'medical_devices',
      },
      {
        parent: 'healthcare_pharmaceutical_and_medical',
        title: 'Medical Practice',
        key: 'medical_practice',
      },
      {
        parent: 'healthcare_pharmaceutical_and_medical',
        title: 'Mental Healthcare',
        key: 'mental_healthcare',
      },
      {
        parent: 'healthcare_pharmaceutical_and_medical',
        title: 'Pharmaceuticals',
        key: 'pharmaceuticals',
      },
      {
        parent: 'healthcare_pharmaceutical_and_medical',
        title: 'Product Trials',
        key: 'product_trials',
      },
      {
        parent: 'healthcare_pharmaceutical_and_medical',
        title: 'Public Health',
        key: 'public_health',
      },
      {
        parent: 'healthcare_pharmaceutical_and_medical',
        title: 'Social care',
        key: 'social_care',
      },
      {
        parent: 'healthcare_pharmaceutical_and_medical',
        title: 'Surgical & Clinical Goods Supplies',
        key: 'surgical_and_clinical_goods_supplies',
      },
      {
        parent: 'healthcare_pharmaceutical_and_medical',
        title: 'Wellness & Fitness',
        key: 'wellness_and_fitness',
      },
      {
        parent: 'hospitality_and_catering',
        title: 'Kitchen Equipment',
        key: 'kitchen_equipment',
      },
      {
        parent: 'hospitality_and_catering',
        title: 'Cafeteria Management',
        key: 'cafeteria_management',
      },
      {
        parent: 'hospitality_and_catering',
        title: 'Catering',
        key: 'catering',
      },
      {
        parent: 'hospitality_and_catering',
        title: 'Dairy Products',
        key: 'dairy_products',
      },
      {
        parent: 'hospitality_and_catering',
        title: 'Ethnic Food Catering',
        key: 'ethnic_food_catering',
      },
      {
        parent: 'hospitality_and_catering',
        title: 'Ethnic Food / Ingredients Wholesaler',
        key: 'ethnic_food_or_ingredients_wholesaler',
      },
      {
        parent: 'hospitality_and_catering',
        title: 'Food & Beverage Supplies',
        key: 'food_and_beverage_supplies',
      },
      {
        parent: 'hospitality_and_catering',
        title: 'Food & Ingredients Wholesaler',
        key: 'food_and_ingredients_wholesaler',
      },
      {
        parent: 'hospitality_and_catering',
        title: 'Food Wholeseller',
        key: 'food_wholeseller',
      },
      {
        parent: 'hospitality_and_catering',
        title: 'Fresh Produce',
        key: 'fresh_produce',
      },
      {
        parent: 'hospitality_and_catering',
        title: 'Frozen/Meat Products',
        key: 'frozenormeat_products',
      },
      {
        parent: 'hospitality_and_catering',
        title: 'Product Supplies',
        key: 'product_supplies',
      },
      {
        parent: 'hospitality_and_catering',
        title: 'Vending Machines',
        key: 'vending_machines',
      },
      {
        parent: 'information_technology_and_telecommunications',
        title: 'AI and Robotics',
        key: 'ai_and_robotics',
      },
      {
        parent: 'information_technology_and_telecommunications',
        title: 'Application Development',
        key: 'application_development',
      },
      {
        parent: 'information_technology_and_telecommunications',
        title: 'Automation',
        key: 'automation',
      },
      {
        parent: 'information_technology_and_telecommunications',
        title: 'Blockchain',
        key: 'blockchain',
      },
      {
        parent: 'information_technology_and_telecommunications',
        title: 'Cloud Computing',
        key: 'cloud_computing',
      },
      {
        parent: 'information_technology_and_telecommunications',
        title: 'Computer Equipment Disposal',
        key: 'computer_equipment_disposal',
      },
      {
        parent: 'information_technology_and_telecommunications',
        title: 'Cyber Security',
        key: 'cyber_security',
      },
      {
        parent: 'information_technology_and_telecommunications',
        title: 'Fintech Solutions',
        key: 'fintech_solutions',
      },
      {
        parent: 'information_technology_and_telecommunications',
        title: 'E-Commerce Solutions',
        key: 'e_commerce_solutions',
      },
      {
        parent: 'information_technology_and_telecommunications',
        title: 'Gaming',
        key: 'gaming',
      },
      {
        parent: 'information_technology_and_telecommunications',
        title: 'Hardware, Peripherals & Networks',
        key: 'hardware_peripherals_and_networks',
      },
      {
        parent: 'information_technology_and_telecommunications',
        title: 'Hosted Solutions',
        key: 'hosted_solutions',
      },
      {
        parent: 'information_technology_and_telecommunications',
        title: 'Immersive Technology',
        key: 'immersive_technology',
      },
      {
        parent: 'information_technology_and_telecommunications',
        title: 'IOT',
        key: 'iot',
      },
      {
        parent: 'information_technology_and_telecommunications',
        title: 'IT Consultancy',
        key: 'it_consultancy',
      },
      {
        parent: 'information_technology_and_telecommunications',
        title: 'IT Security',
        key: 'it_security',
      },
      {
        parent: 'information_technology_and_telecommunications',
        title: 'Mobile Solutions',
        key: 'mobile_solutions',
      },
      {
        parent: 'information_technology_and_telecommunications',
        title: 'Software development',
        key: 'software_development',
      },
      {
        parent: 'information_technology_and_telecommunications',
        title: 'Support & Maintenance Services',
        key: 'support_and_maintenance_services',
      },
      {
        parent: 'information_technology_and_telecommunications',
        title: 'Sustainability Analytics Services',
        key: 'sustainability_analytics_services',
      },
      {
        parent: 'information_technology_and_telecommunications',
        title: 'Telecommunications',
        key: 'telecommunications',
      },
      {
        parent: 'information_technology_and_telecommunications',
        title: 'Web Design',
        key: 'web_design',
      },
      {
        parent: 'information_technology_and_telecommunications',
        title: 'Web Services',
        key: 'web_services',
      },
      {
        parent: 'insurance',
        title: 'Other',
        key: 'other',
      },
      {
        parent: 'leisure_travel_and_tourism',
        title: 'Accommodation Services',
        key: 'accommodation_services',
      },
      {
        parent: 'leisure_travel_and_tourism',
        title: 'Entertainment',
        key: 'entertainment',
      },
      {
        parent: 'leisure_travel_and_tourism',
        title: 'Hotels',
        key: 'hotels',
      },
      {
        parent: 'leisure_travel_and_tourism',
        title: 'Recreational Facilities & Services',
        key: 'recreational_facilities_and_services',
      },
      {
        parent: 'leisure_travel_and_tourism',
        title: 'Travel Services',
        key: 'travel_services',
      },
      {
        parent: 'leisure_travel_and_tourism',
        title: 'Concierge Services',
        key: 'concierge_services',
      },
      {
        parent: 'manufacturing_industrial_and_engineering',
        title: 'Alloy Engineering',
        key: 'alloy_engineering',
      },
      {
        parent: 'manufacturing_industrial_and_engineering',
        title: 'Assembly',
        key: 'assembly',
      },
      {
        parent: 'manufacturing_industrial_and_engineering',
        title: 'Automotive Parts Manufacturing',
        key: 'automotive_parts_manufacturing',
      },
      {
        parent: 'manufacturing_industrial_and_engineering',
        title: 'Boiler Maintainence',
        key: 'boiler_maintainence',
      },
      {
        parent: 'manufacturing_industrial_and_engineering',
        title: 'Calibration Services',
        key: 'calibration_services',
      },
      {
        parent: 'manufacturing_industrial_and_engineering',
        title: 'Civil Engineering',
        key: 'civil_engineering',
      },
      {
        parent: 'manufacturing_industrial_and_engineering',
        title: 'Electrical Installation',
        key: 'electrical_installation',
      },
      {
        parent: 'manufacturing_industrial_and_engineering',
        title: 'Energy Efficient Electrical Systems',
        key: 'energy_efficient_electrical_systems',
      },
      {
        parent: 'manufacturing_industrial_and_engineering',
        title: 'Engineering Services',
        key: 'engineering_services',
      },
      {
        parent: 'manufacturing_industrial_and_engineering',
        title: 'General Maintenance',
        key: 'general_maintenance',
      },
      {
        parent: 'manufacturing_industrial_and_engineering',
        title: 'Heavy Earth Moving',
        key: 'heavy_earth_moving',
      },
      {
        parent: 'manufacturing_industrial_and_engineering',
        title: 'Industrial Automation',
        key: 'industrial_automation',
      },
      {
        parent: 'manufacturing_industrial_and_engineering',
        title: 'Industrial Plant & Equipment Installation',
        key: 'industrial_plant_and_equipment_installation',
      },
      {
        parent: 'manufacturing_industrial_and_engineering',
        title: 'Industrial Services',
        key: 'industrial_services',
      },
      {
        parent: 'manufacturing_industrial_and_engineering',
        title: 'Mechanical Engineering',
        key: 'mechanical_engineering',
      },
      {
        parent: 'manufacturing_industrial_and_engineering',
        title: 'Metal Fabrication',
        key: 'metal_fabrication',
      },
      {
        parent: 'manufacturing_industrial_and_engineering',
        title: 'Plant & Equipment Maintenance',
        key: 'plant_and_equipment_maintenance',
      },
      {
        parent: 'manufacturing_industrial_and_engineering',
        title: 'Plastic Moulding',
        key: 'plastic_moulding',
      },
      {
        parent: 'manufacturing_industrial_and_engineering',
        title: 'Precision Engineering',
        key: 'precision_engineering',
      },
      {
        parent: 'manufacturing_industrial_and_engineering',
        title: 'Steel & Aluminium Casting',
        key: 'steel_and_aluminium_casting',
      },
      {
        parent: 'manufacturing_industrial_and_engineering',
        title: 'Structural Engineering',
        key: 'structural_engineering',
      },
      {
        parent: 'office_suppliers_and_promotional_goods',
        title: 'Clothing, Footwear & Corporate wear',
        key: 'clothing_footwear_and_corporate_wear',
      },
      {
        parent: 'office_suppliers_and_promotional_goods',
        title: 'Corporate Gifts',
        key: 'corporate_gifts',
      },
      {
        parent: 'office_suppliers_and_promotional_goods',
        title: 'Electrical goods',
        key: 'electrical_goods',
      },
      {
        parent: 'office_suppliers_and_promotional_goods',
        title: 'Janitorial Supplies',
        key: 'janitorial_supplies',
      },
      {
        parent: 'office_suppliers_and_promotional_goods',
        title: 'Office Stationary',
        key: 'office_stationary',
      },
      {
        parent: 'office_suppliers_and_promotional_goods',
        title: 'Offices Supplies',
        key: 'offices_supplies',
      },
      {
        parent: 'office_suppliers_and_promotional_goods',
        title: 'Promotional goods & Bespoke Gift Items',
        key: 'promotional_goods_and_bespoke_gift_items',
      },
      {
        parent: 'office_suppliers_and_promotional_goods',
        title: 'Sustainable Goods',
        key: 'sustainable_goods',
      },
      {
        parent: 'packaging',
        title: 'Contract packaging',
        key: 'contract_packaging',
      },
      {
        parent: 'packaging',
        title: 'Corrugated Packaging',
        key: 'corrugated_packaging',
      },
      {
        parent: 'packaging',
        title: 'Environment Friendly Promotional Goods',
        key: 'environment_friendly_promotional_goods',
      },
      {
        parent: 'packaging',
        title: 'Flexible Packaging',
        key: 'flexible_packaging',
      },
      {
        parent: 'packaging',
        title: 'Labels',
        key: 'labels',
      },
      {
        parent: 'packaging',
        title: 'Packaging Solutions',
        key: 'packaging_solutions',
      },
      {
        parent: 'raw_materials_and_chemicals',
        title: 'Chemicals',
        key: 'chemicals',
      },
      {
        parent: 'raw_materials_and_chemicals',
        title: 'Mining & Metals',
        key: 'mining_and_metals',
      },
      {
        parent: 'raw_materials_and_chemicals',
        title: 'Paper & Forest products',
        key: 'paper_and_forest_products',
      },
      {
        parent: 'raw_materials_and_chemicals',
        title: 'Plastics',
        key: 'plastics',
      },
      {
        parent: 'raw_materials_and_chemicals',
        title: 'Semi-Conductors',
        key: 'semi_conductors',
      },
      {
        parent: 'real-estate',
        title: 'Commercial',
        key: 'commercial',
      },
      {
        parent: 'real-estate',
        title: 'Investment',
        key: 'investment',
      },
      {
        parent: 'real-estate',
        title: 'Property Management',
        key: 'property_management',
      },
      {
        parent: 'real-estate',
        title: 'Property Management',
        key: 'property_management',
      },
      {
        parent: 'real-estate',
        title: 'Resident',
        key: 'resident',
      },
      {
        parent: 'recruitment_and_staffing',
        title: 'Admin / Secretarial Staff',
        key: 'admin_or_secretarial_staff',
      },
      {
        parent: 'recruitment_and_staffing',
        title: 'Creative Freelancer Recruitment',
        key: 'creative_freelancer_recruitment',
      },
      {
        parent: 'recruitment_and_staffing',
        title: 'End-to-end Workforce Solutions',
        key: 'end_to_end_workforce_solutions',
      },
      {
        parent: 'recruitment_and_staffing',
        title: 'Engineers & Production Workers',
        key: 'engineers_and_production_workers',
      },
      {
        parent: 'recruitment_and_staffing',
        title: 'HGV / LGV Drivers',
        key: 'hgv_or_lgv_drivers',
      },
      {
        parent: 'recruitment_and_staffing',
        title: 'Highly Skilled Workforce',
        key: 'highly_skilled_workforce',
      },
      {
        parent: 'recruitment_and_staffing',
        title: 'Hotel & Hospitality Staff',
        key: 'hotel_and_hospitality_staff',
      },
      {
        parent: 'recruitment_and_staffing',
        title: 'Industrial Workers',
        key: 'industrial_workers',
      },
      {
        parent: 'recruitment_and_staffing',
        title: 'International Recruitment',
        key: 'international_recruitment',
      },
      {
        parent: 'recruitment_and_staffing',
        title: 'IT Staffing',
        key: 'it_staffing',
      },
      {
        parent: 'recruitment_and_staffing',
        title: 'Legal Recruitment',
        key: 'legal_recruitment',
      },
      {
        parent: 'recruitment_and_staffing',
        title: 'Medical Staff',
        key: 'medical_staff',
      },
      {
        parent: 'recruitment_and_staffing',
        title: 'Pharmaceutical Recruitment',
        key: 'pharmaceutical_recruitment',
      },
      {
        parent: 'recruitment_and_staffing',
        title: 'Recruitment Services',
        key: 'recruitment_services',
      },
      {
        parent: 'recruitment_and_staffing',
        title: 'Senior Executive Search',
        key: 'senior_executive_search',
      },
      {
        parent: 'recruitment_and_staffing',
        title: 'Social Care',
        key: 'social_care',
      },
      {
        parent: 'recruitment_and_staffing',
        title: 'Temporary Staff',
        key: 'temporary_staff',
      },
      {
        parent: 'sustainability_and_environment',
        title: 'CSR Services',
        key: 'csr_services',
      },
      {
        parent: 'sustainability_and_environment',
        title: 'Environment Services',
        key: 'environment_services',
      },
      {
        parent: 'sustainability_and_environment',
        title: 'Fumigation Services',
        key: 'fumigation_services',
      },
      {
        parent: 'sustainability_and_environment',
        title: 'Recycling & Waste Management',
        key: 'recycling_and_waste_management',
      },
      {
        parent: 'sustainability_and_environment',
        title: 'Renewables',
        key: 'renewables',
      },
      {
        parent: 'sustainability_and_environment',
        title: 'Suitable Goods',
        key: 'suitable_goods',
      },
      {
        parent: 'transports_logistics_and_warehousing',
        title: 'Airlines / Aviation / Aerospace',
        key: 'airlines_or_aviation_or_aerospace',
      },
      {
        parent: 'transports_logistics_and_warehousing',
        title: 'Air Freight',
        key: 'air_freight',
      },
      {
        parent: 'transports_logistics_and_warehousing',
        title: 'Ambient Storage',
        key: 'ambient_storage',
      },
      {
        parent: 'transports_logistics_and_warehousing',
        title: 'Automotive',
        key: 'automotive',
      },
      {
        parent: 'transports_logistics_and_warehousing',
        title: 'Bonded Warehousing',
        key: 'bonded_warehousing',
      },
      {
        parent: 'transports_logistics_and_warehousing',
        title: 'Car & van Leasing',
        key: 'car_and_van_leasing',
      },
      {
        parent: 'transports_logistics_and_warehousing',
        title: 'Coach Hire',
        key: 'coach_hire',
      },
      {
        parent: 'transports_logistics_and_warehousing',
        title: 'Container Services',
        key: 'container_services',
      },
      {
        parent: 'transports_logistics_and_warehousing',
        title: 'Couriers',
        key: 'couriers',
      },
      {
        parent: 'transports_logistics_and_warehousing',
        title: 'Dangerous Goods',
        key: 'dangerous_goods',
      },
      {
        parent: 'transports_logistics_and_warehousing',
        title: 'Executive Car Hire',
        key: 'executive_car_hire',
      },
      {
        parent: 'transports_logistics_and_warehousing',
        title: 'Freight Forwarding, Containers & Cargo Handling',
        key: 'freight_forwarding_containers_and_cargo_handling',
      },
      {
        parent: 'transports_logistics_and_warehousing',
        title: 'Food Transport',
        key: 'food_transport',
      },
      {
        parent: 'transports_logistics_and_warehousing',
        title: 'HGV',
        key: 'hgv',
      },
      {
        parent: 'transports_logistics_and_warehousing',
        title: 'Maritime',
        key: 'maritime',
      },
      {
        parent: 'transports_logistics_and_warehousing',
        title: 'Packaging Services',
        key: 'packaging_services',
      },
      {
        parent: 'transports_logistics_and_warehousing',
        title: 'Private Jet Hire',
        key: 'private_jet_hire',
      },
      {
        parent: 'transports_logistics_and_warehousing',
        title: 'Rail Services',
        key: 'rail_services',
      },
      {
        parent: 'transports_logistics_and_warehousing',
        title: 'Relocation Services',
        key: 'relocation_services',
      },
      {
        parent: 'transports_logistics_and_warehousing',
        title: 'Refrigerated Transport',
        key: 'refrigerated_transport',
      },
      {
        parent: 'transports_logistics_and_warehousing',
        title: 'Removal Services',
        key: 'removal_services',
      },
      {
        parent: 'transports_logistics_and_warehousing',
        title: 'Sensible and temperature-controlled transportation',
        key: 'sensible_and_temperature_controlled_transportation',
      },
      {
        parent: 'transports_logistics_and_warehousing',
        title: 'Logistic staffing',
        key: 'logistic_staffing',
      },
      {
        parent: 'transports_logistics_and_warehousing',
        title: 'Storage',
        key: 'storage',
      },
      {
        parent: 'transports_logistics_and_warehousing',
        title: 'Taxi Service',
        key: 'taxi_service',
      },
      {
        parent: 'transports_logistics_and_warehousing',
        title: 'Transportation & Logistics',
        key: 'transportation_and_logistics',
      },
      {
        parent: 'transports_logistics_and_warehousing',
        title: 'Trucking',
        key: 'trucking',
      },
      {
        parent: 'transports_logistics_and_warehousing',
        title: 'Vehicle Cleaning',
        key: 'vehicle_cleaning',
      },
      {
        parent: 'transports_logistics_and_warehousing',
        title: 'Vehicle Servicing',
        key: 'vehicle_servicing',
      },
      {
        parent: 'transports_logistics_and_warehousing',
        title: 'Vehicle Valeting',
        key: 'vehicle_valeting',
      },
      {
        parent: 'transports_logistics_and_warehousing',
        title: 'Warehousing & Shipping',
        key: 'warehousing_and_shipping',
      },
      {
        parent: 'transports_logistics_and_warehousing',
        title: 'Warehousing Supplies',
        key: 'warehousing_supplies',
      },
      {
        parent: 'utilities',
        title: 'Electricity',
        key: 'electricity',
      },
      {
        parent: 'utilities',
        title: 'End-to-end infrastructure services',
        key: 'end_to_end_infrastructure_services',
      },
      {
        parent: 'utilities',
        title: 'Energy',
        key: 'energy',
      },
      {
        parent: 'utilities',
        title: 'Energy Consulting',
        key: 'energy_consulting',
      },
      {
        parent: 'utilities',
        title: 'Oil & Gas',
        key: 'oil_and_gas',
      },
      {
        parent: 'utilities',
        title: 'Sustainable Energy Solutions',
        key: 'sustainable_energy_solutions',
      },
      {
        parent: 'utilities',
        title: 'Water',
        key: 'water',
      },
    
  ];

//   services = [
//     {
//       parent: 'advertising_pr_marketing_and_print',
//       title: 'Advertising',
//       key: 'advertising',
//     },
//     {
//       parent: 'advertising_pr_marketing_and_print',
//       title: 'Animation',
//       key: 'animation',
//     },
//     {
//       parent: 'advertising_pr_marketing_and_print',
//       title: 'Brand Management',
//       key: 'brand_management',
//     },
//     {
//       parent: 'advertising_pr_marketing_and_print',
//       title: 'Creative Design Agency',
//       key: 'creative_design_agency',
//     },
//     {
//       parent: 'advertising_pr_marketing_and_print',
//       title: 'Digital Marketing & Print',
//       key: 'digital_marketing_and_print',
//     },
//     {
//       parent: 'advertising_pr_marketing_and_print',
//       title: 'End-to-end marketing agency',
//       key: 'end_to_end_marketing_agency',
//     },
//     {
//       parent: 'advertising_pr_marketing_and_print',
//       title: 'Enterprise 2.0',
//       key: 'enterprise_2_dot_0',
//     },
//     {
//       parent: 'advertising_pr_marketing_and_print',
//       title: 'Ethnic Media Marketing',
//       key: 'ethnic_media_marketing',
//     },
//     {
//       parent: 'advertising_pr_marketing_and_print',
//       title: 'Film Making',
//       key: 'film_making',
//     },
//     {
//       parent: 'advertising_pr_marketing_and_print',
//       title: 'Graphic Design',
//       key: 'graphic_design',
//     },
//     {
//       parent: 'advertising_pr_marketing_and_print',
//       title: 'Market Research',
//       key: 'market_research',
//     },
//     {
//       parent: 'advertising_pr_marketing_and_print',
//       title: 'Marketing Technology & Automation',
//       key: 'marketing_technology_and_automation',
//     },
//     {
//       parent: 'advertising_pr_marketing_and_print',
//       title: 'Media Agency',
//       key: 'media_agency',
//     },
//     {
//       parent: 'advertising_pr_marketing_and_print',
//       title: 'Media Production',
//       key: 'media_production',
//     },
//     {
//       parent: 'advertising_pr_marketing_and_print',
//       title: 'Online Media',
//       key: 'online_media',
//     },
//     {
//       parent: 'advertising_pr_marketing_and_print',
//       title: 'Photography',
//       key: 'photography',
//     },
//     {
//       parent: 'advertising_pr_marketing_and_print',
//       title: 'PR & Communications',
//       key: 'pr_and_communications',
//     },
//     {
//       parent: 'advertising_pr_marketing_and_print',
//       title: 'Print Fulfilment',
//       key: 'print_fulfilment',
//     },
//     {
//       parent: 'advertising_pr_marketing_and_print',
//       title: 'Print Management',
//       key: 'print_management',
//     },
//     {
//       parent: 'advertising_pr_marketing_and_print',
//       title: 'Print Visual Media',
//       key: 'print_visual_media',
//     },
//     {
//       parent: 'advertising_pr_marketing_and_print',
//       title: 'Product Launch',
//       key: 'product_launch',
//     },
//     {
//       parent: 'advertising_pr_marketing_and_print',
//       title: 'Publishing',
//       key: 'publishing',
//     },
//     {
//       parent: 'advertising_pr_marketing_and_print',
//       title: 'Social Media Agency',
//       key: 'social_media_agency',
//     },
//     {
//       parent: 'advertising_pr_marketing_and_print',
//       title: 'Telemarketing & Sales',
//       key: 'telemarketing_and_sales',
//     },
//     {
//       parent: 'advertising_pr_marketing_and_print',
//       title: 'Video Production',
//       key: 'video_production',
//     },
//     {
//       parent: 'advertising_pr_marketing_and_print',
//       title: 'Web 2.0',
//       key: 'web_2_dot_0',
//     },
//     {
//       parent: 'advertising_pr_marketing_and_print',
//       title: 'Web Marketing & Communication',
//       key: 'web_marketing_and_communication',
//     },
//     {
//       parent: 'advertising_pr_marketing_and_print',
//       title: 'Website Design',
//       key: 'website_design',
//     },
//     {
//       parent: 'advertising_pr_marketing_and_print',
//       title: 'Writing & Editing',
//       key: 'writing_and_editing',
//     },
//     {
//       parent: 'agriculture_and_farming',
//       title: 'Agritech',
//       key: 'agritech',
//     },
//     {
//       parent: 'agriculture_and_farming',
//       title: 'Coffee Production',
//       key: 'coffee_production',
//     },
//     {
//       parent: 'agriculture_and_farming',
//       title: 'Dairy',
//       key: 'dairy',
//     },
//     {
//       parent: 'agriculture_and_farming',
//       title: 'Fishery',
//       key: 'fishery',
//     },
//     {
//       parent: 'agriculture_and_farming',
//       title: 'Food Production',
//       key: 'food_production',
//     },
//     {
//       parent: 'agriculture_and_farming',
//       title: 'Ranching',
//       key: 'ranching',
//     },
//     {
//       parent: 'apparel_and_clothing',
//       title: 'Apparel/Clothing Manufacturer',
//       key: 'apparel_or_clothing_manufacturer',
//     },
//     {
//       parent: 'apparel_and_clothing',
//       title: 'Fashion Accessories',
//       key: 'fashion_accessories',
//     },
//     {
//       parent: 'apparel_and_clothing',
//       title: 'Merchandising',
//       key: 'merchandising',
//     },
//     {
//       parent: 'apparel_and_clothing',
//       title: 'Personal Protective Equipment',
//       key: 'personal_protective_equipment',
//     },
//     {
//       parent: 'apparel_and_clothing',
//       title: 'Sportswear',
//       key: 'sportswear',
//     },
//     {
//       parent: 'apparel_and_clothing',
//       title: 'Textiles',
//       key: 'textiles',
//     },
//     {
//       parent: 'apparel_and_clothing',
//       title: 'Workwear / Corporate ware',
//       key: 'workwear_or_corporate_ware',
//     },
//     {
//       parent: 'architecture_design_and_construction',
//       title: 'Architectural Design',
//       key: 'architectural_design',
//     },
//     {
//       parent: 'architecture_design_and_construction',
//       title: 'Building Material',
//       key: 'building_material',
//     },
//     {
//       parent: 'architecture_design_and_construction',
//       title: 'Civil & Structural Design Consultants',
//       key: 'civil_and_structural_design_consultants',
//     },
//     {
//       parent: 'architecture_design_and_construction',
//       title: 'Construction',
//       key: 'construction',
//     },
//     {
//       parent: 'architecture_design_and_construction',
//       title: 'Design Services',
//       key: 'design_services',
//     },
//     {
//       parent: 'architecture_design_and_construction',
//       title: 'Dismantling and remediation services',
//       key: 'dismantling_and_remediation_services',
//     },
//     {
//       parent: 'architecture_design_and_construction',
//       title: 'Electrical Engineering',
//       key: 'electrical_engineering',
//     },
//     {
//       parent: 'architecture_design_and_construction',
//       title: 'End-to-end general construction contractor',
//       key: 'end_to_end_general_construction_contractor',
//     },
//     {
//       parent: 'architecture_design_and_construction',
//       title: 'Engineering Services',
//       key: 'engineering_services',
//     },
//     {
//       parent: 'architecture_design_and_construction',
//       title: 'Facade Specialists',
//       key: 'facade_specialists',
//     },
//     {
//       parent: 'architecture_design_and_construction',
//       title: 'Flooring Specialists',
//       key: 'flooring_specialists',
//     },
//     {
//       parent: 'architecture_design_and_construction',
//       title: 'Furniture',
//       key: 'furniture',
//     },
//     {
//       parent: 'architecture_design_and_construction',
//       title: 'Glass, Ceramics & Concreating',
//       key: 'glass_ceramics_and_concreating',
//     },
//     {
//       parent: 'architecture_design_and_construction',
//       title: 'Groundworks',
//       key: 'groundworks',
//     },
//     {
//       parent: 'architecture_design_and_construction',
//       title: 'Insulation & Renovation Specialists',
//       key: 'insulation_and_renovation_specialists',
//     },
//     {
//       parent: 'architecture_design_and_construction',
//       title: 'Joinery',
//       key: 'joinery',
//     },
//     {
//       parent: 'architecture_design_and_construction',
//       title: 'Mechanical Engineering',
//       key: 'mechanical_engineering',
//     },
//     {
//       parent: 'architecture_design_and_construction',
//       title: 'Office Design',
//       key: 'office_design',
//     },
//     {
//       parent: 'architecture_design_and_construction',
//       title: 'Pipes & Drains',
//       key: 'pipes_and_drains',
//     },
//     {
//       parent: 'architecture_design_and_construction',
//       title: 'Plant & Machine Hire',
//       key: 'plant_and_machine_hire',
//     },
//     {
//       parent: 'architecture_design_and_construction',
//       title: 'Plastering',
//       key: 'plastering',
//     },
//     {
//       parent: 'architecture_design_and_construction',
//       title: 'Product Suppliers',
//       key: 'product_suppliers',
//     },
//     {
//       parent: 'architecture_design_and_construction',
//       title: 'Project Management',
//       key: 'project_management',
//     },
//     {
//       parent: 'architecture_design_and_construction',
//       title: 'Protect Valuation',
//       key: 'protect_valuation',
//     },
//     {
//       parent: 'architecture_design_and_construction',
//       title: 'Refrigeration Services',
//       key: 'refrigeration_services',
//     },
//     {
//       parent: 'architecture_design_and_construction',
//       title: 'Roofing, cladding and waterproofing',
//       key: 'roofing_cladding_and_waterproofing',
//     },
//     {
//       parent: 'architecture_design_and_construction',
//       title: 'Scaffolding',
//       key: 'scaffolding',
//     },
//     {
//       parent: 'architecture_design_and_construction',
//       title: 'Sustainable Construction',
//       key: 'sustainable_construction',
//     },
//     {
//       parent: 'architecture_design_and_construction',
//       title: 'Steel Fixing',
//       key: 'steel_fixing',
//     },
//     {
//       parent: 'architecture_design_and_construction',
//       title: 'Stone Supply',
//       key: 'stone_supply',
//     },
//     {
//       parent: 'architecture_design_and_construction',
//       title: 'Structural Engineering',
//       key: 'structural_engineering',
//     },
//     {
//       parent: 'architecture_design_and_construction',
//       title: 'Surveying',
//       key: 'surveying',
//     },
//     {
//       parent: 'architecture_design_and_construction',
//       title: 'Tile & Mosaic services',
//       key: 'tile_and_mosaic_services',
//     },
//     {
//       parent: 'architecture_design_and_construction',
//       title: 'Water protection systems',
//       key: 'water_protection_systems',
//     },
//     {
//       parent: 'business_services_or_consultancy',
//       title: 'Bid Writing',
//       key: 'bid_writing',
//     },
//     {
//       parent: 'business_services_or_consultancy',
//       title: 'Bid management & Tender Consultancy',
//       key: 'bid_management_and_tender_consultancy',
//     },
//     {
//       parent: 'business_services_or_consultancy',
//       title: 'Consulting',
//       key: 'consulting',
//     },
//     {
//       parent: 'business_services_or_consultancy',
//       title: 'Data Services',
//       key: 'data_services',
//     },
//     {
//       parent: 'business_services_or_consultancy',
//       title: 'Diversity, Equity and Inclusion',
//       key: 'diversity_equity_and_inclusion',
//     },
//     {
//       parent: 'business_services_or_consultancy',
//       title: 'Document Storage & Archiving',
//       key: 'document_storage_and_archiving',
//     },
//     {
//       parent: 'business_services_or_consultancy',
//       title: 'Employee Benefits',
//       key: 'employee_benefits',
//     },
//     {
//       parent: 'business_services_or_consultancy',
//       title: 'HR & Payroll',
//       key: 'hr_and_payroll',
//     },
//     {
//       parent: 'business_services_or_consultancy',
//       title: 'Insurance',
//       key: 'insurance',
//     },
//     {
//       parent: 'business_services_or_consultancy',
//       title: 'Intellectual Property Services',
//       key: 'intellectual_property_services',
//     },
//     {
//       parent: 'business_services_or_consultancy',
//       title: 'Legal Services',
//       key: 'legal_services',
//     },
//     {
//       parent: 'business_services_or_consultancy',
//       title: 'Mentoring',
//       key: 'mentoring',
//     },
//     {
//       parent: 'business_services_or_consultancy',
//       title: 'Media',
//       key: 'media',
//     },
//     {
//       parent: 'business_services_or_consultancy',
//       title: 'Office / Room Hire',
//       key: 'office_or_room_hire',
//     },
//     {
//       parent: 'business_services_or_consultancy',
//       title: 'Outsourcing',
//       key: 'outsourcing',
//     },
//     {
//       parent: 'business_services_or_consultancy',
//       title: 'Relocation Services',
//       key: 'relocation_services',
//     },
//     {
//       parent: 'business_services_or_consultancy',
//       title: 'Risk Management Consultancy',
//       key: 'risk_management_consultancy',
//     },
//     {
//       parent: 'business_services_or_consultancy',
//       title: 'Scanning',
//       key: 'scanning',
//     },
//     {
//       parent: 'business_services_or_consultancy',
//       title: 'Supply Chain Management',
//       key: 'supply_chain_management',
//     },
//     {
//       parent: 'business_services_or_consultancy',
//       title: 'Trade Mark Lawyers',
//       key: 'trade_mark_lawyers',
//     },
//     {
//       parent: 'business_services_or_consultancy',
//       title: 'Translation Services',
//       key: 'translation_services',
//     },
//     {
//       parent: 'business_services_or_consultancy',
//       title: 'Workspaces Services',
//       key: 'workspaces_services',
//     },
//     {
//       parent: 'consumer_goods',
//       title: 'Electronics',
//       key: 'electronics',
//     },
//     {
//       parent: 'consumer_goods',
//       title: 'Fashion / Apparel',
//       key: 'fashion_or_apparel',
//     },
//     {
//       parent: 'consumer_goods',
//       title: 'General Goods',
//       key: 'general_goods',
//     },
//     {
//       parent: 'consumer_goods',
//       title: 'Health & Beauty',
//       key: 'health_and_beauty',
//     },
//     {
//       parent: 'consumer_goods',
//       title: 'Jewellery',
//       key: 'jewellery',
//     },
//     {
//       parent: 'consumer_goods',
//       title: 'Retail',
//       key: 'retail',
//     },
//     {
//       parent: 'consumer_goods',
//       title: 'Sustainable Products',
//       key: 'sustainable_products',
//     },
//     {
//       parent: 'consumer_goods',
//       title: 'Textiles',
//       key: 'textiles',
//     },
//     {
//       parent: 'consumer_goods',
//       title: 'Inclusive Toys / Games',
//       key: 'inclusive_toys_or_games',
//     },
//     {
//       parent: 'consumer_goods',
//       title: 'Wholesale of ethnic and exotic products',
//       key: 'wholesale_of_ethnic_and_exotic_products',
//     },
//     {
//       parent: 'consumer_goods',
//       title: 'Wholesale',
//       key: 'wholesale',
//     },
//     {
//       parent: 'education_and_training',
//       title: 'Change Management',
//       key: 'change_management',
//     },
//     {
//       parent: 'education_and_training',
//       title: 'Clerical Training',
//       key: 'clerical_training',
//     },
//     {
//       parent: 'education_and_training',
//       title: 'Coaching / Professional Development',
//       key: 'coaching_or_professional_development',
//     },
//     {
//       parent: 'education_and_training',
//       title: 'Consultancy',
//       key: 'consultancy',
//     },
//     {
//       parent: 'education_and_training',
//       title: 'Diversity Training',
//       key: 'diversity_training',
//     },
//     {
//       parent: 'education_and_training',
//       title: 'Financial Education',
//       key: 'financial_education',
//     },
//     {
//       parent: 'education_and_training',
//       title: 'Higher Education',
//       key: 'higher_education',
//     },
//     {
//       parent: 'education_and_training',
//       title: 'IT Training',
//       key: 'it_training',
//     },
//     {
//       parent: 'education_and_training',
//       title: 'Language Training Services',
//       key: 'language_training_services',
//     },
//     {
//       parent: 'education_and_training',
//       title: 'Leadership Development Training',
//       key: 'leadership_development_training',
//     },
//     {
//       parent: 'education_and_training',
//       title: 'Management Training',
//       key: 'management_training',
//     },
//     {
//       parent: 'education_and_training',
//       title: 'Primary / Secondary Education',
//       key: 'primary_or_secondary_education',
//     },
//     {
//       parent: 'education_and_training',
//       title: 'Sales Training',
//       key: 'sales_training',
//     },
//     {
//       parent: 'education_and_training',
//       title: 'Supply Chain Training',
//       key: 'supply_chain_training',
//     },
//     {
//       parent: 'education_and_training',
//       title: 'Team Building Training',
//       key: 'team_building_training',
//     },
//     {
//       parent: 'education_and_training',
//       title: 'Workforce Mediation',
//       key: 'workforce_mediation',
//     },
//     {
//       parent: 'event_management',
//       title: 'Audio – Visual',
//       key: 'audio_visual',
//     },
//     {
//       parent: 'event_management',
//       title: 'Conference Management',
//       key: 'conference_management',
//     },
//     {
//       parent: 'event_management',
//       title: 'Corporate Event Hospitality',
//       key: 'corporate_event_hospitality',
//     },
//     {
//       parent: 'event_management',
//       title: 'Entertainment',
//       key: 'entertainment',
//     },
//     {
//       parent: 'event_management',
//       title: 'Event Catering',
//       key: 'event_catering',
//     },
//     {
//       parent: 'event_management',
//       title: 'Event Supplies',
//       key: 'event_supplies',
//     },
//     {
//       parent: 'event_management',
//       title: 'Exhibition & Trade Shows',
//       key: 'exhibition_and_trade_shows',
//     },
//     {
//       parent: 'event_management',
//       title: 'Full Event Management Services',
//       key: 'full_event_management_services',
//     },
//     {
//       parent: 'event_management',
//       title: 'Product Launches',
//       key: 'product_launches',
//     },
//     {
//       parent: 'event_management',
//       title: 'Road Shows',
//       key: 'road_shows',
//     },
//     {
//       parent: 'event_management',
//       title: 'Speciality Corporate events',
//       key: 'speciality_corporate_events',
//     },
//     {
//       parent: 'event_management',
//       title: 'Team Building Activities',
//       key: 'team_building_activities',
//     },
//     {
//       parent: 'event_management',
//       title: 'Venue Hire',
//       key: 'venue_hire',
//     },
//     {
//       parent: 'facilities_management',
//       title: 'Building Maintenance',
//       key: 'building_maintenance',
//     },
//     {
//       parent: 'facilities_management',
//       title: 'Cash Security',
//       key: 'cash_security',
//     },
//     {
//       parent: 'facilities_management',
//       title: 'Commercial catering equipment',
//       key: 'commercial_catering_equipment',
//     },
//     {
//       parent: 'facilities_management',
//       title: 'Commercial Lighting',
//       key: 'commercial_lighting',
//     },
//     {
//       parent: 'facilities_management',
//       title: 'Concierge Service',
//       key: 'concierge_service',
//     },
//     {
//       parent: 'facilities_management',
//       title: 'Dry Cleaning Services',
//       key: 'dry_cleaning_services',
//     },
//     {
//       parent: 'facilities_management',
//       title: 'Ventilation Management Services',
//       key: 'ventilation_management_services',
//     },
//     {
//       parent: 'facilities_management',
//       title: 'Construction Cleaning',
//       key: 'construction_cleaning',
//     },
//     {
//       parent: 'facilities_management',
//       title: 'Electrical Services',
//       key: 'electrical_services',
//     },
//     {
//       parent: 'facilities_management',
//       title: 'Environmental Services',
//       key: 'environmental_services',
//     },
//     {
//       parent: 'facilities_management',
//       title: 'Flooring',
//       key: 'flooring',
//     },
//     {
//       parent: 'facilities_management',
//       title: 'Glazing',
//       key: 'glazing',
//     },
//     {
//       parent: 'facilities_management',
//       title: 'Grounds & Parks Maintenance',
//       key: 'grounds_and_parks_maintenance',
//     },
//     {
//       parent: 'facilities_management',
//       title: 'Health & Safety',
//       key: 'health_and_safety',
//     },
//     {
//       parent: 'facilities_management',
//       title: 'HVAC (Heating, Ventilation, and Air-conditioning)',
//       key: 'hvac_heating_ventilation_and_air_conditioning',
//     },
//     {
//       parent: 'facilities_management',
//       title: 'Industrial Cleaning',
//       key: 'industrial_cleaning',
//     },
//     {
//       parent: 'facilities_management',
//       title: 'Industrial Lighting',
//       key: 'industrial_lighting',
//     },
//     {
//       parent: 'facilities_management',
//       title: 'Janitorial Services',
//       key: 'janitorial_services',
//     },
//     {
//       parent: 'facilities_management',
//       title: 'Landscaping',
//       key: 'landscaping',
//     },
//     {
//       parent: 'facilities_management',
//       title: 'LED Lighting',
//       key: 'led_lighting',
//     },
//     {
//       parent: 'facilities_management',
//       title: 'Manned Security',
//       key: 'manned_security',
//     },
//     {
//       parent: 'facilities_management',
//       title: 'Office Cleaning',
//       key: 'office_cleaning',
//     },
//     {
//       parent: 'facilities_management',
//       title: 'Painting & Decorating',
//       key: 'painting_and_decorating',
//     },
//     {
//       parent: 'facilities_management',
//       title: 'Pest Control',
//       key: 'pest_control',
//     },
//     {
//       parent: 'facilities_management',
//       title: 'Plumbing & Central heating',
//       key: 'plumbing_and_central_heating',
//     },
//     {
//       parent: 'facilities_management',
//       title: 'Refrigeration',
//       key: 'refrigeration',
//     },
//     {
//       parent: 'facilities_management',
//       title: 'Refurbishment Removals',
//       key: 'refurbishment_removals',
//     },
//     {
//       parent: 'facilities_management',
//       title: 'Security Systems',
//       key: 'security_systems',
//     },
//     {
//       parent: 'facilities_management',
//       title: 'Signage',
//       key: 'signage',
//     },
//     {
//       parent: 'facilities_management',
//       title: 'Total FM Solutions',
//       key: 'total_fm_solutions',
//     },
//     {
//       parent: 'facilities_management',
//       title: 'Traffic Management',
//       key: 'traffic_management',
//     },
//     {
//       parent: 'facilities_management',
//       title: 'Waste Management',
//       key: 'waste_management',
//     },
//     {
//       parent: 'facilities_management',
//       title: 'Window Cleaning',
//       key: 'window_cleaning',
//     },
//     {
//       parent: 'financial_services',
//       title: 'Accounting',
//       key: 'accounting',
//     },
//     {
//       parent: 'financial_services',
//       title: 'Auditing',
//       key: 'auditing',
//     },
//     {
//       parent: 'financial_services',
//       title: 'Banking',
//       key: 'banking',
//     },
//     {
//       parent: 'financial_services',
//       title: 'Fundraising',
//       key: 'fundraising',
//     },
//     {
//       parent: 'financial_services',
//       title: 'Investment Banking',
//       key: 'investment_banking',
//     },
//     {
//       parent: 'financial_services',
//       title: 'Investment Management',
//       key: 'investment_management',
//     },
//     {
//       parent: 'financial_services',
//       title: 'Venture Capital & Private Equity',
//       key: 'venture_capital_and_private_equity',
//     },
//     {
//       parent: 'healthcare_pharmaceutical_and_medical',
//       title: 'Alternative Medicine',
//       key: 'alternative_medicine',
//     },
//     {
//       parent: 'healthcare_pharmaceutical_and_medical',
//       title: 'Biotechnology',
//       key: 'biotechnology',
//     },
//     {
//       parent: 'healthcare_pharmaceutical_and_medical',
//       title: 'Clinical Audit',
//       key: 'clinical_audit',
//     },
//     {
//       parent: 'healthcare_pharmaceutical_and_medical',
//       title: 'Contract Clinical Research',
//       key: 'contract_clinical_research',
//     },
//     {
//       parent: 'healthcare_pharmaceutical_and_medical',
//       title: 'Hospital & Healthcare',
//       key: 'hospital_and_healthcare',
//     },
//     {
//       parent: 'healthcare_pharmaceutical_and_medical',
//       title: 'Lab Testing',
//       key: 'lab_testing',
//     },
//     {
//       parent: 'healthcare_pharmaceutical_and_medical',
//       title: 'Medical Devices',
//       key: 'medical_devices',
//     },
//     {
//       parent: 'healthcare_pharmaceutical_and_medical',
//       title: 'Medical Practice',
//       key: 'medical_practice',
//     },
//     {
//       parent: 'healthcare_pharmaceutical_and_medical',
//       title: 'Mental Healthcare',
//       key: 'mental_healthcare',
//     },
//     {
//       parent: 'healthcare_pharmaceutical_and_medical',
//       title: 'Pharmaceuticals',
//       key: 'pharmaceuticals',
//     },
//     {
//       parent: 'healthcare_pharmaceutical_and_medical',
//       title: 'Product Trials',
//       key: 'product_trials',
//     },
//     {
//       parent: 'healthcare_pharmaceutical_and_medical',
//       title: 'Public Health',
//       key: 'public_health',
//     },
//     {
//       parent: 'healthcare_pharmaceutical_and_medical',
//       title: 'Social care',
//       key: 'social_care',
//     },
//     {
//       parent: 'healthcare_pharmaceutical_and_medical',
//       title: 'Surgical & Clinical Goods Supplies',
//       key: 'surgical_and_clinical_goods_supplies',
//     },
//     {
//       parent: 'healthcare_pharmaceutical_and_medical',
//       title: 'Wellness & Fitness',
//       key: 'wellness_and_fitness',
//     },
//     {
//       parent: 'hospitality_and_catering',
//       title: 'Kitchen Equipment',
//       key: 'kitchen_equipment',
//     },
//     {
//       parent: 'hospitality_and_catering',
//       title: 'Cafeteria Management',
//       key: 'cafeteria_management',
//     },
//     {
//       parent: 'hospitality_and_catering',
//       title: 'Catering',
//       key: 'catering',
//     },
//     {
//       parent: 'hospitality_and_catering',
//       title: 'Dairy Products',
//       key: 'dairy_products',
//     },
//     {
//       parent: 'hospitality_and_catering',
//       title: 'Ethnic Food Catering',
//       key: 'ethnic_food_catering',
//     },
//     {
//       parent: 'hospitality_and_catering',
//       title: 'Ethnic Food / Ingredients Wholesaler',
//       key: 'ethnic_food_or_ingredients_wholesaler',
//     },
//     {
//       parent: 'hospitality_and_catering',
//       title: 'Food & Beverage Supplies',
//       key: 'food_and_beverage_supplies',
//     },
//     {
//       parent: 'hospitality_and_catering',
//       title: 'Food & Ingredients Wholesaler',
//       key: 'food_and_ingredients_wholesaler',
//     },
//     {
//       parent: 'hospitality_and_catering',
//       title: 'Food Wholeseller',
//       key: 'food_wholeseller',
//     },
//     {
//       parent: 'hospitality_and_catering',
//       title: 'Fresh Produce',
//       key: 'fresh_produce',
//     },
//     {
//       parent: 'hospitality_and_catering',
//       title: 'Frozen/Meat Products',
//       key: 'frozenormeat_products',
//     },
//     {
//       parent: 'hospitality_and_catering',
//       title: 'Product Supplies',
//       key: 'product_supplies',
//     },
//     {
//       parent: 'hospitality_and_catering',
//       title: 'Vending Machines',
//       key: 'vending_machines',
//     },
//     {
//       parent: 'information_technology_and_telecommunications',
//       title: 'AI and Robotics',
//       key: 'ai_and_robotics',
//     },
//     {
//       parent: 'information_technology_and_telecommunications',
//       title: 'Application Development',
//       key: 'application_development',
//     },
//     {
//       parent: 'information_technology_and_telecommunications',
//       title: 'Automation',
//       key: 'automation',
//     },
//     {
//       parent: 'information_technology_and_telecommunications',
//       title: 'Blockchain',
//       key: 'blockchain',
//     },
//     {
//       parent: 'information_technology_and_telecommunications',
//       title: 'Cloud Computing',
//       key: 'cloud_computing',
//     },
//     {
//       parent: 'information_technology_and_telecommunications',
//       title: 'Computer Equipment Disposal',
//       key: 'computer_equipment_disposal',
//     },
//     {
//       parent: 'information_technology_and_telecommunications',
//       title: 'Cyber Security',
//       key: 'cyber_security',
//     },
//     {
//       parent: 'information_technology_and_telecommunications',
//       title: 'Fintech Solutions',
//       key: 'fintech_solutions',
//     },
//     {
//       parent: 'information_technology_and_telecommunications',
//       title: 'E-Commerce Solutions',
//       key: 'e_commerce_solutions',
//     },
//     {
//       parent: 'information_technology_and_telecommunications',
//       title: 'Gaming',
//       key: 'gaming',
//     },
//     {
//       parent: 'information_technology_and_telecommunications',
//       title: 'Hardware, Peripherals & Networks',
//       key: 'hardware_peripherals_and_networks',
//     },
//     {
//       parent: 'information_technology_and_telecommunications',
//       title: 'Hosted Solutions',
//       key: 'hosted_solutions',
//     },
//     {
//       parent: 'information_technology_and_telecommunications',
//       title: 'Immersive Technology',
//       key: 'immersive_technology',
//     },
//     {
//       parent: 'information_technology_and_telecommunications',
//       title: 'IOT',
//       key: 'iot',
//     },
//     {
//       parent: 'information_technology_and_telecommunications',
//       title: 'IT Consultancy',
//       key: 'it_consultancy',
//     },
//     {
//       parent: 'information_technology_and_telecommunications',
//       title: 'IT Security',
//       key: 'it_security',
//     },
//     {
//       parent: 'information_technology_and_telecommunications',
//       title: 'Mobile Solutions',
//       key: 'mobile_solutions',
//     },
//     {
//       parent: 'information_technology_and_telecommunications',
//       title: 'Software development',
//       key: 'software_development',
//     },
//     {
//       parent: 'information_technology_and_telecommunications',
//       title: 'Support & Maintenance Services',
//       key: 'support_and_maintenance_services',
//     },
//     {
//       parent: 'information_technology_and_telecommunications',
//       title: 'Sustainability Analytics Services',
//       key: 'sustainability_analytics_services',
//     },
//     {
//       parent: 'information_technology_and_telecommunications',
//       title: 'Telecommunications',
//       key: 'telecommunications',
//     },
//     {
//       parent: 'information_technology_and_telecommunications',
//       title: 'Web Design',
//       key: 'web_design',
//     },
//     {
//       parent: 'information_technology_and_telecommunications',
//       title: 'Web Services',
//       key: 'web_services',
//     },
//     {
//       parent: 'insurance',
//       title: 'Other',
//       key: 'other',
//     },
//     {
//       parent: 'leisure_travel_and_tourism',
//       title: 'Accommodation Services',
//       key: 'accommodation_services',
//     },
//     {
//       parent: 'leisure_travel_and_tourism',
//       title: 'Entertainment',
//       key: 'entertainment',
//     },
//     {
//       parent: 'leisure_travel_and_tourism',
//       title: 'Hotels',
//       key: 'hotels',
//     },
//     {
//       parent: 'leisure_travel_and_tourism',
//       title: 'Recreational Facilities & Services',
//       key: 'recreational_facilities_and_services',
//     },
//     {
//       parent: 'leisure_travel_and_tourism',
//       title: 'Travel Services',
//       key: 'travel_services',
//     },
//     {
//       parent: 'leisure_travel_and_tourism',
//       title: 'Concierge Services',
//       key: 'concierge_services',
//     },
//     {
//       parent: 'manufacturing_industrial_and_engineering',
//       title: 'Alloy Engineering',
//       key: 'alloy_engineering',
//     },
//     {
//       parent: 'manufacturing_industrial_and_engineering',
//       title: 'Assembly',
//       key: 'assembly',
//     },
//     {
//       parent: 'manufacturing_industrial_and_engineering',
//       title: 'Automotive Parts Manufacturing',
//       key: 'automotive_parts_manufacturing',
//     },
//     {
//       parent: 'manufacturing_industrial_and_engineering',
//       title: 'Boiler Maintainence',
//       key: 'boiler_maintainence',
//     },
//     {
//       parent: 'manufacturing_industrial_and_engineering',
//       title: 'Calibration Services',
//       key: 'calibration_services',
//     },
//     {
//       parent: 'manufacturing_industrial_and_engineering',
//       title: 'Civil Engineering',
//       key: 'civil_engineering',
//     },
//     {
//       parent: 'manufacturing_industrial_and_engineering',
//       title: 'Electrical Installation',
//       key: 'electrical_installation',
//     },
//     {
//       parent: 'manufacturing_industrial_and_engineering',
//       title: 'Energy Efficient Electrical Systems',
//       key: 'energy_efficient_electrical_systems',
//     },
//     {
//       parent: 'manufacturing_industrial_and_engineering',
//       title: 'Engineering Services',
//       key: 'engineering_services',
//     },
//     {
//       parent: 'manufacturing_industrial_and_engineering',
//       title: 'General Maintenance',
//       key: 'general_maintenance',
//     },
//     {
//       parent: 'manufacturing_industrial_and_engineering',
//       title: 'Heavy Earth Moving',
//       key: 'heavy_earth_moving',
//     },
//     {
//       parent: 'manufacturing_industrial_and_engineering',
//       title: 'Industrial Automation',
//       key: 'industrial_automation',
//     },
//     {
//       parent: 'manufacturing_industrial_and_engineering',
//       title: 'Industrial Plant & Equipment Installation',
//       key: 'industrial_plant_and_equipment_installation',
//     },
//     {
//       parent: 'manufacturing_industrial_and_engineering',
//       title: 'Industrial Services',
//       key: 'industrial_services',
//     },
//     {
//       parent: 'manufacturing_industrial_and_engineering',
//       title: 'Mechanical Engineering',
//       key: 'mechanical_engineering',
//     },
//     {
//       parent: 'manufacturing_industrial_and_engineering',
//       title: 'Metal Fabrication',
//       key: 'metal_fabrication',
//     },
//     {
//       parent: 'manufacturing_industrial_and_engineering',
//       title: 'Plant & Equipment Maintenance',
//       key: 'plant_and_equipment_maintenance',
//     },
//     {
//       parent: 'manufacturing_industrial_and_engineering',
//       title: 'Plastic Moulding',
//       key: 'plastic_moulding',
//     },
//     {
//       parent: 'manufacturing_industrial_and_engineering',
//       title: 'Precision Engineering',
//       key: 'precision_engineering',
//     },
//     {
//       parent: 'manufacturing_industrial_and_engineering',
//       title: 'Steel & Aluminium Casting',
//       key: 'steel_and_aluminium_casting',
//     },
//     {
//       parent: 'manufacturing_industrial_and_engineering',
//       title: 'Structural Engineering',
//       key: 'structural_engineering',
//     },
//     {
//       parent: 'office_suppliers_and_promotional_goods',
//       title: 'Clothing, Footwear & Corporate wear',
//       key: 'clothing_footwear_and_corporate_wear',
//     },
//     {
//       parent: 'office_suppliers_and_promotional_goods',
//       title: 'Corporate Gifts',
//       key: 'corporate_gifts',
//     },
//     {
//       parent: 'office_suppliers_and_promotional_goods',
//       title: 'Electrical goods',
//       key: 'electrical_goods',
//     },
//     {
//       parent: 'office_suppliers_and_promotional_goods',
//       title: 'Janitorial Supplies',
//       key: 'janitorial_supplies',
//     },
//     {
//       parent: 'office_suppliers_and_promotional_goods',
//       title: 'Office Stationary',
//       key: 'office_stationary',
//     },
//     {
//       parent: 'office_suppliers_and_promotional_goods',
//       title: 'Offices Supplies',
//       key: 'offices_supplies',
//     },
//     {
//       parent: 'office_suppliers_and_promotional_goods',
//       title: 'Promotional goods & Bespoke Gift Items',
//       key: 'promotional_goods_and_bespoke_gift_items',
//     },
//     {
//       parent: 'office_suppliers_and_promotional_goods',
//       title: 'Sustainable Goods',
//       key: 'sustainable_goods',
//     },
//     {
//       parent: 'packaging',
//       title: 'Contract packaging',
//       key: 'contract_packaging',
//     },
//     {
//       parent: 'packaging',
//       title: 'Corrugated Packaging',
//       key: 'corrugated_packaging',
//     },
//     {
//       parent: 'packaging',
//       title: 'Environment Friendly Promotional Goods',
//       key: 'environment_friendly_promotional_goods',
//     },
//     {
//       parent: 'packaging',
//       title: 'Flexible Packaging',
//       key: 'flexible_packaging',
//     },
//     {
//       parent: 'packaging',
//       title: 'Labels',
//       key: 'labels',
//     },
//     {
//       parent: 'packaging',
//       title: 'Packaging Solutions',
//       key: 'packaging_solutions',
//     },
//     {
//       parent: 'raw_materials_and_chemicals',
//       title: 'Chemicals',
//       key: 'chemicals',
//     },
//     {
//       parent: 'raw_materials_and_chemicals',
//       title: 'Mining & Metals',
//       key: 'mining_and_metals',
//     },
//     {
//       parent: 'raw_materials_and_chemicals',
//       title: 'Paper & Forest products',
//       key: 'paper_and_forest_products',
//     },
//     {
//       parent: 'raw_materials_and_chemicals',
//       title: 'Plastics',
//       key: 'plastics',
//     },
//     {
//       parent: 'raw_materials_and_chemicals',
//       title: 'Semi-Conductors',
//       key: 'semi_conductors',
//     },
//     {
//       parent: 'real-estate',
//       title: 'Commercial',
//       key: 'commercial',
//     },
//     {
//       parent: 'real-estate',
//       title: 'Investment',
//       key: 'investment',
//     },
//     {
//       parent: 'real-estate',
//       title: 'Property Management',
//       key: 'property_management',
//     },
//     {
//       parent: 'real-estate',
//       title: 'Property Management',
//       key: 'property_management',
//     },
//     {
//       parent: 'real-estate',
//       title: 'Resident',
//       key: 'resident',
//     },
//     {
//       parent: 'recruitment_and_staffing',
//       title: 'Admin / Secretarial Staff',
//       key: 'admin_or_secretarial_staff',
//     },
//     {
//       parent: 'recruitment_and_staffing',
//       title: 'Creative Freelancer Recruitment',
//       key: 'creative_freelancer_recruitment',
//     },
//     {
//       parent: 'recruitment_and_staffing',
//       title: 'End-to-end Workforce Solutions',
//       key: 'end_to_end_workforce_solutions',
//     },
//     {
//       parent: 'recruitment_and_staffing',
//       title: 'Engineers & Production Workers',
//       key: 'engineers_and_production_workers',
//     },
//     {
//       parent: 'recruitment_and_staffing',
//       title: 'HGV / LGV Drivers',
//       key: 'hgv_or_lgv_drivers',
//     },
//     {
//       parent: 'recruitment_and_staffing',
//       title: 'Highly Skilled Workforce',
//       key: 'highly_skilled_workforce',
//     },
//     {
//       parent: 'recruitment_and_staffing',
//       title: 'Hotel & Hospitality Staff',
//       key: 'hotel_and_hospitality_staff',
//     },
//     {
//       parent: 'recruitment_and_staffing',
//       title: 'Industrial Workers',
//       key: 'industrial_workers',
//     },
//     {
//       parent: 'recruitment_and_staffing',
//       title: 'International Recruitment',
//       key: 'international_recruitment',
//     },
//     {
//       parent: 'recruitment_and_staffing',
//       title: 'IT Staffing',
//       key: 'it_staffing',
//     },
//     {
//       parent: 'recruitment_and_staffing',
//       title: 'Legal Recruitment',
//       key: 'legal_recruitment',
//     },
//     {
//       parent: 'recruitment_and_staffing',
//       title: 'Medical Staff',
//       key: 'medical_staff',
//     },
//     {
//       parent: 'recruitment_and_staffing',
//       title: 'Pharmaceutical Recruitment',
//       key: 'pharmaceutical_recruitment',
//     },
//     {
//       parent: 'recruitment_and_staffing',
//       title: 'Recruitment Services',
//       key: 'recruitment_services',
//     },
//     {
//       parent: 'recruitment_and_staffing',
//       title: 'Senior Executive Search',
//       key: 'senior_executive_search',
//     },
//     {
//       parent: 'recruitment_and_staffing',
//       title: 'Social Care',
//       key: 'social_care',
//     },
//     {
//       parent: 'recruitment_and_staffing',
//       title: 'Temporary Staff',
//       key: 'temporary_staff',
//     },
//     {
//       parent: 'sustainability_and_environment',
//       title: 'CSR Services',
//       key: 'csr_services',
//     },
//     {
//       parent: 'sustainability_and_environment',
//       title: 'Environment Services',
//       key: 'environment_services',
//     },
//     {
//       parent: 'sustainability_and_environment',
//       title: 'Fumigation Services',
//       key: 'fumigation_services',
//     },
//     {
//       parent: 'sustainability_and_environment',
//       title: 'Recycling & Waste Management',
//       key: 'recycling_and_waste_management',
//     },
//     {
//       parent: 'sustainability_and_environment',
//       title: 'Renewables',
//       key: 'renewables',
//     },
//     {
//       parent: 'sustainability_and_environment',
//       title: 'Suitable Goods',
//       key: 'suitable_goods',
//     },
//     {
//       parent: 'transports_logistics_and_warehousing',
//       title: 'Airlines / Aviation / Aerospace',
//       key: 'airlines_or_aviation_or_aerospace',
//     },
//     {
//       parent: 'transports_logistics_and_warehousing',
//       title: 'Air Freight',
//       key: 'air_freight',
//     },
//     {
//       parent: 'transports_logistics_and_warehousing',
//       title: 'Ambient Storage',
//       key: 'ambient_storage',
//     },
//     {
//       parent: 'transports_logistics_and_warehousing',
//       title: 'Automotive',
//       key: 'automotive',
//     },
//     {
//       parent: 'transports_logistics_and_warehousing',
//       title: 'Bonded Warehousing',
//       key: 'bonded_warehousing',
//     },
//     {
//       parent: 'transports_logistics_and_warehousing',
//       title: 'Car & van Leasing',
//       key: 'car_and_van_leasing',
//     },
//     {
//       parent: 'transports_logistics_and_warehousing',
//       title: 'Coach Hire',
//       key: 'coach_hire',
//     },
//     {
//       parent: 'transports_logistics_and_warehousing',
//       title: 'Container Services',
//       key: 'container_services',
//     },
//     {
//       parent: 'transports_logistics_and_warehousing',
//       title: 'Couriers',
//       key: 'couriers',
//     },
//     {
//       parent: 'transports_logistics_and_warehousing',
//       title: 'Dangerous Goods',
//       key: 'dangerous_goods',
//     },
//     {
//       parent: 'transports_logistics_and_warehousing',
//       title: 'Executive Car Hire',
//       key: 'executive_car_hire',
//     },
//     {
//       parent: 'transports_logistics_and_warehousing',
//       title: 'Freight Forwarding, Containers & Cargo Handling',
//       key: 'freight_forwarding_containers_and_cargo_handling',
//     },
//     {
//       parent: 'transports_logistics_and_warehousing',
//       title: 'Food Transport',
//       key: 'food_transport',
//     },
//     {
//       parent: 'transports_logistics_and_warehousing',
//       title: 'HGV',
//       key: 'hgv',
//     },
//     {
//       parent: 'transports_logistics_and_warehousing',
//       title: 'Maritime',
//       key: 'maritime',
//     },
//     {
//       parent: 'transports_logistics_and_warehousing',
//       title: 'Packaging Services',
//       key: 'packaging_services',
//     },
//     {
//       parent: 'transports_logistics_and_warehousing',
//       title: 'Private Jet Hire',
//       key: 'private_jet_hire',
//     },
//     {
//       parent: 'transports_logistics_and_warehousing',
//       title: 'Rail Services',
//       key: 'rail_services',
//     },
//     {
//       parent: 'transports_logistics_and_warehousing',
//       title: 'Relocation Services',
//       key: 'relocation_services',
//     },
//     {
//       parent: 'transports_logistics_and_warehousing',
//       title: 'Refrigerated Transport',
//       key: 'refrigerated_transport',
//     },
//     {
//       parent: 'transports_logistics_and_warehousing',
//       title: 'Removal Services',
//       key: 'removal_services',
//     },
//     {
//       parent: 'transports_logistics_and_warehousing',
//       title: 'Sensible and temperature-controlled transportation',
//       key: 'sensible_and_temperature_controlled_transportation',
//     },
//     {
//       parent: 'transports_logistics_and_warehousing',
//       title: 'Logistic staffing',
//       key: 'logistic_staffing',
//     },
//     {
//       parent: 'transports_logistics_and_warehousing',
//       title: 'Storage',
//       key: 'storage',
//     },
//     {
//       parent: 'transports_logistics_and_warehousing',
//       title: 'Taxi Service',
//       key: 'taxi_service',
//     },
//     {
//       parent: 'transports_logistics_and_warehousing',
//       title: 'Transportation & Logistics',
//       key: 'transportation_and_logistics',
//     },
//     {
//       parent: 'transports_logistics_and_warehousing',
//       title: 'Trucking',
//       key: 'trucking',
//     },
//     {
//       parent: 'transports_logistics_and_warehousing',
//       title: 'Vehicle Cleaning',
//       key: 'vehicle_cleaning',
//     },
//     {
//       parent: 'transports_logistics_and_warehousing',
//       title: 'Vehicle Servicing',
//       key: 'vehicle_servicing',
//     },
//     {
//       parent: 'transports_logistics_and_warehousing',
//       title: 'Vehicle Valeting',
//       key: 'vehicle_valeting',
//     },
//     {
//       parent: 'transports_logistics_and_warehousing',
//       title: 'Warehousing & Shipping',
//       key: 'warehousing_and_shipping',
//     },
//     {
//       parent: 'transports_logistics_and_warehousing',
//       title: 'Warehousing Supplies',
//       key: 'warehousing_supplies',
//     },
//     {
//       parent: 'utilities',
//       title: 'Electricity',
//       key: 'electricity',
//     },
//     {
//       parent: 'utilities',
//       title: 'End-to-end infrastructure services',
//       key: 'end_to_end_infrastructure_services',
//     },
//     {
//       parent: 'utilities',
//       title: 'Energy',
//       key: 'energy',
//     },
//     {
//       parent: 'utilities',
//       title: 'Energy Consulting',
//       key: 'energy_consulting',
//     },
//     {
//       parent: 'utilities',
//       title: 'Oil & Gas',
//       key: 'oil_and_gas',
//     },
//     {
//       parent: 'utilities',
//       title: 'Sustainable Energy Solutions',
//       key: 'sustainable_energy_solutions',
//     },
//     {
//       parent: 'utilities',
//       title: 'Water',
//       key: 'water',
//     },
//   ];
  matchingCountries: string[] = [];

  ngOnChanges() {
    const geographicalcov = this.businessProfileForm.get('businessProfileForm')?.get('geographical_coverage')?.value;
    if (Array.isArray(geographicalcov)){
      geographicalcov.forEach((code: any) => {
        // Find the object in geographicalCoverage array with the matching code
        const countryObject = this.geographicalCoverage.find((item: { code: any; }) => item.code === code);

        // If a country object is found, push the country name into the matchingCountries array
        if (countryObject) {
            this.matchingCountries?.push(countryObject.country);
        }
      });
    }
  }

  getCountryByCode(code: string): string {
    const geoCoverage = this.geographicalCoverage.find(item => item.code === code);
    return geoCoverage ? geoCoverage.country : '';
  }

  getIndustryByKey(key: string): string {
    const industry = this.industrySectors.find(item => item.key === key);
    return industry ? industry.title : '';
  }

  getSubIndustryByKey(key: string): string {
    const subIndustry = this.services.find(item => item.key === key);
    return subIndustry ? subIndustry.title : '';
  }

  compareFn(option1: any, option2: any): boolean {
    return option1 && option2 ? option1.key === option2.key : option1 === option2;
  }
}
