import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { UserData } from '../../models/user.model';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { jwtDecode } from 'jwt-decode';
import { TokenService } from '../../token/token.service';
import { AdminAuthService } from '../admin/admin-auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  UserData : any;

  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    private toastr: ToastrService,
    private tokenService: TokenService,
    private snackBar: MatSnackBar,
    // private adminAuthService: AdminAuthService
  ) { }

  public async signIn(email: string, password: string): Promise<{ success: boolean; response?: any }> {
    try {

      const result = await this.afAuth.signInWithEmailAndPassword(email, password);

      if (!(result?.user?.emailVerified)) {
        return { success: false, response: 'Email is not verified, Please verify your email address by clicking the link in email' };
      }
      this.snackBar.open('Logging you in...', '', {
        duration: 1000,
      });
      const token = await result.user?.getIdToken(false);
      this.tokenService.updateToken(token);
      this.writeJWT(token);  

      this.getUserById(result.user.uid)
        .subscribe(async (documentData) => {
          if (documentData) {
            sessionStorage.setItem('User', JSON.stringify(documentData));
            // this.adminAuthService.assignAdminClaim();
            if (await this.checkIfUserIsAdmin(result?.user)) {
              this.router.navigate(['admin']);
            } else {
              this.router.navigate(['/certifications']);
            }
          }
        });

      return { success: true, response: 'Successfully logged in' };      

    } catch (error: any) {
      const errorMessage = this.getErrorMessage(error.code);
      console.error('Authentication error:', error);
      return { success: false, response: errorMessage };
    }
  }

  private getErrorMessage(errorCode: string): string {
    switch (errorCode) {
      case 'auth/user-not-found':
        return 'User not found. Please check your email or sign up.';
      case 'auth/wrong-password':
        return 'Invalid password. Please try again.';
      case 'auth/too-many-requests':
        return 'Too many unsuccessful login attempts. Please try again later.';
      default:
        return 'An error occurred. Please try again later.';
    }
  }

  async checkIfUserIsAdmin(user: any): Promise<boolean> {
    // Check the claims 
    if (user) {     
      const idTokenResult = await user.getIdTokenResult();
      const isAdmin = idTokenResult.claims.admin;     
      if (isAdmin) {       
        return true;
      } else {       
        return false;
      }
    }
    return false;
  }

  public async signUp(email: any, password: any, user: any): Promise<{ success: boolean; response?: any }> {
    try {
      const result = await this.afAuth.createUserWithEmailAndPassword(email, password);
      const displayName = user.firstname.trim() + ' ' +  user.lastname.trim();
      await result.user?.updateProfile({
        displayName: displayName
      });
      await this.setUserData(result?.user, user);
      await this.sendVerificationMail();
      this.toastr.success('Please verify your email address by clicking the link in email', '', {timeOut: 8000});

      return { success: true, response: {} };
    } catch (error) {
      console.error('Sign up error:', error);
      return { success: false, response: error };
    }
  }

  public async logout(): Promise<{ success: boolean; response?: any }> {
    try {
      const result = await this.afAuth.signOut().then(() => {
        // this.browserClosed = false;
        // sessionStorage.removeItem('browserClosedFlag');
      });;
      this.eraseLocalStorage();
      sessionStorage.removeItem('User');
      // this.setValue('');
      return { success: true, response: {} };
    } catch (error) {
      console.error('Cannot Logout:', error);
      throw error;
    }
  }

  public async forgotPassword(passwordResetEmail: any): Promise<{ success: boolean; response?: any }> {
    try {
      await this.afAuth.sendPasswordResetEmail(passwordResetEmail);
      this.toastr.success('Password reset email sent, Please check your inbox.', '');
      return { success: true, response: {} };
    } catch (error) {
      console.error('Error sending password reset email:', error);
      return { success: false, response: error };
    }
  }

  private async sendVerificationMail() {
    try {
      const currentUser = await this.afAuth.currentUser;
      await currentUser?.sendEmailVerification();
    } catch (error) {
      console.error('Error sending verification email:', error);
    }
  }
  

  public getUserById(documentId: string): Observable<any> {
    return this.afs.collection('users').doc(documentId).valueChanges();
  }

  private async setUserData(user: any, userInfo: any): Promise<void> {

    // const token = await user?.getIdToken(false);
    // this.writeJWT(token);
    const displayName = userInfo.firstname.trim() + ' ' +  userInfo.lastname.trim();
    const userData: UserData = {
      uid: user.uid,
      email: user.email,
      firstName: userInfo.firstname,
      lastName: userInfo.lastname,
      displayName: displayName,
      emailVerified: user.emailVerified,
      organisation: userInfo.orgname,
      countryCode: userInfo.countryCode,
      phoneNumber: userInfo.phoneNumber,
      // facingDisadvantage: userInfo.facingDisadvantage,
      acceptedTerms: userInfo.acceptedTerms,
      acceptedPrivacyPolicy: userInfo.acceptedPrivacyPolicy,
      createdOn: new Date().toISOString(),
    };

    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    return userRef.set(userData, { merge: true, });
  }

  private writeJWT(jwt: any): void {
    sessionStorage.setItem('token', jwt);
  }
  private eraseLocalStorage(): void {
    sessionStorage.removeItem('User');
    sessionStorage.clear();
    localStorage.clear();
  }

  //Check wither User Is looged in or not
  get isLoggedIn(): boolean {
    const token = sessionStorage.getItem('token');
    const user = this.decodeToken(token);
    return user !== null ? true : false;
  }

  decodeToken(token: any): any {
    try {
      return jwtDecode(token);
    } catch (error) {
      return null;
    }
  }
}