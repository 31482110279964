import { Component } from '@angular/core';

@Component({
  selector: 'app-upload-progress-overlay',
  templateUrl: './upload-progress-overlay.component.html',
  styleUrl: './upload-progress-overlay.component.css'
})
export class UploadProgressOverlayComponent {

}
