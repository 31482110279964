<div class="text-primary md:mt-12 px-5">
  <form [formGroup]="ownershipForm">

      <div formArrayName="ownershipForm">
          <div *ngFor="let other of owners?.controls; let i = index">
              <div [formGroupName]="i" class="mb-10">
                  <div class="text-xl mb-5 font-bold">Ethnic Business Minority Owner No. {{ i + 1 }}</div>

                  <!---Business Owner first & last name-->
                  <div class="flex items-center justify-between flex-col lg:flex-row mb-3">
                      <div class="w-full lg:w-[48%]">
                          <div class="text-base mb-3 lg:text-lg font-medium">Business Owner First Name
                              <sup>*</sup></div>
                          <mat-form-field class="w-full" appearance="outline">
                              <input matInput
                                  formControlName="business_owner_firstname" maxlength="50" #firstName />
                          </mat-form-field>
                      </div>
                      <div class="w-full lg:w-[48%]">
                          <div class="text-base mb-3 lg:text-lg font-medium">Business Owner Last Name <sup>*</sup>
                          </div>
                          <mat-form-field class="w-full" appearance="outline">
                              <input matInput
                                  formControlName="business_owner_lastname" maxlength="50" #lastName />
                          </mat-form-field>
                      </div>
                  </div>

                  <!--Email & Job title-->
                  <div class="flex items-center justify-between flex-col lg:flex-row mb-3">
                      <div class="w-full lg:w-[48%]">
                          <div class="text-base mb-3 lg:text-lg font-medium">Email Address <sup>*</sup></div>
                          <mat-form-field class="w-full" appearance="outline">
                              <input matInput
                                  formControlName="email_address" />
                          </mat-form-field>
                      </div>
                      <div class="w-full lg:w-[48%]">
                          <div class="text-base mb-3 lg:text-lg font-medium">Job Title <sup>*</sup></div>
                          <mat-form-field class="w-full" appearance="outline">
                              <input matInput
                                  formControlName="job_title" />
                          </mat-form-field>
                      </div>
                  </div>

                  <!--Ethnic group-->
                  <div class="flex justify-between flex-col lg:flex-row">
                      <div class="w-full lg:w-[48%]">
                          <div class="text-base mb-3 lg:text-lg font-medium">Percentage Ownership <sup>*</sup></div>
                          <mat-form-field class="w-full mb-3" appearance="outline">
                              <input matInput
                                  formControlName="percentage_ownership" />
                          </mat-form-field>
                          <div class="text-base mb-3 lg:text-lg font-medium">
                              The ethnic minority group(s) you identify
                              as from the drop-down menu below
                              (this information will not be shared with the Corporate Members) <sup>*</sup>
                          </div>

                          @if (ownershipForm.get('status')?.value?.current_status !== 'CERTIFIED') {
                            <mat-form-field appearance="outline" class="w-full mb-3">
                                <mat-select
                                    formControlName="ethnic_minority_group" multiple>
                                    <mat-optgroup *ngFor="let regionGroup of regionGroups" [label]="regionGroup.name">
                                        <mat-option *ngFor="let option of regionGroup.options" [disabled]="true" [value]="option.key">{{option.value}}</mat-option>
                                    </mat-optgroup>
                                </mat-select>
                            </mat-form-field>
                          } @else {
                            <div class="font-bold text-red mb-3">Data removed for GDPR compliance</div>
                          }
                          <div class="text-base mb-3 lg:text-lg font-medium">
                              Are you a European Union national? <sup>*</sup>
                          </div>
                          <mat-radio-group aria-label="Select an option"
                              formControlName="is_european_union_national">
                              <mat-radio-button [value]="true">Yes</mat-radio-button>
                              <mat-radio-button [value]="false">No</mat-radio-button>
                          </mat-radio-group>
                          <div class="text-base my-3 lg:text-lg font-medium">
                              If not, please specify your resident status
                          </div>
                          <mat-form-field appearance="outline" class="w-full">
                              <mat-select
                                  formControlName="resident_status">
                                  <mat-optgroup *ngFor="let countryGroup of countryGroups" [label]="countryGroup.name">
                                      <mat-option *ngFor="let option of countryGroup.options" [value]="option.key">{{option.value}}</mat-option>
                                  </mat-optgroup>
                              </mat-select>
                          </mat-form-field>
                      </div>
                      <div class="w-full lg:w-[48%]">
                          <div class="text-base italic mb-3 lg:text-lg font-bold">
                              <div class="mb-3">Why do we ask this question:</div>
                              <div class="mb-3">
                                Our objective is to ensure that one minority group is not more disadvantaged than
                                the other.
                                It is established that racial minority groups are not all treated in the same
                                manner,
                                and each face its own challenges and is subject to different prejudices. Amongst
                                others,
                                if within our certification program, we identify imbalance in the representation of
                                racial
                                minorities, we would analyse the cause of any under representation and remedy it,
                                to the extent that it is not solely based on the business' merits.
                                This information will not be shared with the Corporate Members.
                              </div>
                          </div>
                      </div>
                  </div>
                    <!---proof of identity--->
                  <div class="text-base mb-3 lg:text-lg font-medium">Proof of Identity</div>
                  <div *ngIf="!getRemoveFilesValue()">
                    <div class="flex flex-row justify-between"
                          *ngIf="this.files[i]?.file.name && !this.files[i].isDeleted">
                          <mat-accordion class="w-[85%]">
                              <mat-expansion-panel [ngClass]="files[i].isUploaded ? 'bg-lightGreen' : ''" disabled [expanded]="files[i].upload_result">
                                  <mat-expansion-panel-header [ngClass]="files[i].upload_result ? 'error-state' : ''">
                                      <mat-panel-title>
                                          <div class="flex items-center">
                                              <mat-icon color="primary">description</mat-icon>
                                              <span class="ml-3 text-primary font-medium file-name" matTooltip="{{files[i].file.name}}">{{files[i].file.name}}</span>
                                              <span class="ml-3 text-black">{{ getFileSize(i) }}</span>
                                          </div>
                                      </mat-panel-title>
                                      <button *ngIf="this.files[i] && files[i]?.isUploaded" class="download-icon mt-1 mr-3"
                                          (click)="downLoadFile(files[i].file.id)" matTooltip="Download file">
                                          <mat-icon>vertical_align_bottom</mat-icon>
                                      </button>
                       
                                  </mat-expansion-panel-header>
                                  <div class="py-2 font-bold" [ngClass]="files[i].isUploaded ? 'text-green' : 'text-red'">
                                      {{files[i].upload_result}}
                                  </div>
                              </mat-expansion-panel>
                          </mat-accordion>
                    </div>
                    <div *ngIf="viewing === 'admin'" class="mt-1 italic text-red blinking-text">
                        GDPR compliance: Please ensure to remove this file from download folder
                        from your device after downloading/viewing.
                    </div>
                  </div>
                  <div *ngIf="getRemoveFilesValue()">
                    <div class="font-bold text-red">File removed for GDPR compliance</div>
                  </div>
              </div>
          </div>
      </div>

      <div class="flex justify-between mt-5">
          <div>
              <button mat-button matStepperPrevious class="!rounded-[50px] next-button">
                  <div class="flex items-center pr-2"><mat-icon>chevron_left</mat-icon>Previous step</div>
              </button>
          </div>
          <div class="flex">
              <button mat-button matStepperNext class="!rounded-[50px] next-button">
                  <div class="flex items-center pl-3">Next step<mat-icon>chevron_right</mat-icon></div>
              </button>
          </div>
      </div>
  </form>
</div>
